import React from "react";
import { fullMonthNames } from "../../constants/dateConstants";
import ElepayLogo from "../../assets/images/Elepay_enjoy_more_time.png";
import "../style.css";

const Details = ({ details, partnerCode, showBlur, requestedAmount }) => {
  const {
    firstname,
    lastname,
    email,
    address,
    requestedamount,
    startdate,
    disbursement_date,
  } = details;

  const formatDate = (date) => {
    const startDate = new Date(date);
    return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]} '${
      startDate.getFullYear().toString().split("0")[1]
    }`;
  };

  return (
    <>
      <div
        className="text-left xl:absolute items-center m-4 xl:m-0 xl:w-56"
        style={{ filter: `${showBlur && "blur(10px)"}` }}
      >
        {/* <div className="hidden py-10 flex xl:flex flex-row justify-center flex-wrap bg-purple-dark border-2 border-purple-dark">
                <img className="w-40" src={ElepayLogo} alt="Elepay Logo" />
            </div> */}
        <div
          className="xl:flex xl:w-56 detailsContainer -z-1 xl:flex-col xl:absolute h-100 border rounded-2xl xl:rounded-none"
          style={{ zIndex: "-1" }}
        >
          <div className="bg-black-light text-center pt-3 pl-6 pr-6 xl:px-0 pb-3 w-full h-full details-in-payment-hub">
            <h3 className="pt-6 font-gilroy-bold text-purple-dark">
              Your details
            </h3>
            <p className="font-gilroy-bold">{`${firstname} ${lastname}`}</p>
            <p className="text-sm leading-1 font-gilroy-regular">{email}</p>
            <p className="text-sm leading-1 font-gilroy-regular">
              {address?.unitNumber} {address?.streetNumber}{" "}
              {address?.streetName}
            </p>
            <p className="text-sm leading-1 font-gilroy-regular">
              {address?.suburb}
            </p>
            <p className="text-sm font-gilroy-regular">
              {address?.state} {address?.postcode}
            </p>
            <div className="bg-black-light pt-3 pb-3 w-full">
              <h3 className="pt-2 font-gilroy-bold text-md text-black-dark">
                Requested Amount
              </h3>
              {/* <h3 className="font-gilroy-bold text-md text-purple-light">${requestedamount}</h3> */}
              <h3 className="font-gilroy-bold text-md text-purple-light">
                {requestedAmount?.amount}
              </h3>

              <h3 className="pt-2 font-gilroy-bold text-md text-black-dark">
                Start Date
              </h3>
              <h3 className="font-gilroy-bold text-md text-purple-light">
                {formatDate(startdate)}
              </h3>
            </div>
            {disbursement_date && partnerCode !== "elepay" && (
              <div
                className="bg-purple-light px-2 pt-3 pb-3 w-full rounded-2xl"
                style={{ maxWidth: "12rem", margin: "auto" }}
              >
                <h3 className="font-gilroy-semibold text-md text-green-light">
                  Funds disbursed into your account on
                </h3>
                <h3 className="font-gilroy-semibold text-md text-white">
                  {formatDate(disbursement_date)}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
