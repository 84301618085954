import React, { useState } from "react";
import { motion } from "framer-motion";
import { fullMonthNames } from "../../constants/dateConstants";

const OptionCard = ({
    requestedAmount,
    selectedTab,
    applicationUrl,
    dayPlan,
    noPayFor,
    code,
    name,
    dateOptions,
    easyPayments,
    price
}) => {
  const [showSinglePaymentOptions, setShowSinglePaymentOptions] = useState(false);
  const [showMultiplePaymentOptions, setShowMultiplePaymentOptions] = useState(false);

  const handleProduct = () => {
    // window.location.href = `https://app.elepay.com.au/lead/apply/${leadId}?product=${productCode}&amount=${requestedAmount?.amount}`;
    window.location.href=`${applicationUrl}`
  }

  const formatCurrency = (elm) => {
    if (!elm) return '$0.00';

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    return formatter.format(elm);
  }

  const formatDate = (date) => {
    return `${date.getDate()} ${fullMonthNames[date.getMonth()]} '${date.getFullYear() % 100}`;
  }

  return (
    <div className="w-60 bg-white rounded-2xl" style={{ height: "fit-content" }}>
      <div className="p-4">
        <h4 className="font-gilroy-bold text-purple-dark">
          <span className="w-10 p-2 bg-purple-extra-light rounded-3xl text-purple-dark">
            {dayPlan} DAY
          </span>{" "}
        </h4>
      </div>
      {/* <div className="bg-purple-extra-light p-4">
        <p className="font-gilroy-bold text-purple-dark">Nothing to pay for</p>
        <h3 className="font-gilroy-bold text-3xl text-purple-light">
          {noPayFor} days
        </h3>
      </div> */}
      <div className="flex flex-col items-center">
      <p className="text-sm font-gilroy-regular text-gray-500">No payment for <span className="text-purple-light font-gilroy-semibold">{noPayFor} days</span></p>
      <hr className="w-3/5 mt-2 mb-2 border-green-light" style={{ borderWidth: "1px" }} />
      <p className="text-3xl text-purple-light font-gilroy-regular">{formatCurrency(price)}</p>
      <hr className="w-3/5 mt-1 mb-2 border-green-light" style={{ borderWidth: "1px" }} />
      <p className="text-sm mb-2 font-gilroy-regular text-gray-500">{`${easyPayments} x payment${easyPayments === 4 ? 's' : ""}`}</p>
        {/* <table className="">
          <tr>
            <td>
              <p className="font-gilroy-bold text-purple-dark">No payment for</p>
            </td>
            <td className="pl-4">{<p className="font-gilroy-bold text-purple-light">{noPayFor} days</p>}</td>
          </tr>
          <tr>
            <td><p className="font-gilroy-bold text-md text-purple-dark">
              {easyPayments ? `${easyPayments} easy payments` : "Single Payment"}
            </p></td>
            <td className="pl-4">{<p className="text-purple-light font-gilroy-bold">{formatCurrency(price)}</p>}</td>
          </tr>
        </table> */}
      </div>
      <div className="p-4 pt-0">
        {/* <p className="font-gilroy-bold text-md text-purple-dark">
          {easyPayments ? `${easyPayments} easy payments` : "Single Payment"}
        </p>
        <p className="text-purple-light text-lg mb-4">${price}</p> */}
        {/* {selectedTab.optionSelected === "a" && !showSinglePaymentOptions && <button onClick={() => setShowSinglePaymentOptions(true)}><p className="font-gilroy-regular text-gray-500">{`View date${easyPayments === 4 ? 's' : ''}`} <span className="text-purple-light">▾</span></p></button>} */}
        {selectedTab.optionSelected === "a" &&
        <div className="text-right m-auto table"> 
          {dateOptions.map((dateOption) => (
            <p className="font-gilroy-regular text-sm text-purple-dark">
              {formatDate(new Date(dateOption.date))}
            </p> 
        ))}
        </div>}
        {selectedTab.optionSelected === "b" && !showMultiplePaymentOptions && <button onClick={() => setShowMultiplePaymentOptions(true)}><p className="font-gilroy-regular text-purple-dark">{`View date${easyPayments === 4 ? 's' : ''}`} <span className="text-purple-light">▾</span></p></button>}
        {selectedTab.optionSelected === "b" && showMultiplePaymentOptions && 
          <div className="text-right m-auto table">
            {dateOptions.map((dateOption) => (
                <p className="font-gilroy-regular text-sm text-purple-dark">
                  {formatDate(new Date(dateOption.date))}
                </p>
            ))}
          </div>}
        <button onClick={handleProduct} className="bg-green-light p-2 mt-2 w-full text-purple-light rounded-3xl font-gilroy-bold">
          <motion.button whileHover={{ scale: 1.1 }}>
            Select Plan
          </motion.button>
        </button>
      </div>
    </div>
  );
};

export default OptionCard;
