import React, { useEffect, useState } from "react";
import Details from "./components/Details";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import { fullMonthNames } from "../constants/dateConstants";

import "./latestStyle.css";

import Logo120 from "../assets/images/Elepay Plan Icons-120 days.png";
import Logo12M from "../assets/images/12Month-Ni.svg";
import Invest from "../assets/images/icons/Investment Property Icon.png";
import Personal from "../assets/images/icons/Personal Loans.png";
import ShortTerm from "../assets/images/icons/Short Term Deposits.png";
import SideImage from "../assets/images/CalcMobImage.png";
import ElepayBlueLogo from "../assets/images/Elepay_Primary.svg";
import ElepayWhiteLogo from "../assets/images/elepay-logo.png";

import axios from "axios";

export default ({ location, match }) => {
  const { leadId } = match.params;
  const { form_code } = match.params;

  const [startDate, setStartDate] = useState(new Date());
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [firstPaymentDate, setFirstPaymentDate] = useState(new Date());
  const [finalPaymentDate, setFinalPaymentDate] = useState(new Date());
  const [requestedAmount, setRequestedAmount] = useState({ amount: "0" });
  const [totalRepayments, setTotalRepayments] = useState({ amount: "0" });
  const [showBlur, setShowBlur] = useState(false);
  const [details, setDetails] = useState({
    requestedamount: 0,
    options: [
      {
        amount: 0,
        products: [
          {
            application_url: "",
            earlyPayments: [],
            payments: [{ amount: 0, date: "", sequence: 0 }],
            product: {
              code: "",
              firstPayment: 0,
              name: "",
              numberOfDays: 0,
              numberOfPayments: 0,
              paymentInterval: 0,
            },
          },
        ],
      },
    ],
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    startdate: "",
    address: {
      fullAddress: "",
      id: 0,
      postcode: "",
      state: "",
      streetName: "",
      streetNumber: "",
      suburb: "",
      unitNumber: "",
    },
    form_code: "",
    payment_methods: [""],
  });
  const [options, setOptions] = useState(details.options);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}`
      )
      .then(function (response) {
        const leadResponseData = response.data;
        console.log({ leadResponseData });

        let startDate = new Date();

        startDate = new Date(leadResponseData.startdate);

        setStartDate(
          `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()].slice(
            0,
            3
          )} '${startDate.getFullYear().toString().split("0")[1]}`
        );

        setRequestedAmount({
          amount: formatCurrency(leadResponseData.requestedamount),
        });

        setTotalRepayments({
          amount: formatCurrency(leadResponseData.total_repayments),
        });

        let firstPaymentDate = new Date();
        let finalPaymentDate = new Date();

        if (match.path.includes("121rlc")) {
          firstPaymentDate = new Date(leadResponseData.startdate);
          firstPaymentDate.setDate(firstPaymentDate.getDate() + 120);

          setFirstPaymentDate(
            `${firstPaymentDate.getDate()} ${fullMonthNames[
              firstPaymentDate.getMonth()
            ].slice(0, 3)} '${
              firstPaymentDate.getFullYear().toString().split("0")[1]
            }`
          );
        } else {
          let paymentProducts =
            leadResponseData.options[0].products[0].payments;

          firstPaymentDate = new Date(paymentProducts[0].date);
          finalPaymentDate = new Date(
            paymentProducts[paymentProducts.length - 1].date
          );

          setFirstPaymentDate(
            `${firstPaymentDate.getDate()} ${fullMonthNames[
              firstPaymentDate.getMonth()
            ].slice(0, 3)} '${
              firstPaymentDate.getFullYear().toString().split("0")[1]
            }`
          );

          setFinalPaymentDate(
            `${finalPaymentDate.getDate()} ${fullMonthNames[
              finalPaymentDate.getMonth()
            ].slice(0, 3)} '${
              finalPaymentDate.getFullYear().toString().split("0")[1]
            }`
          );

          setMonthlyAmount(
            formatCurrency(
              leadResponseData.options[0].products[0].payments[0].amount
            )
          );
        }

        setDetails(leadResponseData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const formatCurrency = (elm) => {
    if (!elm) return "$0.00";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(elm);
  };

  const Navigate = (_) => {
    window.location.href = `${details.options[0].products[0].application_url}`;
  };

  return (
    <div className="pay120">
      <img className="top-logo pt-8" src={ElepayWhiteLogo} alt="WhiteLogo" />

      <Details
        details={details}
        options={options}
        requestedAmount={requestedAmount}
        showBlur={showBlur}
      />

      <div
        className="flex flex-wrap justify-center"
        style={{ maxWidth: "50rem", minWidth: 0, margin: "auto" }}
      >
        <h1 className="mt-10 mb-8 text-4xl font-gilroy-semibold white">
          Welcome to Australia's Pay Later specialist for property owners
        </h1>

        <div className="side-img">
          <img src={SideImage} alt="GirlSittingSide" />
        </div>

        {match.path.includes("121") ? (
          <div className="my-4 p-6 pb-8 pl-9 pr-9 bg-black-light rounded-2xl border-purple upfront">
            <h3 className="font-gilroy-bold blue text-2xl text-left w-full mt-2 mb-4 lg:ml-0">
              No Upfront Payment.
            </h3>

            <h4 className="mb-2 font-gilroy-bold">
              Manage your property expenses with no upfront payment for 120
              days. Start now and enjoy all the benefits of deferring any
              upfront payment for 120 days with Australia's Pay Later specialist
              for Property owners.
            </h4>

            <div className="line mb-8 mt-8"></div>

            <div className="flex flex-wrap">
              <div className="img-container">
                <img src={Logo120} alt="120Logo" />
              </div>

              <div className="auto">
                <p className="font-gilroy-bold">1 x Single repayment:</p>
                <h3 className="font-gilroy-bold">{totalRepayments.amount}</h3>
              </div>

              <div className="auto">
                <p className="font-gilroy-bold">Due Date: </p>

                <div className="w-full flex justify-start items-center input-padding-down">
                  <span
                    className="w-7 pl-3 absolute"
                    style={{ color: "#4139ff" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      style={{ marginLeft: "-0.2rem" }}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </span>

                  <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="LL"
                    minDate={new Date()}
                    onDayChange={(date) => setStartDate(date)}
                    value={firstPaymentDate}
                    dayPickerProps={{
                      modifiers: {
                        disabled: [
                          {
                            before: new Date(),
                          },
                        ],
                      },
                    }}
                  />
                </div>
              </div>

              <button
                onClick={Navigate}
                className="auto bg-purple-light p-2 mt-16 py-3 px-8 mb-8 text-white rounded-3xl font-gilroy-bold"
              >
                START NOW
              </button>
            </div>
          </div>
        ) : (
          <div className="my-4 p-6 pl-9 pr-9 bg-black-light rounded-2xl border-purple no-interest">
            <h3 className="font-gilroy-bold blue text-2xl text-left w-full mt-2 mb-8 lg:ml-0">
              12 Months. No Interest.
            </h3>

            <h4 className="mb-2 font-gilroy-bold">
              Manage your property expenses over 12 x monthly payments and pay
              no interest. Start now and enjoy all the benefits of spreading
              your repayments over 12 months with Australia's Pay Later
              specialist for Property owners.
            </h4>

            <div className="line mb-8 mt-8"></div>

            <div className="flex flex-wrap remove-flex">
              <div className="img-container">
                <img src={Logo12M} alt="120Logo" />
              </div>

              <div className="flex-three-container">
                <div className="auto pb-3">
                  <p className="font-gilroy-bold">
                    12 x 30 Day recurring instalments of:
                  </p>
                  <h3 className="font-gilroy-bold">{monthlyAmount}</h3>
                </div>

                <div className="flex">
                  <div className="auto">
                    <p className="font-gilroy-bold pb-2">First Payment: </p>

                    <div className="w-full flex justify-start items-center">
                      <span
                        className="w-7 pl-3 absolute"
                        style={{ color: "#4139ff" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          style={{ marginLeft: "-0.2rem" }}
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                      </span>

                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        minDate={new Date()}
                        onDayChange={(date) => setStartDate(date)}
                        value={firstPaymentDate}
                        dayPickerProps={{
                          modifiers: {
                            disabled: [
                              {
                                before: new Date(),
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="auto">
                    <p className="font-gilroy-bold pb-2">Last Payment: </p>

                    <div className="w-full flex justify-start items-center">
                      <span
                        className="w-7 pl-3 absolute"
                        style={{ color: "#4139ff" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          style={{ marginLeft: "-0.2rem" }}
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                      </span>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        minDate={new Date()}
                        onDayChange={(date) => setStartDate(date)}
                        value={finalPaymentDate}
                        dayPickerProps={{
                          modifiers: {
                            disabled: [
                              {
                                before: new Date(),
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="auto">
                    <p className="font-gilroy-bold pb-2">Amount Due: </p>
                    <input
                      type="text"
                      name="Amount"
                      className="p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold"
                      value={totalRepayments.amount}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={Navigate}
              className="auto bg-purple-light p-2 mt-16 py-3 px-8 mb-8 text-white rounded-3xl font-gilroy-bold"
            >
              START NOW
            </button>
          </div>
        )}
      </div>

      <div
        className="flex flex-wrap justify-center"
        style={{ maxWidth: "50rem", minWidth: 0, margin: "auto" }}
      >
        <h3 className="font-gilroy-bold blue text-2xl text-center w-full mt-8 mb-8 lg:ml-0 small-font">
          How Elepay is helping 000's of other Australian Property owners
        </h3>

        <div className="my-4 pt-10 pb-10 bg-black-light rounded-2xl border-purple smaller-margin">
          <div className="flex flex flex-wrap">
            <div className="block left-line pl-4 pr-4">
              <div className="icon-container auto">
                <img src={Invest} alt="InvestIcon" />
              </div>

              <h4 className="mt-4 font-gilroy-bold">Investment Properties</h4>
            </div>

            <div className="block left-line pl-4 pr-4">
              <div className="icon-container auto">
                <img src={ShortTerm} alt="PersonalLoans" />
              </div>

              <h4 className="mt-4 font-gilroy-bold">Short Term Deposits</h4>
            </div>

            <div className="block pl-4 pr-4">
              <div className="icon-container auto">
                <img src={Personal} alt="building" />
              </div>

              <h4 className="mt-4 font-gilroy-bold">
                Personal <br /> Loans
              </h4>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={(_) => (window.location.href = "https://www.elepay.com.au/")}
        className="bg-purple-light p-2 mt-8 mb-10 py-3 px-8 text-white rounded-3xl font-gilroy-bold"
      >
        ENQUIRE NOW
      </button>

      <div className="footer flex">
        <div className="img-container m-10">
          <img src={ElepayBlueLogo} alt="Elepay Logo Blue" />
        </div>

        <div className="terms-container m-10">
          <p className="font-gilroy-semibold">
            ABN: 47 634 728. Copyright 2022 Elepay.
          </p>
          <p className="text-right font-gilroy-semibold">
            All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
