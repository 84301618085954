import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { fullMonthNames } from "../../constants/dateConstants";

const OptionCard = ({
  requestedAmount,
  applicationUrl,
  dayPlan,
  noPayFor,
  code,
  name,
  dateOptions,
  easyPayments,
  price
}) => {
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);

  const handleProduct = () => {
    // window.location.href = `https://app.elepay.com.au/lead/apply/${leadId}?product=${productCode}&amount=${requestedAmount?.amount}`;
    window.location.href=`${applicationUrl}`
  }

  const formatCurrency = (elm) => {
    if (!elm) return '$0.00';

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    return formatter.format(elm);
  }

  const formatDate = (date) => {
    return `${date.getDate()} ${fullMonthNames[date.getMonth()]}, ${date.getFullYear()}`;
  }

  return (
    <div className="w-60 bg-white rounded-2xl" style={{ height: "fit-content" }}>
      <div className="p-4">
        <h4 className="font-gilroy-bold text-purple-dark">
          <span className="w-10 p-1.5 bg-purple-light rounded-3xl text-white">
            {dayPlan}
          </span>{" "}
          day plan
        </h4>
      </div>
      {/* <div className="bg-purple-extra-light p-4">
        <p className="font-gilroy-bold text-purple-dark">Nothing to pay for</p>
        <h3 className="font-gilroy-bold text-3xl text-purple-light">
          {noPayFor} days
        </h3>
      </div> */}
      <div className="flex flex-col items-center">
        <table className="">
          <tr>
            <td>
              <p className="font-gilroy-bold text-purple-dark">Nothing to pay for</p>
            </td>
            <td className="pl-4">{<p className="font-gilroy-bold text-purple-light">{noPayFor} days</p>}</td>
          </tr>
          <tr>
            <td><p className="font-gilroy-bold text-md text-purple-dark">
              {easyPayments ? `${easyPayments} easy payments` : "Single Payment"}
            </p></td>
            <td className="pl-4">{<p className="text-purple-light font-gilroy-bold">{formatCurrency(price)}</p>}</td>
          </tr>
        </table>
      </div>
      <div className="p-4">
        {/* <p className="font-gilroy-bold text-md text-purple-dark">
          {easyPayments ? `${easyPayments} easy payments` : "Single Payment"}
        </p>
        <p className="text-purple-light text-lg mb-4">${price}</p> */}
        {!showPaymentOptions && <button onClick={() => setShowPaymentOptions(true)}><p className="font-gilroy-regular">View payment options <span className="text-purple-light">▾</span></p></button>}
        {showPaymentOptions && dateOptions.map((dateOption) => (
          <p className="font-gilroy-regular">{formatDate(new Date(dateOption.date))}</p>
        ))}
        <button onClick={handleProduct} className="bg-purple-light p-2 mt-2 w-full text-white rounded-3xl font-gilroy-bold">
          <motion.button whileHover={{ scale: 1.1 }}>
            Select Plan
          </motion.button>
        </button>
      </div>
    </div>
  );
};

export default OptionCard;
