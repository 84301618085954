import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { motion } from "framer-motion";
import { isJwtExpired } from 'jwt-check-expiration';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';
import CoronisLogo from '../../assets/images/CoronisLogo.png';

const ThankYou = ({ location }) => {
    const jwt = localStorage.getItem('token');
    const { firstName,
        lastName,
        email,
        mobile,
        street,
        propertyAddress,
        suburb,
        postCode,
        state,
        requestedamount,
        requestedamount_2,
        requestedamount_3,
        formCode,
        partner_code,
        partner_name,
        startDate, qrCode, url, propertyManager } = location.state || {};

    useEffect(() => {
        const cleanup = () => { 
            localStorage.removeItem('token');
        }

        // Cleaning up when the tab is closed
        window.addEventListener('beforeunload', cleanup);

        return () => {
          window.removeEventListener('beforeunload', cleanup);
        }
    }, []);

    return (<><div className="flex flex-col pb-6 lg:pb-0 lg:flex-row items-center justify-between flex-wrap items-center bg-purple-dark">
            <div className="p-10 flex flex-col lg:flex-row flex-wrap lg:flex-start items-center bg-purple-dark">
                {/* <img className="flex md:pl-0 lg:pl-40 w-52 lg:w-72" src={partner_code ? getPartnerLogoByCode(partner_code) : CoronisLogo} alt={partner_name ? partner_name : 'Coronis'} /> */}
                {/* {
                    getPartnerLogoByCode(partner_code) ? <img className="flex md:pl-0 lg:pl-40 w-52 lg:w-72 text-white" src={partner_code ? getPartnerLogoByCode(partner_code) : CoronisLogo} alt={partner_name ? partner_name : 'Coronis'} /> : <h3 className="font-gilroy-semibold text-white text-xl">{partner_name}</h3>
                } */}
                <h3 className="font-gilroy-semibold text-white text-xl">{partner_name}</h3>
                <p className="flex text-green-light text-2xl font-gilroy-bold md:pl-0 lg:pl-16">{formCode.includes('paylater_paynow') ? 'Pay Now / Pay Later Lead Form' : formCode.includes('paylater') ? 'Pay Later Lead Form' : 'Lead Form'}</p>
            </div>
            <img className="pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
        </div>
        <div className="flex flex-col justify-center items-center w-4/5 lg:w-1/5 xl:w-3/5 m-auto">
            {jwt && !isJwtExpired(jwt) &&  <button type="button" className="border border-purple-light w-max p-2 py-3 mt-10 px-4 text-purple-light rounded-3xl font-gilroy-bold" onClick={() => window.location = process.env.REACT_APP_PORTAL_PREFIX}>
                    <motion.button whileHover={{ scale: 1.1 }}>
                        Back to Portal
                    </motion.button>
            </button>}
            <h3 className="mt-20 self-center font-gilroy-bold leading-14 text-6xl text-center text-purple-light">Thank you {propertyManager}.</h3>
            <h3 className="font-gilroy-semibold text-purple-dark">The following link and QR code has been sent to your client. If you wish to use these links, simply drag / copy them onto your own work.</h3>
            <div className="w-full flex flex-col lg:flex-row items-center lg:justify-evenly">
                {(!requestedamount_2 && !requestedamount_3) && <img src={`${qrCode}`} width={150} height={150} />}
                {(requestedamount_2 || requestedamount_3) && <img src={`${qrCode}?option=a`} width={150} height={150} />}
                {requestedamount_2 && <img src={`${qrCode}?option=b`} width={150} height={150} />}
                {requestedamount_3 && <img src={`${qrCode}?option=c`} width={150} height={150} />}
            </div>
            <a className="text-purple-light underline pt-8" href={url}>{url}</a>
        </div>
    </>)
}

export default ThankYou;