import React from "react";
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';

const Header = ({ headerTitle }) => {
    return (<>
        <div className="lg:hidden flex flex-col p-10 lg:flex-row items-center justify-between flex-wrap items-center bg-purple-dark">
            <img className="pl-5 pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
            <div className="px-6 py-1 m-auto mt-6 flex flex-col lg:flex-row flex-wrap lg:flex-start items-baseline bg-purple-dark border-2 border-green-light rounded-2xl">
                <p className="flex justify-center text-white text-2xl font-gilroy-bold">{(headerTitle.includes('paylater_paynow') || headerTitle.includes('rwa6090120nl') || headerTitle.includes('paynow')) ? 'Payment Hub' : 'Pay Later'}</p>
            </div>
        </div>
        <div className="hidden py-10 flex lg:flex flex-row flex-wrap items-center bg-purple-dark">
            <div className="w-56 absolute flex justify-center">
                <a href="https://elepay.com.au"><img className="w-40" src={ElepayLogo} alt="Elepay Logo" /></a>
            </div>
            <div className="px-6 py-1 m-auto flex flex-col lg:flex-row flex-wrap lg:flex-start items-baseline bg-purple-dark border-2 border-green-light rounded-2xl">
                <p className="flex justify-center text-white text-2xl font-gilroy-bold">{(headerTitle.includes('paylater_paynow') || headerTitle.includes('rwa6090120nl') || headerTitle.includes('paynow')) ? 'Payment Hub' : 'Pay Later'}</p>
            </div>
        </div>
        </>
    );
};

export default Header;