import React from "react";
import ElepayLogo from '../../assets/images/ElepayLogo.png';
import partnerLogos from "../partnerLogos";

const Header = ({partner_name}) => {
    const logo = partnerLogos[partner_name];

    return (
        <div className="flex flex-col lg:flex-row items-center justify-between flex-wrap items-center bg-purple-dark">
        <div className="p-10 flex flex-wrap lg:flex-start items-baseline bg-purple-dark">
            {logo ? <img className="flex" src={logo} alt="Partner Logo" /> : <h3 className="text-4xl text-white font-gilroy-bold">{partner_name}</h3>}
        </div>
        <img className="pr-10" src={ElepayLogo} alt="Elepay Logo" />
    </div>
    );
  };

export default Header;