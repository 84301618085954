import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Joi from 'joi-browser';
import CalculatorHeader from './CalculatorHeader';
import ProductConfirmationBox from '../components/ProductConfirmationBox';
import { fullMonthNames } from '../../constants/dateConstants';
import CalculatorDetails from './CalculatorDetails';
import CalculatorPlanFields from './CalculatorPlanFields';
import CalculatorPlanOptions from './CalculatorPlanOptions';
import CalculatorHeaderImage from '../../assets/images/calc_header.png';
import ElepayPrimaryLogo from '../../assets/images/Elepay_Primary.svg';
import CalculatorPlanOptionsBackground from '../../assets/images/CalculatorPlanOptionsBackground.png';

import './style.css';

const ElepayCalculator = ({ match }) => {
    const { leadId } = match.params;

    const [details, setDetails] = useState({});

    const [options, setOptions] = useState([]);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [maxFundAmount, setMaxFundAmount] = useState(0.00);
    const [fundAmount, setFundAmount] = useState(0.00);
    const [startDate, setStartDate] = useState(new Date());
    // Validation Errors
    const [errors, setErrors] = useState({});
    const [requestedAmount, setRequestedAmount] = useState({ index: 0, amount: "0", option: "a", products: [] });
    const [modifyData, setModifyData] = useState(false);
    const [disableSelectButton, setDisableSelectButton] = useState(true);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [confirmBoxInfo, setConfirmBoxInfo] = useState({ product: "", typeOfPayment: "", fundsDisbursed: "", applicationUrl: ""});
    const [disbursementDate, setDisbursementDate] = useState("");
    const [payOptions, setPayOptions] = useState([]);
    const [queryOption, setQueryOption] = useState("");
    const [requestedAmountTabOptions, setRequestedAmountTabOptions] = useState([]);
    // const tabOptions = ["$1,200.00", "$1,400.00", "$1,600.00"];
    const [requestedAmountOpenTab, setRequestedAmountOpenTab] = useState({ index: 0, amount: "0", option: "a", products: [] });
    const [purposeOfFund, setPurposeOfFund] = useState("");

    const purposeOfFunds = ["- Select Your Purpose of Funds -", "Preparing Property for Sale", "Maintenance for investment property", "Refreshing your own home", "Need a personal loan"];

    const [showBlur, setShowBlur] = useState(false);

    const handleBlur = () => {
        setShowBlur(true);
    }

    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    const formatDate = (date) => {
        const startDate = new Date(date);
        return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
    }

    const handleProduct = (applicationUrl) => {
        window.location.href=`${applicationUrl}`;
    }

  // Validation Schema
  const schema = {
      fundAmount: Joi.number().min(1).label("Fund amount").required(),
      startDate: Joi.date().required()
  }

  const validate = () => {
      const validateDetails = {
          fundAmount, startDate
      }
      const options = { abortEarly: false };
      const { error } = Joi.validate(validateDetails, schema, options);
      if (!error) return null;

      const errors = {};
      error.details.map(error => errors[error.path[0]] = error.message);

      return errors;
  }

    const handleCalculate = () => {
      console.log('fundAmount', fundAmount);
      console.log('startDate', startDate);

      // Validating upon submit
      const errors = validate();
      console.log('errors', errors);
      setErrors(errors || {});
      if (errors) return;

      setDisableSelectButton(false);

        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_update`, {
            enc_id: leadId,
            requestedamount: fundAmount,
            purpose: purposeOfFund === "- Select Your Purpose of Funds -" ? null : purposeOfFund,
            startdate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2')
        })
            .then(function (response) {
                console.log('hello response', response);
                setOptions(response.data.options);
                setDisbursementDate(response.data.disbursement_date);
                // const { qrcode, url } = response.data;
                // window.location.href = url;
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    const handleModifyData = () => {        
        setModifyData(true);
        setDisableSelectButton(true);
    };
    
    const handleFundAmountChange = (amount) => {      
      // if (amount > parseFloat(maxFundAmount) && !details.referrer.startsWith("wv2_")) {
      //   alert(`The funding amount cannot be more than $${maxFundAmount}`);
      //   setFundAmount(parseFloat(maxFundAmount));
      // }
  
      setFundAmount(amount);
    };

    useEffect(() => {
        let pay_options = [];
        let requested_amount_tab_options = [];
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}`)
            .then(function (response) {
                // handle success
                setDetails(response.data);
                setOptions(response.data.options);
                setFundAmount(response.data.requestedamount);
                setMaxFundAmount(response.data.requestedamount);
                setStartDate(new Date(response.data.startdate));
                setDisbursementDate(response.data.disbursement_date);
                pay_options.push({ index: 0, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount).amount), option: "a", products: response.data.options.find(option => option.amount === response.data.requestedamount).products });
                requested_amount_tab_options.push({ index: 0, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount).amount), option: "a", products: response.data.options.find(option => option.amount === response.data.requestedamount).products });
                response.data.requestedamount_2 && pay_options.push({ index: 1, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_2).amount), option: "b", products: response.data.options.find(option => option.amount === response.data.requestedamount_2).products }) && requested_amount_tab_options.push({ index: 1, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_2).amount), option: "b", products: response.data.options.find(option => option.amount === response.data.requestedamount_2).products });
                response.data.requestedamount_3 && pay_options.push({ index: 2, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_3).amount), option: "c", products: response.data.options.find(option => option.amount === response.data.requestedamount_3).products }) && requested_amount_tab_options.push({ index: 2, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_3).amount), option: "c", products: response.data.options.find(option => option.amount === response.data.requestedamount_3).products });
                setPayOptions([...pay_options]);
                setRequestedAmountTabOptions([...requested_amount_tab_options]);
                setRequestedAmountOpenTab(requested_amount_tab_options[0]);
                setRequestedAmount({ index: 0, amount: pay_options[0].amount, option: pay_options[0].option, products: pay_options[0].products });
                setPurposeOfFund(response.data.purpose);
                const queryOptionArr = pay_options.filter(item => item.option === params.option);
                // setQueryOption(queryOptionArr[0].index);
                params.option && setRequestedAmount({ index: queryOptionArr[0].index, amount: queryOptionArr[0].amount, option: queryOptionArr[0].option, products: queryOptionArr[0].products  });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);

  return (
      <>
      {showConfirmBox && <ProductConfirmationBox showModal={showConfirmBox} product={confirmBoxInfo.product} typeOfPayment={confirmBoxInfo.typeOfPayment} fundsDisbursed={formatDate(disbursementDate ? disbursementDate : details.disbursement_date)} onHandleProduct={() => handleProduct(confirmBoxInfo.applicationUrl)} onCloseConfirmBox={() => setShowConfirmBox(false)}  />}
        <div className="headerBG">
          <div>
            <CalculatorHeader />
          </div>
          <div className="flex flex-col px-4 xl:px-0 pb-12 items-center xl:flex-row xl:items-start justify-between">
            <div className="w-full xl:w-1/3 hidden xl:flex justify-center xl:justify-start">
              <CalculatorDetails
                details={details}
                purposeOfFunds={purposeOfFunds}
                purposeOfFund={purposeOfFund}
                options={options}
                onPurposeChange={(e) => setPurposeOfFund(e.target.value)}
              />
            </div>
            <div className="w-full xl:w-1/3 flex justify-center xl:justify-center">
              <CalculatorPlanFields
                maxFundAmount={maxFundAmount}
                fundAmount={fundAmount}
                onChangeFundAmount={(amount) => handleFundAmountChange(amount)}
                onChangeStartDate={(date) => setStartDate(date)}
                startDate={startDate}
                errors={errors}
                disbursementDate={disbursementDate}
                details={details}
                options={options}
                modifyData={modifyData}
                onModifyData={handleModifyData}
                purposeOfFund={purposeOfFund}
                purposeOfFunds={purposeOfFunds}
                onPurposeChange={(e) => setPurposeOfFund(e.target.value)}
                onHandleCalculate={handleCalculate}
              />
            </div>
            <div className="w-full xl:w-1/3 hidden xl:flex justify-center xl:justify-end">
              <img src={CalculatorHeaderImage} alt="Elepay Calculator" className="w-64" />
            </div>
          </div>
        </div>
        <div className="py-12" style={{ backgroundImage: `url(${CalculatorPlanOptionsBackground})`, backgroundSize: "2160px", backgroundRepeat: "round", paddingBottom: "6rem", marginTop: "-6rem", paddingTop: "6rem"  }}>
          <div style={{ position: "relative", zIndex: "0"}}>
            <CalculatorPlanOptions onConfirmBox={(dayPlan, easyPayments, applicationUrl) => {setShowConfirmBox(true); setConfirmBoxInfo({ product: dayPlan, typeOfPayment: easyPayments, applicationUrl: applicationUrl }) }} disableSelectButton={disableSelectButton} details={details} options={options} requestedAmount={requestedAmount} />
          </div>
        </div>
        {details.referrer !== "flood" && <div className="p-4">
            <h3 className="text-md font-gilroy-regular italic">* Elepay will disburse funds into your nominated account within 7 days from start date. Disbursement of funds may vary based on weekends and public holidays.</h3>
        </div>}
        <div className="p-10 flex flex-col text-center xl:text-right xl:flex-row flex-wrap xl:justify-between items-center">
            <img className="flex w-40" src={ElepayPrimaryLogo} alt="Elepay" />
            <p className="text-sm pt-4 xl:pt-0 font-gilroy-regular">ABN: 47 634 728 591. Copyright 2022 Elepay. <br />All rights reserved.</p>
        </div>
      </>
  )
};

export default ElepayCalculator;