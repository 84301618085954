import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { isJwtExpired } from 'jwt-check-expiration';
import Header from './Paylater_107_Header';
import Pay_107_Calc_Details from './Paylater_107_Details';
import Paylater_107_Head from './Paylater_107_Head';
import Paylater_107_PayLater from './Paylater_107_PayLater';
import 'react-tabs/style/react-tabs.css';
import './style.css';
import GirlCouch from "../assets/images/CalcMobImage2.png";
import { initTrack } from "../helpers/tracking";

const Pay_107_Calc = ({ location, match }) => {
    const {partner_code, leadId, form_code="gen107later" } = match.params;
    const jwt = localStorage.getItem('token');
    const path = match.url.split('/');
    const [partnerName, setPartnerName] = useState("");

    const [details, setDetails] = useState(
        {
            "requestedamount": 0,
            "firstname": "",
            "address": {
              "fullAddress": "",
              "id": 0,
              "postcode": "0",
              "state": "",
              "streetName": "",
              "streetNumber": "",
              "suburb": "",
              "unitNumber": ""
            },
            "phone": "",
            "options": [
              {
                "amount": 0,
                "products": [
                  {
                    "application_url": "",
                    "earlyPayments": [
                        {
                            "amount": 0,
                            "date": "2022-02-17",
                            "sequence": 0
                        }
                    ],
                    "payments": [
                      {
                        "amount": 0,
                        "date": "2022-02-17",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-02-02",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-18",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-03",
                        "sequence": 0
                      }
                    ],
                    "product": {
                      "code": "",
                      "firstPayment": 0,
                      "name": "",
                      "numberOfDays": 0,
                      "numberOfPayments": 0,
                      "paymentInterval": 0
                    }
                  },
                ]
              }
            ],
            "payment_methods": [],
            "startdate": "",
            "email": "",
            "form_code": "",
            "lastname": ""
        }
    );

    const [options, setOptions] = useState([{
        "amount": 0,
        "products": [
          {
            "application_url": "",
            "earlyPayments": [
                {
                    "amount": 0,
                    "date": "2022-02-17",
                    "sequence": 0
                }
            ],
            "payments": [
              {
                "amount": 0,
                "date": "2022-02-17",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-02-02",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-18",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-03",
                "sequence": 0
              }
            ],
            "product": {
              "code": "",
              "firstPayment": 0,
              "name": "",
              "numberOfDays": 0,
              "numberOfPayments": 0,
              "paymentInterval": 0
            }
          }
        ]
      }]);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    
    const [requestedAmount, setRequestedAmount] = useState({ index: 0, amount: "0", option: "a" });
    const [payOptions, setPayOptions] = useState([]);
   
    const [showBlur, setShowBlur] = useState(false);

    const handleBlur = () => {
        setShowBlur(true);
    }

    const handleProduct = (applicationUrl) => {
        window.location.href=`${applicationUrl}`;
    }

    const [urlError, setUrlError] = useState(false);

    const ValidateUrl = (_) => {
      axios.get(`${process.env.REACT_APP_ENV_PREFIX}/partner/validatePath?partnerCode=${partner_code}&formCode=${form_code}`)
        .then(function (response) {            
          console.log({response});
          if (response.data.partner_name != null) {          
            setUrlError(response.data.partner_name == 'invalid' ? true : false);      
          }         
        })
        .catch(function (error) {            
          console.log(error);
        });
    };

    useEffect(() => {
        console.log(initTrack("RayWhitePay_107", partner_code, form_code, path[3]));
        ValidateUrl();
        let pay_options = [];
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}`)
            .then(function (response) {
                // handle success
                setDetails(response.data);
                setOptions(response.data.options);
                pay_options.push({ index: 0, amount: response.data.requestedamount, option: "a" });
                // response.data.requestedamount_2 && pay_options.push({ index: 1, amount: response.data.requestedamount_2, option: "b" });
                // response.data.requestedamount_3 && pay_options.push({ index: 2, amount: response.data.requestedamount_3, option: "c" });
                setPayOptions([...pay_options]);
                setRequestedAmount({ index: 0, amount: pay_options[0].amount, option: pay_options[0].option });
                const queryOptionArr = pay_options.filter(item => item.option === params.option);
                // setQueryOption(queryOptionArr[0].index);
                params.option && setRequestedAmount({ index: queryOptionArr[0].index, amount: queryOptionArr[0].amount, option: queryOptionArr[0].option });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);

    return (
        <div className="calc107Container m-auto">          
          <Header partner_code={partner_code} headerTitle="paylater" />

          {urlError == false && ( 
            
            <div className="flex flex-col tablet:flex-row">
                <Pay_107_Calc_Details details={details} partnerCode={partner_code} requestedAmount={requestedAmount} showBlur={showBlur} />
                <div className="w-full flex flex-wrap justify-center ml-0 tablet:ml-16">
                    {/* {jwt && !isJwtExpired(jwt) && <button type="button" className="border border-purple-light w-max mt-5 p-2 py-3 px-4 text-purple-light rounded-3xl font-gilroy-bold" onClick={() => window.location = process.env.REACT_APP_PORTAL_PREFIX}>
                        <motion.button whileHover={{ scale: 1.1 }}>
                            Back to Portal
                        </motion.button>
                    </button>} */}
                    {/* <h3 className="mt-10 text-4xl font-gilroy-semibold text-purple-light" style={{ filter: `${showBlur && 'blur(10px)'}` }}>Let's manage your property expenses the easy way</h3> */}
                        {/* <div className="flex items-baseline w-full">
                            <h3 className="font-gilroy-bold text-purple-dark text-2xl text-left w-full mt-8 ml-4 tablet:ml-0" style={{ filter: `${showBlur && 'blur(10px)'}` }}>{payOptions.length > 1 ? 'Amount options' : 'Payment options'}</h3>
                        </div> */}
                    <div className="flex flex-col m-4 tablet:m-0 tablet:mt-4 w-full">
                        {/* {payOptions.length > 1 && <Tabs className="w-full" style={{ filter: `${showBlur && 'blur(10px)'}` }} selectedIndex={requestedAmount.index} onSelect={(e) => setRequestedAmount({ index: e, amount: payOptions[e].amount, option: payOptions[e].option })}>
                            <div>
                                <TabList >
                                    {payOptions.length > 1 && payOptions.map(option => (<Tab>{formatCurrency(option.amount)}</Tab>))}
                                </TabList>
                            </div>

                            <TabPanel>
                                <h2 className="font-gilroy-bold text-purple-dark text-lg">Choose a payment option that works best for you:</h2>
                            </TabPanel>
                            <TabPanel>
                                <h2 className="font-gilroy-bold text-purple-dark text-lg">Choose a payment option that works best for you:</h2>
                            </TabPanel>
                            <TabPanel>
                                <h2 className="font-gilroy-bold text-purple-dark text-lg">Choose a payment option that works best for you:</h2>
                            </TabPanel>
                        </Tabs>} */}
                        <div className="flex flex-col tablet:flex-row justify-center w-full">
                            {/* {details.payment_methods.includes("now") && <div className="w-full mt-4 order-2 tablet:order-1 tablet:mt-0 tablet:w-2/5 mr-2 p-8 bg-black-light rounded-2xl border border-purple-extra-light">
                                <PayToday_107 onShowPayTodayOptions={option => setShowPayTodayOptions(option)} showPayTodayOptions={showPayTodayOptions} showBlur={showBlur} />
                                <PayToday_107_CCDC details={details} leadId={leadId} requestedAmount={requestedAmount} onHandleBlur={handleBlur} showBlur={showBlur} />
                            </div>} */}
                            {details.payment_methods.includes("later") && <div className="w-full order-1 tablet:order-2 ml-2 p-8 bg-purple-light rounded-3xl border border-purple-extra-light">
                                <Paylater_107_Head planOptions={options} details={details} leadId={leadId} requestedAmount={requestedAmount} /> 
                                <Paylater_107_PayLater planOptions={options} details={details} payOptionsLength={payOptions.length} leadId={leadId} reqAmount={requestedAmount} onRequestedAmountChange={(e) => setRequestedAmount(e.target.value)} showBlur={showBlur} />
                            </div>}
                        </div>
                        <script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/8513039.js"></script>
                    </div>
                </div>
            </div>

          )}

          {urlError == true && (
              <div className="pl-large pr-large pt-10 pb-20 new-header">
                  <div className="flex flex-row xl:flex-start justify-wrap">
                  <div className="text-left flex-1">
                      <h3 className="text-green-light text-1xl uppercase font-gilroy-bold pb-4 pt-14">
                      Oops!
                      </h3>

                      <h1 className="text-blue text-6xl pb-8 font-gilroy-bold">
                      Something has gone wrong!
                      </h1>

                      <p className="text-blue text-2xl font-gilroy-regular">
                      It appears that your partner has not been registered for this product!{" "}
                      Please contact <span className="font-gilroy-bold">Elepay </span>
                      for further assistance.
                      </p>
                  </div>

                  <div className="text-right flex-1">
                      <img
                      className="image-large ml-auto "
                      src={GirlCouch}
                      alt="Elepay Logo"
                      />
                  </div>
                  </div>
              </div>
          )}

        </div>
    )
}

export default Pay_107_Calc;