import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { motion } from "framer-motion";
import { isJwtExpired } from 'jwt-check-expiration';
import Header from './pay_components/Header';
import Tabs from "./pay_components/Tabs";
import Details from './components/Details';
import PayLater from './pay_components/PayLater';
import PayNow from './pay_components/PayNow';
import 'react-tabs/style/react-tabs.css';

const PaymentHub = ({ location, match }) => {
    const { leadId } = match.params;
    const jwt = localStorage.getItem('token');

    const [details, setDetails] = useState(
        {
            "requestedamount": 0,
            "firstname": "",
            "address": {
              "fullAddress": "",
              "id": 0,
              "postcode": "0",
              "state": "",
              "streetName": "",
              "streetNumber": "",
              "suburb": "",
              "unitNumber": ""
            },
            "phone": "",
            "options": [
              {
                "amount": 0,
                "products": [
                  {
                    "application_url": "",
                    "payments": [
                      {
                        "amount": 0,
                        "date": "2022-02-17",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-02-02",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-18",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-03",
                        "sequence": 0
                      }
                    ],
                    "product": {
                      "code": "",
                      "firstPayment": 0,
                      "name": "",
                      "numberOfDays": 0,
                      "numberOfPayments": 0,
                      "paymentInterval": 0
                    }
                  },
                ]
              }
            ],
            "startdate": "",
            "email": "",
            "form_code": "",
            "lastname": ""
        }
    );

    const [options, setOptions] = useState([{
        "amount": 0,
        "products": [
          {
            "application_url": "",
            "payments": [
              {
                "amount": 0,
                "date": "2022-02-17",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-02-02",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-18",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-03",
                "sequence": 0
              }
            ],
            "product": {
              "code": "",
              "firstPayment": 0,
              "name": "",
              "numberOfDays": 0,
              "numberOfPayments": 0,
              "paymentInterval": 0
            }
          }
        ]
      }]);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [requestedAmount, setRequestedAmount] = useState({ index: 0, amount: "0", option: "a", products: [] });
    const [payOptions, setPayOptions] = useState([]);
    const [queryOption, setQueryOption] = useState("");
    const [requestedAmountTabOptions, setRequestedAmountTabOptions] = useState([]);
    // const tabOptions = ["$1,200.00", "$1,400.00", "$1,600.00"];
    const [requestedAmountOpenTab, setRequestedAmountOpenTab] = useState({ index: 0, amount: "0", option: "a", products: [] });

    const [showBlur, setShowBlur] = useState(false);

    const handleBlur = () => {
        setShowBlur(true);
    }

    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    useEffect(() => {
        let pay_options = [];
        let requested_amount_tab_options = [];
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}`)
            .then(function (response) {
                // handle success
                setDetails(response.data);
                setOptions(response.data.options);
                pay_options.push({ index: 0, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount).amount), option: "a", products: response.data.options.find(option => option.amount === response.data.requestedamount).products });
                requested_amount_tab_options.push({ index: 0, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount).amount), option: "a", products: response.data.options.find(option => option.amount === response.data.requestedamount).products });
                response.data.requestedamount_2 && pay_options.push({ index: 1, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_2).amount), option: "b", products: response.data.options.find(option => option.amount === response.data.requestedamount_2).products }) && requested_amount_tab_options.push({ index: 1, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_2).amount), option: "b", products: response.data.options.find(option => option.amount === response.data.requestedamount_2).products });
                response.data.requestedamount_3 && pay_options.push({ index: 2, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_3).amount), option: "c", products: response.data.options.find(option => option.amount === response.data.requestedamount_3).products }) && requested_amount_tab_options.push({ index: 2, amount: formatCurrency(response.data.options.find(option => option.amount === response.data.requestedamount_3).amount), option: "c", products: response.data.options.find(option => option.amount === response.data.requestedamount_3).products });
                setPayOptions([...pay_options]);
                setRequestedAmountTabOptions([...requested_amount_tab_options]);
                setRequestedAmountOpenTab(requested_amount_tab_options[0]);
                setRequestedAmount({ index: 0, amount: pay_options[0].amount, option: pay_options[0].option, products: pay_options[0].products });
                const queryOptionArr = pay_options.filter(item => item.option === params.option);
                // setQueryOption(queryOptionArr[0].index);
                params.option && setRequestedAmount({ index: queryOptionArr[0].index, amount: queryOptionArr[0].amount, option: queryOptionArr[0].option, products: queryOptionArr[0].products  });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);

    return (
        <div>
            <Header />
            <Details details={details} options={options} requestedAmount={requestedAmount} showBlur={showBlur} />
            <div className="flex flex-wrap justify-center" style={{ maxWidth: "min-content", minWidth: 0, margin: "auto" }}>
                {/* {jwt && !isJwtExpired(jwt) && <button type="button" className="border border-purple-light w-max p-2 py-3 px-4 mt-5 text-purple-light rounded-3xl font-gilroy-bold" onClick={() => window.location = process.env.REACT_APP_PORTAL_PREFIX}>
                    <motion.button whileHover={{ scale: 1.1 }}>
                        Back to Portal
                    </motion.button>
                </button>} */}
                <div className="flex items-baseline w-full">
                    <h3 className="font-gilroy-bold text-purple-dark text-2xl text-center w-full mt-8 ml-4 lg:ml-0" style={{ filter: `${showBlur && 'blur(10px)'}` }}>{payOptions.length > 1 && 'Requested Amounts'}</h3>
                </div>
                <div className="flex flex-col m-4 lg:m-0 lg:mt-4">
                    {payOptions.length > 1 && 
                        (<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} style={{ filter: `${showBlur && 'blur(10px)'}` }}>
                            {/* <p className="text-4xl text-purple-dark m-auto mt-20 mb-10 font-gilroy-bold">
                                Requested Amount
                            </p> */}
                            <Tabs
                                textColor={'text-purple-light'}
                                bgColor={'bg-purple-light'}
                                selectedTextColor={'text-purple-extra-light'}
                                borderColor={'border-purple-light'}
                                tabBackgroundColor={'bg-purple-extra-light'}
                                normalTabTextColor={'text-purple-light'}
                                activeWidth={28}
                                activeTabWidth="7rem"
                                tabOptions={requestedAmountTabOptions}
                                onTabChange={(option) => { setRequestedAmountOpenTab(option); setRequestedAmount(option) }}
                                selectedTab={requestedAmountOpenTab}
                            />
                            {/* {openTab === "Single payment" ? <SingleOption /> : <MultipleOptions />} */}
                            </motion.div>)
                    }
                    <div className="mb-12">
                        <PayLater details={details} options={options} leadId={leadId} requestedAmount={requestedAmount} showBlur={showBlur} />
                    </div>
                    {/* <div className="flex-col lg:flex lg:flex-row"> */}
                    <div className="" style={{ filter: `${showBlur && 'blur(10px)'}`, display: `${showBlur ? 'contents' : 'block'}` }}>
                        <PayNow details={details} leadId={leadId} requestedAmount={requestedAmount} onHandleBlur={handleBlur} showBlur={showBlur} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentHub;