import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Joi from 'joi-browser';
import Header from './Pay_107_Calc_Header';
import Pay_107_Calc_Details from './Pay_107_Calc_Details';
import Pay_107_Calc_Head from './Pay_107_Calc_Head';
import Pay_107_Calc_PayLater from './Pay_107_Calc_PayLater';

import 'react-tabs/style/react-tabs.css';
import './style.css';

const Pay_107_Calc = ({ location, match }) => {
    const {partner_code, leadId } = match.params;
    const [details, setDetails] = useState(
        {
            "requestedamount": 0,
            "firstname": "",
            "address": {
              "fullAddress": "",
              "id": 0,
              "postcode": "0",
              "state": "",
              "streetName": "",
              "streetNumber": "",
              "suburb": "",
              "unitNumber": ""
            },
            "phone": "",
            "options": [
              {
                "amount": 0,
                "products": [
                  {
                    "application_url": "",
                    "earlyPayments": [
                        {
                            "amount": 0,
                            "date": "2022-02-17",
                            "sequence": 0
                        }
                    ],
                    "payments": [
                      {
                        "amount": 0,
                        "date": "2022-02-17",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-02-02",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-18",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-03",
                        "sequence": 0
                      }
                    ],
                    "product": {
                      "code": "",
                      "firstPayment": 0,
                      "name": "",
                      "numberOfDays": 0,
                      "numberOfPayments": 0,
                      "paymentInterval": 0
                    }
                  },
                ]
              }
            ],
            "payment_methods": [],
            "startdate": "",
            "email": "",
            "form_code": "",
            "lastname": ""
        }
    );

    const [options, setOptions] = useState([{
        "amount": 0,
        "products": [
          {
            "application_url": "",
            "earlyPayments": [
                {
                    "amount": 0,
                    "date": "2022-02-17",
                    "sequence": 0
                }
            ],
            "payments": [
              {
                "amount": 0,
                "date": "2022-02-17",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-02-02",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-18",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-03",
                "sequence": 0
              }
            ],
            "product": {
              "code": "",
              "firstPayment": 0,
              "name": "",
              "numberOfDays": 0,
              "numberOfPayments": 0,
              "paymentInterval": 0
            }
          }
        ]
      }]);

    // const { options } = details;


    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [requestedAmount, setRequestedAmount] = useState({ index: 0, amount: "0", option: "a" });
    const [payOptions, setPayOptions] = useState([]);

    const [fundAmount, setFundAmount] = useState(0);
    const [startDate, setStartDate] = useState(new Date());

    // Validation Errors
    const [errors, setErrors] = useState({});

    const [showBlur, setShowBlur] = useState(false);

    const handleProduct = (applicationUrl) => {
        window.location.href=`${applicationUrl}`;
    }

    useEffect(() => {
        let pay_options = [];
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}`)
            .then(function (response) {
                // handle success
                setDetails(response.data);
                setFundAmount(response.data.requestedamount)
                setOptions(response.data.options);
                pay_options.push({ index: 0, amount: response.data.requestedamount, option: "a" });
                // response.data.requestedamount_2 && pay_options.push({ index: 1, amount: response.data.requestedamount_2, option: "b" });
                // response.data.requestedamount_3 && pay_options.push({ index: 2, amount: response.data.requestedamount_3, option: "c" });
                setPayOptions([...pay_options]);
                setRequestedAmount({ index: 0, amount: pay_options[0].amount, option: pay_options[0].option });
                const queryOptionArr = pay_options.filter(item => item.option === params.option);
                // setQueryOption(queryOptionArr[0].index);
                params.option && setRequestedAmount({ index: queryOptionArr[0].index, amount: queryOptionArr[0].amount, option: queryOptionArr[0].option });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });

        // populateSchedule();
    }, []);

    const handleCalculate = (fundAmount, startDate, start = false) => {
      // Validation Schema
      const schema = {
          fundAmount: Joi.number().min(1).label("Fund amount").required(),
          startDate: Joi.date().required()
      }

      const validate = () => {
          const validateDetails = {
              fundAmount, startDate
          }
          const options = { abortEarly: false };
          const { error } = Joi.validate(validateDetails, schema, options);
          if (!error) return null;

          const errors = {};
          error.details.map(error => errors[error.path[0]] = error.message);

          return errors;
      }

      // Validating upon submit
      const errors = validate();
      console.log('errors', errors);
      setErrors(errors || {});
      if (errors) return;

      axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_update`, {
          enc_id: leadId,
          requestedamount: fundAmount,
          startdate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2')
      })
          .then(function (response) {
              setOptions(response.data.options);
              if(start) window.location.href=`${response.data.options[0].products[0].application_url}`;
          })
          .catch(function (error) {
              console.log('error', error);
          });
  };


    // console.log('payOptions', payOptions.length);
    return (
        <div className="calc107Container m-auto">
            <Header partner_code={partner_code} headerTitle="paylater" />
            <div className="flex flex-col tablet:flex-row">
                <Pay_107_Calc_Details details={details} requestedAmount={requestedAmount} showBlur={showBlur} />
                <div className="w-full flex flex-wrap justify-center ml-0 tablet:ml-16">
                    {/* <h3 className="mt-10 text-4xl font-gilroy-semibold text-purple-light" style={{ filter: `${showBlur && 'blur(10px)'}` }}>Let's manage your property expenses the easy way</h3> */}
                        {/* <div className="flex items-baseline w-full">
                            <h3 className="font-gilroy-bold text-purple-dark text-2xl text-left w-full mt-8 ml-4 tablet:ml-0" style={{ filter: `${showBlur && 'blur(10px)'}` }}>{payOptions.length > 1 ? 'Amount options' : 'Payment options'}</h3>
                        </div> */}
                    <div className="flex flex-col m-4 tablet:m-0 tablet:mt-4 w-full">
                        {/* {payOptions.length > 1 && <Tabs className="w-full" style={{ filter: `${showBlur && 'blur(10px)'}` }} selectedIndex={requestedAmount.index} onSelect={(e) => setRequestedAmount({ index: e, amount: payOptions[e].amount, option: payOptions[e].option })}>
                            <div>
                                <TabList>
                                    {payOptions.length > 1 && payOptions.map(option => (<Tab>{formatCurrency(option.amount)}</Tab>))}
                                </TabList>
                            </div>

                            <TabPanel>
                                <h2 className="font-gilroy-bold text-purple-dark text-lg">Choose a payment option that works best for you:</h2>
                            </TabPanel>
                            <TabPanel>
                                <h2 className="font-gilroy-bold text-purple-dark text-lg">Choose a payment option that works best for you:</h2>
                            </TabPanel>
                            <TabPanel>
                                <h2 className="font-gilroy-bold text-purple-dark text-lg">Choose a payment option that works best for you:</h2>
                            </TabPanel>
                        </Tabs>} */}
                        <div className="flex flex-col tablet:flex-row justify-center w-full">
                            {/* {details.payment_methods.includes("now") && <div className="w-full mt-4 order-2 tablet:order-1 tablet:mt-0 tablet:w-2/5 mr-2 p-8 bg-black-light rounded-2xl border border-purple-extra-light">
                                <PayToday_107 onShowPayTodayOptions={option => setShowPayTodayOptions(option)} showPayTodayOptions={showPayTodayOptions} showBlur={showBlur} />
                                <PayToday_107_CCDC details={details} leadId={leadId} requestedAmount={requestedAmount} onHandleBlur={handleBlur} showBlur={showBlur} />
                            </div>} */}
                            {details.payment_methods.includes("later") && <div className="w-full order-1 tablet:order-2 ml-2 p-8 bg-purple-light rounded-3xl border border-purple-extra-light">
                                <Pay_107_Calc_Head errors={errors} planOptions={options} fundAmount={fundAmount} startDate={startDate} onHandleFundAmount={(e) =>  setFundAmount(e.target.value)} onHandleStartDate={(date) => setStartDate(date)} onHandleCalculate={handleCalculate} details={details} leadId={leadId} requestedAmount={requestedAmount} /> 
                                <Pay_107_Calc_PayLater fundAmount={fundAmount} startDate={startDate} planOptions={options} details={details} payOptionsLength={payOptions.length} leadId={leadId} reqAmount={requestedAmount} onRequestedAmountChange={(e) => setRequestedAmount(e.target.value)} showBlur={showBlur} onHandleCalculate={handleCalculate} />
                            </div>}
                        </div>
                        <script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/8513039.js"></script>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pay_107_Calc;