import React from "react";
import ElepayLogo from '../../assets/images/elepay-logo.png';

const CalculatorHeader = () => {
  return (
        <div className="flex flex-col pb-6 xl:pb-0 xl:flex-row items-center justify-between flex-wrap items-center">
            <div className="w-full xl:w-1/3 p-10 flex flex-col xl:flex-row flex-wrap xl:flex-start items-center">
                <img className="flex w-40" src={ElepayLogo} alt="Elepay" />
            </div>
            <div className="w-1/3">
                <h3 className="w-100 hidden xl:block text-white text-center py-4 font-gilroy-bold" style={{ fontSize: "1.8rem", lineHeight: "1.2" }}>Welcome to Australia's Pay Later specialist for property owners</h3>
            </div>
            <div className="w-1/3">

            </div>
        </div>
    );
};

export default CalculatorHeader;

