import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import { motion } from "framer-motion";
import Tabs from "./Tabs";
import OptionCard from './OptionCard';
import ProductConfirmationBox from '../components/ProductConfirmationBox';
import { fullMonthNames } from '../../constants/dateConstants';

const PayLater = ({ details, options, leadId, dayPlanAmount60, dayPlanAmount90, dayPlanAmount120, showBlur, requestedAmount }) => {
    const payLaterTabOptions = [{ index: 0, option: "1 x payment", optionSelected: 'a' }, { index: 1, option: "4 x payments", optionSelected: 'b'}];
    const [payLaterOpenTab, setPayLaterOpenTab] = useState({ index: 0, option: payLaterTabOptions[0].option, optionSelected: 'a' });
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [confirmBoxInfo, setConfirmBoxInfo] = useState({ product: "", typeOfPayment: "", fundsDisbursed: "", applicationUrl: ""});
    const [showMultiplePaymentOptions, setShowMultiplePaymentOptions] = useState(false);

    const { disbursement_date } = details;

    const formatDate = (date) => {
        const startDate = new Date(date);
        return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
    }

    const singlePayment = _.orderBy(requestedAmount.products.filter(product => product.payments.length === 1), item => item.product.numberOfDays, ['asc']);
    const multiplePayments = _.orderBy(requestedAmount.products.filter(product => product.payments.length === 4), item => item.product.numberOfDays, ['asc']);

    const products = payLaterOpenTab.index === 0 ? singlePayment : multiplePayments;

    const productCodeArray = [];
    {products.map((product) => {
        productCodeArray.push(product['product'].numberOfDays);
    })}

    const handleProduct = (applicationUrl) => {
        window.location.href=`${applicationUrl}`;
    }

    return (<div>
    {showConfirmBox && <ProductConfirmationBox showModal={showConfirmBox} product={confirmBoxInfo.product} typeOfPayment={confirmBoxInfo.typeOfPayment} fundsDisbursed={formatDate(disbursement_date)} onHandleProduct={() => handleProduct(confirmBoxInfo.applicationUrl)} onCloseConfirmBox={() => setShowConfirmBox(false)}  />}
        <div className="m-0 pb-12 md:justify-center lg:flex-start lg:flex-start bg-purple-light rounded-3xl" style={{ minWidth: 0, filter: `${showBlur && 'blur(10px)'}` }}>
            <div className="flex flex-col items-center justify-between items-end  pt-12 ">
                <p className="font-gilroy-bold w-full text-xl text-white text-center"><span><span className="text-3xl font-gilroy-semibold">Pay Later </span><span className="text-3xl font-gilroy-regular">Options</span></span></p>
                {payLaterTabOptions.length > 1 && 
                    (<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                        {/* <p className="text-4xl text-purple-dark m-auto mt-20 mb-10 font-gilroy-bold">
                            Requested Amount
                        </p> */}
                        {(productCodeArray.includes(60) || productCodeArray.includes(90)) && <Tabs
                            textColor={'text-purple-light'}
                            bgColor={'bg-green-light'}
                            selectedTextColor={'text-purple-light'}
                            borderColor={'border-green-light'}
                            activeWidth={36}
                            showBlur={showBlur}
                            activeTabWidth="9rem"
                            tabBackgroundColor={'bg-purple-light'}
                            normalTabTextColor={'text-white'}
                            tabOptions={payLaterTabOptions}
                            onTabChange={(option) => setPayLaterOpenTab(option)}
                            selectedTab={payLaterOpenTab}
                        />}
                        {/* {openTab === "Single payment" ? <SingleOption /> : <MultipleOptions />} */}
                        </motion.div>)
                }
            </div>
            <div className={`lg:hidden lg:flex justify-center ${(!productCodeArray.includes(60) || !productCodeArray.includes(90)) && "mt-4"}`}>
                <Carousel swipeable={true} autoPlay={false} interval={300000} infiniteLoop={true} showIndicators={false} showStatus={false} width={500}>
                    {products.map((product) => {
                        const payments = _.sortBy(product.payments, function(dateObj) { return new Date(dateObj.date)});
                        {/* return <div className={`pl-4 mt-4 flex justify-center flex-wrap ${product['product'].numberOfDays === 60 && 'order-1'} ${product['product'].numberOfDays === 90 && 'order-2'} ${product['product'].numberOfDays === 120 && 'order-3'}`} key={product['product'].numberOfDays}> */}
                        return <div className={`pl-4 mt-4 flex justify-center flex-wrap`} key={product['product'].numberOfDays}>
                            <OptionCard
                                requestedAmount={requestedAmount}
                                applicationUrl={product.application_url}
                                dayPlan={product['product'].numberOfDays}
                                noPayFor={product['product'].firstPayment}
                                code={product['product'].code}
                                name={product['product'].name}
                                easyPayments={product['product'].numberOfPayments}
                                selectedTab={payLaterOpenTab}
                                price={product.payments[0].amount.toFixed(2)}
                                dateOptions={payments}
                                onConfirmBox={(dayPlan, easyPayments) => {setShowConfirmBox(true); setConfirmBoxInfo({ product: dayPlan, typeOfPayment: easyPayments, applicationUrl: product.application_url }) }}
                                onShowMultiplePaymentOptions={() => setShowMultiplePaymentOptions(true)}
                                showMultiplePaymentOptions={showMultiplePaymentOptions}
                            />
                        </div>
                    })}
                </Carousel>
            </div>
            <div className={`hidden lg:block lg:flex justify-center px-20 ${(!productCodeArray.includes(60) || !productCodeArray.includes(90)) && "mt-4"}`}>
                    {products.map((product) => {
                        const payments = _.sortBy(product.payments, function(dateObj) { return new Date(dateObj.date)});
                        {/* return <div key={product['product'].numberOfDays} className={`px-1 flex justify-center flex-wrap ${product['product'].numberOfDays === 60 && 'order-1'} ${product['product'].numberOfDays === 90 && 'order-2'} ${product['product'].numberOfDays === 120 && 'order-3'}`}> */}
                        return <div key={product['product'].numberOfDays} className={`px-1 flex justify-center flex-wrap`}>
                            <OptionCard
                                requestedAmount={requestedAmount}
                                applicationUrl={product.application_url}
                                dayPlan={product['product'].numberOfDays}
                                noPayFor={product['product'].firstPayment}
                                code={product['product'].code}
                                name={product['product'].name}
                                easyPayments={product['product'].numberOfPayments}
                                selectedTab={payLaterOpenTab}
                                price={product.payments[0].amount.toFixed(2)}
                                dateOptions={payments}
                                onConfirmBox={(dayPlan, easyPayments) => {setShowConfirmBox(true); setConfirmBoxInfo({ product: dayPlan, typeOfPayment: easyPayments, applicationUrl: product.application_url }) }}
                                onShowMultiplePaymentOptions={() => setShowMultiplePaymentOptions(true)}
                                showMultiplePaymentOptions={showMultiplePaymentOptions}
                            />
                        </div>
                    })}
            </div>
            <div className="flex flex-col pt-10">
            {/* <small style={{fontSize: '6px', fontStyle: "italic", color: "#fff"}}>* If you enter into the second Loan, there are no fees payable in relation to the first loan</small> */}
            {productCodeArray.includes(90) && <small style={{fontSize: '6px', fontStyle: "italic", color: "#fff"}}>* 90 days is the period for the First Loan (28 days) plus the maximum period for the Second Loan (62 days - if you elect to enter into it)</small>}
            {productCodeArray.includes(120) && <small style={{fontSize: '6px', fontStyle: "italic", color: "#fff"}}>^ 120 days is the period for the First Loan (58 days) plus the maximum period for the Second Loan (62 days - if you elect to enter into it)</small>}
            </div>
        </div>
    </div>)
}

export default PayLater;