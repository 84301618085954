import React, { useState } from 'react';
import { motion } from 'framer-motion';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { fullMonthNames } from '../constants/dateConstants';

const Pay_107_Calc_Head = ({ showPayLaterOptions, errors, details, planOptions,  onHandleCalculate, leadId, payOptionsLength, showBlur, reqAmount, amountBefore107days }) => {
    const [fundAmount, setFundAmount] = useState(details.requestedamount);
    const [startDate, setStartDate] = useState(new Date());
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }
    const formatDates = (date) => {
        const startDate = new Date(date);
        return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
    }

    // const leadId = 101;
    const dayPlan = 107;
    return (<div className="flex justify-center tablet:block">
        <div className={`m-0 flex flex-col items-center md:justify-center lg:flex-start lg:flex-start bg-purple-light ${payOptionsLength === 1 && 'rounded-2xl rounded-b-none' }`} style={{ minWidth: 0, filter: `${showBlur && 'blur(10px)'}` }}>
            <div className="flex flex-col justify-center items-center">
                {/* <p className="font-gilroy-bold text-xl text-green-light">Enjoy More Time</p> */}
                <h3 className="w-full tablet:w-4/5 text-white font-gilroy-bold text-3xl">Welcome to Australia's Pay Later Specialist for Property Owners</h3>
                <div className="flex flex-col my-4">
                <p className="text-green-light text-center xl:text-left text-2xl py-8 xl:py-4 font-gilroy-bold">Let's calculate your plan</p>
                <div className="flex flex-col">
                    <p className={`mt-2 font-gilroy-semibold text-sm xl:text-md text-white`}>How much funding is required?</p>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">
                                $
                            </span>
                        </div>
                        <input type="text" name="requestedAmount" className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold`} placeholder="0.00" onChange={(e) => { setStartDate(new Date());  setFundAmount(e.target.value)}} value={fundAmount} />
                        {/* <input type="text" name="requestedAmount" className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold`} placeholder="0.00" onChange={(e) => { setStartDate(new Date());  setFundAmount(e.target.value)}} value={fundAmount} /> */}
                    </div>
                    {errors['fundAmount'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['fundAmount']}</p>}
                </div>

                <p className={`mt-5 font-gilroy-semibold text-sm xl:text-md text-white`}>When would you like your plan to start?</p>
                <div className="w-full mt-1 flex justify-start items-center">
                    <span className="w-7 pl-3 absolute" style={{ color: "#4139ff" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" style={{ marginLeft: "-0.2rem" }} viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    </span>
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        minDate={new Date()}
                        // onDayChange={(date) => {onModifyData(); setStartDate(date); setDateModified(true)}}
                        onDayChange={(date) => {setStartDate(date)}}
                        // value={(!modifyData && details.startdate) ? formatDates(details.startdate) : formatDates(startDate)}
                        value={(details.startdate) ? formatDates(details.startdate) : formatDates(startDate)}
                        dayPickerProps={{
                            modifiers: {
                                disabled: [
                                    {
                                        before: new Date()
                                    }
                                ]
                            }
                        }}
                    />
                </div>
                {!disableSubmitButton && <div className="mt-6 flex self-center w-full">
                        <button type="button" onClick={() => onHandleCalculate(fundAmount, startDate)} className="bg-green-light p-2 mt-2 py-3 px-4 text-purple-light rounded-3xl font-gilroy-bold w-full" disabled={disableSubmitButton}>
                            <motion.button whileHover={{ scale: 1.1 }} >
                                CALCULATE NOW
                            </motion.button>
                        </button>
                    </div>}
                </div>
            </div>
            {showPayLaterOptions && <div className="flex flex-col">
                    <div className="mt-4 flex justify-center flex-wrap" key={dayPlan}>
                        <div className="p-4 bg-white rounded-2xl">
                            <p className="text-md font-gilroy-bold text-purple-dark">Your payment of {formatCurrency(planOptions[0].products[0].payments[0]?.amount)} is in</p>
                            <h3 className="text-5xl pt-2 text-purple-dark font-gilroy-bold">{planOptions[0].products[0].product['numberOfDays']} days</h3>
                        </div>
                    </div>
            </div>}
        </div>
    </div>)
}

export default Pay_107_Calc_Head;