import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import { motion } from "framer-motion";
import Tabs from "./Tabs";
import OptionCard from './OptionCard';

const PayLater = ({ details, options, leadId, dayPlanAmount60, dayPlanAmount90, dayPlanAmount120, showBlur, requestedAmount }) => {
    const payLaterTabOptions = [{ index: 0, option: "1 x payment", optionSelected: 'a' }, { index: 1, option: "4 x payments", optionSelected: 'b'}];
    const [payLaterOpenTab, setPayLaterOpenTab] = useState({ index: 0, option: payLaterTabOptions[0].option, optionSelected: 'a' });

    const singlePayment = _.orderBy(requestedAmount.products.filter(product => product.payments.length === 1), item => item.product.numberOfDays, ['asc']);
    const multiplePayments = _.orderBy(requestedAmount.products.filter(product => product.payments.length === 4), item => item.product.numberOfDays, ['asc']);

    const products = payLaterOpenTab.index === 0 ? singlePayment : multiplePayments;

    return (<div>
        <div className="m-0 pb-12 md:justify-center lg:flex-start lg:flex-start bg-purple-light rounded-3xl" style={{ minWidth: 0, filter: `${showBlur && 'blur(10px)'}` }}>
            <div className="flex flex-col items-center justify-between items-end  pt-12 ">
                <p className="font-gilroy-bold w-full text-xl text-white text-center"><span><span className="text-3xl font-gilroy-semibold">Pay Later </span><span className="text-3xl font-gilroy-regular">Options</span></span></p>
                {payLaterTabOptions.length > 1 && 
                    (<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                        {/* <p className="text-4xl text-purple-dark m-auto mt-20 mb-10 font-gilroy-bold">
                            Requested Amount
                        </p> */}
                        <Tabs
                            textColor={'text-purple-light'}
                            bgColor={'bg-green-light'}
                            selectedTextColor={'text-purple-light'}
                            borderColor={'border-green-light'}
                            activeWidth={36}
                            showBlur={showBlur}
                            activeTabWidth="9rem"
                            tabBackgroundColor={'bg-purple-light'}
                            normalTabTextColor={'text-white'}
                            tabOptions={payLaterTabOptions}
                            onTabChange={(option) => setPayLaterOpenTab(option)}
                            selectedTab={payLaterOpenTab}
                        />
                        </motion.div>)
                }
            </div>
            <div className="lg:hidden lg:flex justify-center">
                <Carousel swipeable={true} autoPlay={false} interval={300000} infiniteLoop={true} showIndicators={false} showStatus={false} width={500}>
                    {products.map((product) => {
                        const payments = _.sortBy(product.payments, function(dateObj) { return new Date(dateObj.date)});
                        return <div className={`pl-4 mt-4 flex justify-center flex-wrap`} key={product['product'].numberOfDays}>
                            <OptionCard
                                requestedAmount={requestedAmount}
                                applicationUrl={product.application_url}
                                dayPlan={product['product'].numberOfDays}
                                noPayFor={product['product'].firstPayment}
                                code={product['product'].code}
                                name={product['product'].name}
                                easyPayments={product['product'].numberOfPayments}
                                selectedTab={payLaterOpenTab}
                                price={product.payments[0].amount.toFixed(2)}
                                dateOptions={payments}
                            />
                        </div>
                    })}
                </Carousel>
            </div>
            <div className="hidden lg:block lg:flex justify-center px-20">
                    {products.map((product) => {
                        const payments = _.sortBy(product.payments, function(dateObj) { return new Date(dateObj.date)});
                        return <div key={product['product'].numberOfDays} className={`px-1 flex justify-center flex-wrap`}>
                            <OptionCard
                                requestedAmount={requestedAmount}
                                applicationUrl={product.application_url}
                                dayPlan={product['product'].numberOfDays}
                                noPayFor={product['product'].firstPayment}
                                code={product['product'].code}
                                name={product['product'].name}
                                easyPayments={product['product'].numberOfPayments}
                                selectedTab={payLaterOpenTab}
                                price={product.payments[0].amount.toFixed(2)}
                                dateOptions={payments}
                            />
                        </div>
                    })}
            </div>
        </div>
    </div>)
}

export default PayLater;