import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { useHistory } from 'react-router-dom';
import Joi from 'joi-browser';
import StateSelect from '../../components/common/StateSelect';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';
import CoronisLogo from '../../assets/images/CoronisLogo.png';

const CustomerLanding = ({ location }) => {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const partner = params.partner;
        // (partner !== "coronis" || partner !== "coronis_b") && history.push('/not-found');
    // Property Owner Details
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");

    // Property Address
    // const [propertyAddress, setPropertyAddress] = useState("");
    const [unitNumber, setUnitNumber] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [streetName, setStreetName] = useState("");
    const [suburb, setSuburb] = useState("");
    const [state, setState] = useState("QLD");
    const [postCode, setPostCode] = useState("");

    // Application Details
    const [requestedAmount, setRequestedAmount] = useState("");
    const [showRequestedAmount2, setShowRequestedAmount2] = useState(false);
    const [requestedAmount2, setRequestedAmount2] = useState("");
    const [showRequestedAmount3, setShowRequestedAmount3] = useState(false);
    const [requestedAmount3, setRequestedAmount3] = useState("");
    // const [partner, setPartner] = useState("");
    const [startDate, setStartDate] = useState(new Date());

    // Validation Errors
    const [errors, setErrors] = useState({});

    const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

    // Validation Schema
    const schema = {
        firstName: Joi.string().min(3).max(20).label("First Name").required(),
        lastName: Joi.string().min(2).max(20).label("Last Name").required(),
        email: Joi.string().email().min(3).max(50).label("Email").required(),
        mobile: Joi.number().label("Mobile").required(),
        // propertyAddress: Joi.string().min(3).max(100).label("Property Address").required(),
        unitNumber: Joi.string().allow("").label("Unit Number"),
        streetNumber: Joi.string().label("Street Number").required(),
        streetName: Joi.string().label("Street Name").required(),
        suburb: Joi.string().min(3).max(20).label("Suburb").required(),
        state: Joi.string().min(1).max(10).label("State").required(),
        postCode: Joi.number().label("Postcode").required(),
        requestedAmount: Joi.number().min(1).label("Requested Amount").required(),
        requestedAmount2: Joi.number().min(1).allow("").label("Requested Amount b"),
        requestedAmount3: Joi.number().min(1).allow("").label("Requested Amount c"),
        startDate: Joi.date().required()
    }

    const validate = () => {
        const validateDetails = {
            firstName, lastName, email, mobile, unitNumber, streetNumber, streetName, suburb, state, postCode, requestedAmount, requestedAmount2, requestedAmount3, startDate
        }
        const options = { abortEarly: false };
        const { error } = Joi.validate(validateDetails, schema, options);
        if (!error) return null;

        const errors = {};
        error.details.map(error => errors[error.path[0]] = error.message);

        return errors;
    }

    const validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schemaForProperty = { [name]: schema[name] };
        const { error } = Joi.validate(obj, schemaForProperty);
        return error ? error.details[0].message : null;
    }

    const handleChange = ({ currentTarget: input }) => {
        const errors = {};
        const errorMessage = validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        setErrors(errors);
    }

    const referrer = location.search !== "" && location.search.split('?ref=');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validating upon submit
        const errors = validate();
        setErrors(errors || {});
        if (errors) return;

        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_create?partner_code=coronis`, {
            firstname: firstName,
            lastname: lastName,
            email: email,
            phone: mobile,
            address: {
                unitNumber: unitNumber,
                streetNumber: streetNumber,
                streetName: streetName,
                suburb: suburb,
                postcode: postCode,
                state: state
            },
            form: {
                code: 'cor107nl'
                // code: 'cor6090nl'
            },
            referrer: referrer.length > 1 ? referrer[1] : undefined,
            requestedamount: requestedAmount,
            requestedamount_2: requestedAmount2 === "" ? null : requestedAmount2,
            requestedamount_3: requestedAmount3 === "" ? null : requestedAmount3,
            // startdate: `${startDate.toISOString().split('T')[0]}`
            startdate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2')
        })
            .then(function (response) {
                console.log('hello');
                const { qrcode, url } = response.data;
                window.location.href = url;
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    return (<div className="mb-10">
        <div className="flex justify-between flex-wrap items-center bg-purple-dark">
            <div className="p-10 flex flex-wrap lg:flex-start items-baseline bg-purple-dark">
                <img className="flex md:pl-0 lg:pl-40" src={CoronisLogo} alt="Coronis" />
                <p className="flex text-green-light text-2xl font-gilroy-bold md:pl-0 lg:pl-16">Payment options</p>
            </div>
            <img className="pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
        </div>
        <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center w-4/5 lg:w-1/5 xl:w-3/5 m-auto">
                <h3 className="mt-20 self-center font-gilroy-bold leading-14 text-5xl text-left text-purple-light">Let's start with some details</h3>

                {/* Personal details */}
                <div className="mt-12 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-bold text-xl text-purple-light text-left">Personal details</h3>
                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['firstName'] && 'text-red-dark'}`}>First name</p>
                        <input type="text" name="firstName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['firstName'] && 'border-red-dark'}`} value={firstName} onChange={(e) => { setFirstName(e.target.value); handleChange(e) }} />
                        {errors['firstName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['firstName']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['lastName'] && 'text-red-dark'}`}>Last name</p>
                        <input type="text" name="lastName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['lastName'] && 'border-red-dark'}`} value={lastName} onChange={(e) => { setLastName(e.target.value); handleChange(e) }} />
                        {errors['lastName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['lastName']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['email'] && 'text-red-dark'}`}>Email</p>
                        <input type="text" name="email" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['email'] && 'border-red-dark'}`} value={email} onChange={(e) => { setEmail(e.target.value); handleChange(e) }} />
                        {errors['email'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['email']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['mobile'] && 'text-red-dark'}`}>Mobile</p>
                        <input type="text" name="mobile" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['mobile'] && 'border-red-dark'}`} value={mobile} onChange={(e) => { setMobile(e.target.value); handleChange(e) }} />
                        {errors['mobile'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['mobile']}</p>}
                    </div>
                </div>

                {/* Property address */}
                <div className="mt-12 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-bold text-xl text-purple-light text-left">Funding details</h3>
                        {/* <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['propertyAddress'] && 'text-red-dark'}`}>Property address where the funds will be invested</p>
                        <input type="text" name="propertyAddress" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['propertyAddress'] && 'border-red-dark'}`} value={propertyAddress} onChange={(e) => { setPropertyAddress(e.target.value); handleChange(e) }} />
                        {errors['propertyAddress'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['propertyAddress']}</p>} */}

                        <div className="flex">
                            <div className="w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['unitNumber'] && 'text-red-dark'}`}>Unit Number</p>
                                <input type="text" name="unitNumber" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['unitNumber'] && 'border-red-dark'}`} value={unitNumber} onChange={(e) => { setUnitNumber(e.target.value); handleChange(e) }} />
                                {errors['unitNumber'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['unitNumber']}</p>}
                            </div>
                            <div className="ml-2 w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['streetNumber'] && 'text-red-dark'}`}>Street Number</p>
                                <input type="text" name="streetNumber" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['streetNumber'] && 'border-red-dark'}`} value={streetNumber} onChange={(e) => { setStreetNumber(e.target.value); handleChange(e) }} />
                                {errors['streetNumber'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['streetNumber']}</p>}
                            </div>
                        </div>

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['streetName'] && 'text-red-dark'}`}>Street Name</p>
                        <input type="text" name="streetName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['streetName'] && 'border-red-dark'}`} value={streetName} onChange={(e) => { setStreetName(e.target.value); handleChange(e) }} />
                        {errors['streetName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['streetName']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['suburb'] && 'text-red-dark'}`}>Suburb</p>
                        <input type="text" name="suburb" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['suburb'] && 'border-red-dark'}`} value={suburb} onChange={(e) => { setSuburb(e.target.value); handleChange(e) }} />
                        {errors['suburb'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['suburb']}</p>}

                        <div className="flex">
                            <div className="w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['postCode'] && 'text-red-dark'}`}>Postcode</p>
                                <input type="text" name="postCode" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['postCode'] && 'border-red-dark'}`} value={postCode} onChange={(e) => { setPostCode(e.target.value); handleChange(e) }} />
                                {errors['postCode'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['postCode']}</p>}
                            </div>
                            <div className="ml-2 w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['state'] && 'text-red-dark'}`}>State</p>
                                <StateSelect className="border-2 rounded-xl font-gilroy-semibold p-4" options={states} onOptionChange={(e) => setState(e.target.value)} selectedOption={state} />
                            </div>
                        </div>
                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['requestedAmount'] ? 'text-red-dark' : ''}`}>Requested amount</p>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">
                                    $
                                </span>
                            </div>
                            <input type="text" name="requestedAmount" className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['requestedAmount'] ? 'border-red-dark' : ''}`} placeholder="0.00" value={`${requestedAmount}`} onChange={(e) => { setRequestedAmount(e.target.value); handleChange(e) }} />
                        </div>
                        {errors['requestedAmount'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['requestedAmount']}</p>}

                        {showRequestedAmount2 && <><p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['requestedAmount2'] ? 'text-red-dark' : ''}`}>Requested amount b</p>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">
                                        $
                                    </span>
                                </div>
                                <input type="text" name="requestedAmount2" className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['requestedAmount2'] ? 'border-red-dark' : ''}`} placeholder="0.00" value={`${requestedAmount2}`} onChange={(e) => { setRequestedAmount2(e.target.value); handleChange(e) }} />
                            </div></>}
                        {showRequestedAmount2 && errors['requestedAmount2'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['requestedAmount2']}</p>}

                        {showRequestedAmount3 && <><p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['requestedAmount3'] ? 'text-red-dark' : ''}`}>Requested amount c</p>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">
                                        $
                                    </span>
                                </div>
                                <input type="text" name="requestedAmount3" className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['requestedAmount3'] ? 'border-red-dark' : ''}`} placeholder="0.00" value={`${requestedAmount3}`} onChange={(e) => { setRequestedAmount3(e.target.value); handleChange(e) }} />
                            </div></>}
                        {showRequestedAmount3 && errors['requestedAmount3'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['requestedAmount3']}</p>}

                        {!showRequestedAmount3 && <div className="mt-5 flex self-center w-80">
                            <button type="button" onClick={() => { !showRequestedAmount2 && setShowRequestedAmount2(true) || showRequestedAmount2 && !showRequestedAmount3 && setShowRequestedAmount3(true) }} className="p-2 mt-2 py-3 px-4 text-purple-light rounded-3xl font-gilroy-bold border border-purple-light">
                                <motion.button type="button" whileHover={{ scale: 1.1 }}>
                                    Add amount
                                </motion.button>
                            </button>
                        </div>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['startDate'] && 'text-red-dark'}`}>Start date</p>
                        <div className="w-full flex justify-start items-center">
                            <span className="w-7 pl-3 absolute" style={{ color: "#4139ff" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" style={{ marginLeft: "-0.2rem" }} viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </span>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="LL"
                                minDate={new Date()}
                                onDayChange={(date) => setStartDate(date)}
                                value={startDate}
                                dayPickerProps={{
                                    modifiers: {
                                        disabled: [
                                            {
                                                before: new Date()
                                            }
                                        ]
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-10 flex self-center w-80">
                    <button type="submit" className="bg-purple-light p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold">
                        <motion.button whileHover={{ scale: 1.1 }}>
                            View options
                        </motion.button>
                    </button>
                </div>
            </div>
        </form>
    </div>)
}

export default CustomerLanding;