import React from "react";

const StateSelect = ({ options, onOptionChange, selectedOption }) => {
  return (
    <select
      // className="bg-transparent appearance-none text-center border-2 border-t-0 border-l-0 border-r-0 border-green-light"
      className="p-4 border-2 rounded-xl w-full font-gilroy-semibold p-4 mt-1"
      onChange={onOptionChange}
      value={selectedOption}
      style={{ padding: "0 1rem", height: "3rem" }} //remove later
    >
      {/*the below option is used as a placeholder and cannot be selected by the user*/}
      <option value="" disabled selected>
        Select
      </option>

      {options.map((option, index) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default StateSelect;
