import React from "react";
import OptionCard from "../common/OptionCard";

const MultipleOptions = () => {
  const options = [
    {
      dayPlan: 60,
      noPayFor: 15,
      easyPayments: 4,
      price: Number(1200).toFixed(2),
      dateOptions: [
        "15 October, 2021",
        "30 October, 2021",
        "15 November, 2021",
        "30 November, 2021",
      ],
    },
    {
      dayPlan: 90,
      noPayFor: 45,
      easyPayments: 4,
      price: Number(1200).toFixed(2),
      dateOptions: [
        "15 October, 2021",
        "30 October, 2021",
        "15 November, 2021",
        "30 November, 2021",
      ],
    },
    {
      dayPlan: 120,
      noPayFor: 75,
      easyPayments: 4,
      price: Number(1200).toFixed(2),
      dateOptions: [
        "15 October, 2021",
        "30 October, 2021",
        "15 November, 2021",
        "30 November, 2021",
      ],
    },
  ];

  return (
    <div className="flex flex-wrap justify-center">
      {options.map((option) => (
        <div className="pl-4 mt-4" key={option.dayPlan}>
          <OptionCard
            dayPlan={option.dayPlan}
            noPayFor={option.noPayFor}
            easyPayments={option.easyPayments}
            price={option.price}
            dateOptions={option.dateOptions}
          />
        </div>
      ))}
    </div>
  );
};

export default MultipleOptions;