import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';

const ChooseProduct = (params) => {
    const [showProducts, setShowProducts] = useState(false);

    const history = useHistory();

    // Dummy data
    const getData = () => {
        return {
            forms: ['107', '60/90/120', '365'] // Product Names
            // url: '' 
        }
    };

    let products = [{
        id: '107',
        name: '107',
        noPayFor: 15,
    },
    {
        id: '60-90-120',
        name: '60/90/120',
        noPayFor: 45,
    }
    ,{
        id: '365',
        name: '365',
        noPayFor: 75,
    }
];

const handleProduct = (formId) => {
    // history.push(`/partner/leads/${formId}`)
    // window.location.href = `https://app.elepay.com.au/ui/partner/leads/${formId}`;
    window.location.href = `/ui/partner/leads/${formId}`;
}



    // Render once when the component loads
    useEffect(() => {
        // Get the data from the endpoint
        const data = getData();
        const forms = data.forms;
        forms.length > 1 && setShowProducts(true);
        forms.length === 1 && (forms[0] === '107' ? history.push('/partner/leads/107') : forms[0] === '60/90/120' ? history.push('/partner/leads/60-90-120') : history.push('/partner/leads/365'));
    }, []);

    return (
        <div>
            {showProducts && 
            <div className="flex flex-col justify-center">
                <h3 className="mt-20 p-4 items-center font-gilroy-bold leading-14 text-5xl text-center text-purple-light">Choose a product</h3>
                <div className="m-8">
                    {products.map(product => <button className="w-max p-8 m-4 mb-2 border border-purple-extra-light hover:bg-purple-extra-light focus:bg-purple-light text-purple-light focus:text-white rounded-2xl" onClick={() => handleProduct(product.id)}>
                        <small className="text-md font-gilroy-regular">days</small>
                        <h3 className="text-4xl font-gilroy-semibold">{product.name}</h3>
                        {/* <h3>No pay for {product.noPayFor}</h3> */}
                    </button>)}
                </div>
            </div>}
        </div>
    );
    
}

export default ChooseProduct;