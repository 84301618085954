import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { motion } from 'framer-motion';
import { fullMonthNames } from '../constants/dateConstants';
import Header from './components/Header';

const Pay180 = ({location, match}) => {
    const { leadId } = match.params;
    const [details, setDetails] = useState({
        requestedAmount: "",
        firstPaymentDate: "",
        equalPaymentDates: "",
        elepaySupplier: "",
        customer: "",
        contactDetails: "",
        startDate: "",
        propertyAddress: ""
    });

    const formatDate = (date) => {
        const startDate = new Date(date);
        return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
    }

    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}`)
            .then(function (response) {
                console.log("response", response.data);
                const leadResponseData = response.data;
                let startDate = new Date();
                let formattedStartDate = "";
                startDate = new Date(leadResponseData.startdate);
                formattedStartDate = formatDate(startDate);

                let firstPaymentDate = new Date();
                let formattedFirstPaymentDate = "";
                    firstPaymentDate = new Date(leadResponseData.startdate);
                    firstPaymentDate.setDate(firstPaymentDate.getDate() + 120);
                    formattedFirstPaymentDate = formatDate(firstPaymentDate);

                    const detailsObj = {
                        requestedAmount: formatCurrency(leadResponseData.requestedamount),
                        firstPaymentDate: formattedFirstPaymentDate,
                        equalPaymentDates: formatCurrency(leadResponseData.requestedamount / 5),
                        elepaySupplier: leadResponseData.partner,
                        customer: `${leadResponseData.firstname} ${leadResponseData.lastname}`,
                        contactDetails: leadResponseData.phone,
                        startDate: formattedStartDate,
                        propertyAddress: leadResponseData.address.fullAddress,
                    }
                    setDetails(detailsObj);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
    }, []);

    const dayPlan = 180;

    const handleLead = (leadId) => {
        window.location.href = `${process.env.REACT_APP_ENV_PREFIX}/lead/apply/${leadId}?product=elepay${dayPlan}`;
    }
    

    return <div>
    <Header />
        <div>
            <h3 className="font-gilroy-semibold text-5xl p-8 mt-8 text-purple-light">Let's manage your property expenses the easy way</h3>
            <div className="w-max flex flex-col lg:flex-row justify-center p-12 lg:p-4 mt-8 m-auto bg-purple-extra-light rounded-3xl">
                <div className="p-4 pl-8">
                    <p className="text-lg text-black-dark font-gilroy-bold">Requested amount</p>
                    <h3 className="pt-3 text-xl text-purple-light font-gilroy-bold">{details.requestedAmount}</h3>
                </div>
                <div className="p-4 pl-8">
                    <p className="text-lg text-black-dark font-gilroy-bold">First payment date</p>
                    <h3 className="pt-3 text-xl text-purple-light font-gilroy-bold">{details.firstPaymentDate}</h3>
                </div>
                <div className="p-4 pl-8">
                    <p className="text-lg text-black-dark font-gilroy-bold">5 x equal payments</p>
                    <h3 className="pt-3 text-xl text-purple-light font-gilroy-bold">{details.equalPaymentDates}</h3>
                    <p className="text-xs text-purple-light font-gilroy-regular">Every 15 days</p>
                </div>
            </div>
            <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Contact details</h3>

                        <p className="mt-4 font-gilroy-regular text-md text-black-dark">Elepay Supplier</p>
                        <input type="text" name="elepaySupplier" className="p-4 w-full h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.elepaySupplier} disabled />
                        
                        <p className="mt-4 font-gilroy-regular text-md text-black-dark">Customer</p>
                        <input type="text" name="customer" className="p-4 w-full h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.customer} disabled />

                        <p className="mt-4 font-gilroy-regular text-md text-black-dark">Contact details</p>
                        <input type="text" name="contactDetails" className="p-4 w-full h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.contactDetails} disabled />
                        
                        <p className="mt-4 font-gilroy-regular text-md text-black-dark">Start date</p>
                        <input type="text" name="startDate" className="p-4 w-full h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.startDate} disabled />
                        
                        <p className="mt-4 font-gilroy-regular text-md text-black-dark">Property address</p>
                        <textarea type="textarea" rows="2" name="propertyAddress" className="p-4 w-full min-h-20 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.propertyAddress} disabled />
                    </div>
                    {/* Script is here */}
                    {/* <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8513039.js"></script> */}
                <button className="bg-purple-light p-2 mt-16 py-3 px-8 mb-8 text-white rounded-3xl font-gilroy-bold" onClick={() => handleLead(leadId)}>
                    <motion.button whileHover={{ scale: 1.1 }}>
                        Confirm and continue
                    </motion.button>
                </button>
            </div>
        </div>
    </div>
}

export default Pay180;