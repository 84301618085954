import React from 'react';
import Header from '../components/Header';

const Custom404 = () => {
  return (
      <>
          <Header />
          <div className="mt-10 flex flex-col items-center justify-center">
              <h3 className="font-gilroy-semibold text-4xl text-purple-light xl:text-8xl">404 Not Found!</h3>
              <p className="font-gilroy-semibold text-3xl text-red xl:text-4xl">Sorry, Partner Code is invalid</p>
          </div>
      </>
  )
};

export default Custom404;