import React from "react";

export default function PaymentModal({ showPaymentModal, onCloseModal, url }) {
    return (
        <>
            {showPaymentModal ? (
                <>
                    <div
                        className="justify-center items-center flex h-full overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <iframe id="paymentForm" allowpaymentrequest="true" att src={url} scrolling="no" style={{ width: "50rem", height: "40rem", border: "2px solid #e7e6fa", borderRadius: "2rem", backgroundColor: "white" }} class="custom-bizcore-iframe-height"></iframe>
                    </div>
                </>
            ) : null}
        </>
    );
}