import React from 'react';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';

const PaymentThankYou = ({ location }) => {
    const { firstName, transactionReference, transactionDateFormatted, transactionSourceType, totalAmount } = location.state || {};

    return (<><div className="flex justify-center flex-wrap items-center p-8 bg-purple-dark">
        {/* <div className="p-10 flex flex-wrap lg:flex-start items-baseline bg-purple-dark">
            <img className="flex md:pl-0 lg:pl-40" src={CoronisLogo} alt="Coronis" />
            <p className="flex text-green-light text-2xl font-gilroy-bold md:pl-0 lg:pl-16">Payment options</p>
        </div> */}
        <img className="pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
    </div>
        <div className="flex flex-col justify-center items-center w-4/5 lg:w-1/5 xl:w-3/5 m-auto">
            <h3 className="mt-20 self-center font-gilroy-bold leading-14 text-6xl text-center text-purple-light">Thank you {firstName}.</h3>
            <h3 className="font-gilroy-semibold text-purple-light text-2xl">Your payment has been successful!</h3>

            <table className="mt-4">
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Transaction Reference: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{transactionReference} </p>}</td>
                </tr>
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Transaction Date: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{transactionDateFormatted} </p>}</td>
                </tr>
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Total Amount: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{totalAmount} </p>}</td>
                </tr>
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Payment Type: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{transactionSourceType} </p>}</td>
                </tr>

            </table>
        </div>
    </>)
}

export default PaymentThankYou;