import React, { useState } from 'react';
import axios from 'axios';
import Joi from 'joi-browser';
import { motion } from 'framer-motion';
import ElepayLogo from '../../assets/images/ElepayLogo.png';

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");

    // Validation Errors
    const [errors, setErrors] = useState({});

    // Validation Schema
    const schema = {
        email: Joi.string().email().min(3).max(50).label("Email").required(),
    }

    const validate = () => {
        const validateDetails = {
            email
        }
        const options = { abortEarly: false };
        const { error } = Joi.validate(validateDetails, schema, options);
        if (!error) return null;

        const errors = {};
        error.details.map(error => errors[error.path[0]] = error.message);

        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validating upon submit
        const errors = validate();
        setErrors(errors || {});
        if (errors) return;

        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/re_forgotten`, {
            email: email,
        })
            .then(function (response) {
                // “authenticated”: true/false, “firstname”: “firstname”, “lastname”: “lastname”
                const { result, message } = response.data;
                if (result === "success") {
                    setMessage(`New password has been sent to ${email}`);
                    setShowMessage(true);
                }
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    return (
        <>
            <div className="flex justify-between flex-wrap items-center bg-purple-dark">
                <div className="p-10 flex flex-wrap lg:flex-start items-end bg-purple-dark">
                    <img className="pr-10" src={ElepayLogo} alt="Elepay Logo" />
                    <p className="flex text-green-light text-2xl font-gilroy-bold md:pl-0 lg:pl-4">CRM</p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        {!showMessage && <>
                            <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Please enter your email</h3>
                            <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['email'] && 'text-red-dark'}`}>Email</p>
                            <input type="email" name="email" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['email'] && 'border-red-dark'}`} value={email} onChange={(e) => { setEmail(e.target.value); }} />
                            {errors['email'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['email']}</p>}
                        </>
                        }
                        {showMessage && <h3 className="font-gilroy-semibold text-2xl text-purple-light text-left">{message}</h3>}

                    </div>
                    {!showMessage && <div className="mt-4 flex self-center w-80">
                        <button type="submit" className="bg-purple-light p-2 mt-2 py-3 px-5 text-white rounded-3xl font-gilroy-bold">
                            <motion.button whileHover={{ scale: 1.1 }}>
                                Reset Password
                            </motion.button>
                        </button>
                    </div>}
                </div>
            </form>
        </>
    )
}

export default ResetPassword;