import React, { useState, useEffect } from "react";
import axios from "axios";
import ElepayLogo from '../assets/images/Elepay_enjoy_more_time.png';

const Pay_107_Calc_Header = ({ partner_code, headerTitle }) => {
    const [partnerCode, setPartnerCode] = useState("");
    const [partnerName, setPartnerName] = useState("");

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_qry/${partner_code}?type=partner`)
          .then(function (response) {
              const { name, id, type } = response.data;
              setPartnerName(name);
              setPartnerCode(id);
            })
          .catch(function (error) {
              console.log('error', error);
            //   history.push('/404');
          });
    }, []);

    return (<>
        <div className="tablet:hidden flex flex-col p-10 tablet:flex-row items-center justify-between flex-wrap items-center bg-purple-dark">
            {/* <img className="pl-5 pr-10 w-48" src={getPartnerLogoByCode(partner_code)} alt={getPartnerNameByCode(partner_code)} /> */}
            <h3 className="text-white flex md:pl-0 font-gilroy-semibold text-2xl">{partnerName}</h3>
            {/* <div className="px-6 py-1 m-auto mt-6 flex flex-col tablet:flex-row flex-wrap tablet:flex-start items-baseline bg-purple-dark border-2 border-green-light rounded-2xl">
                <p className="flex justify-center text-white text-2xl font-gilroy-bold">{(headerTitle.includes('paylater_paynow') || headerTitle.includes('paynow')) ? 'Payment Hub' : 'Pay Later Hub'}</p>
            </div> */}
            <img className="pl-5 pr-10 pt-4 w-48" src={ElepayLogo} alt="Elepay Logo" />
        </div>
        <div className="hidden px-10 py-10 flex tablet:flex flex-row flex-wrap items-center tablet:justify-between bg-purple-dark">
            {/* <img className="w-40" src={getPartnerLogoByCode(partner_code)} alt={getPartnerNameByCode(partner_code)} /> */}
            <h3 className="text-white flex md:pl-0 font-gilroy-semibold text-2xl">{partnerName}</h3>
            {/* <div className="px-6 py-1 m-auto flex flex-col tablet:flex-row flex-wrap tablet:flex-start items-baseline bg-purple-dark border-2 border-green-light rounded-2xl">
                <p className="flex justify-center text-white text-2xl font-gilroy-bold">{(headerTitle.includes('paylater_paynow') || headerTitle.includes('paynow')) ? 'Payment Hub' : 'Pay Later Hub'}</p>
            </div> */}
            <img className="w-40" src={ElepayLogo} alt="Elepay Logo" />
        </div>
        </>
    );
};

export default Pay_107_Calc_Header;