import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga4';
import GA4React, { useGA4React } from "ga-4-react";
import ReactAdroll from 'react-adroll';

const tracking = "";
const ga4react = new GA4React('G-K8TRV2B0TD');
var ga4obj;

export const initTrack = (component, partner_code, form_code, path) => {

    ga4react.initialize().then((ga4) => {
        ga4.pageview('path1');
        ga4obj=ga4;        
    },(err) => {
        console.error(err)
    })

    // ReactGA.initialize('G-K8TRV2B0TD');
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "React Payment Hub" });
    // ReactGA.event({
    //     category: 'Partner',
    //     partnerCode: partner_code,
    //     campaignCode: (form_code || path)
    // });

    ReactPixel.init('584658706961837');
    ReactPixel.pageView();
    ReactPixel.trackCustom('Partner', {partnerCode:partner_code, campaignCode: (form_code || path)}); //, productCode:, campaignCode: cat + formcode});

    ReactAdroll.init('BEMNFI7CRFGVHIYQT4SDDA', 'NOAA6H3O65HDFOJEXRZU5X', true);

    return "initTrack: " + component + " " + partner_code + " " + (form_code || path) + " " + new Date();
};

export const convert = (event, partner_code, form_code, path, params) => {

    const urlSearchParams = new URLSearchParams(params);
    if (urlSearchParams.has("src")) {
        const src = urlSearchParams.get('src');       

        if (src === 'ga') {
            ga4obj.event('PartnerRetargetingPropertyOwner'); //, {'event_category': '' });
        } else if (src === 'fb') {
            ReactPixel.trackCustom(event);           
        } else if (src === 'adroll') {
            ReactAdroll.trackCustom('Partner', {'partnerCode': partner_code, 'campaignCode': (form_code || path)});
        }

        return "convert " + src + ": " + event + " " + new Date();    
    }
     
    // ga4obj.event('conversion',{'send_to': 'AW-11121061574/aCxoCLfZ_pQYEMbd97Yp'});        
    // ReactGA.event('conversion',{'send_to': 'AW-11121061574/aCxoCLfZ_pQYEMbd97Yp'} );    
    
    
};

export default tracking;