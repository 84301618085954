import { motion } from "framer-motion";

import days60 from "../../assets/images/60_days.svg";
import days90 from "../../assets/images/90_days.svg";
import days120 from "../../assets/images/120_days.svg";
import masterCard from "../../assets/images/mastercard.svg";
import visaCard from "../../assets/images/visa.svg";
import applePayCard from "../../assets/images/apple-pay.svg";
import gPayCard from "../../assets/images/g-pay.svg";

export default ({
  selectedPlan,
  dateFormat,
  formatCurrency,
  closePopup,
  payNowLaterSelected,
  requestedAmount,
  payNowOpenTab,
  startDate,
  scrollDown,
}) => {
  let image = selectedPlan[0]?.product?.numberOfDays;

  return (
    <div className="calc-popup">
      <div className="popup-container">
        {payNowLaterSelected == "Now" ? (
          <>
            <h2 className="font-gilroy-bold text-3xl text-purple-light text-center pb-0">
              You have selected to Pay Now
            </h2>

            <h3 className="mt-4 font-gilroy-bold text-xl text-purple-dark text-center">
              Via {payNowOpenTab}
            </h3>

            {payNowOpenTab == "Credit Card" ? (
              <>
                <p className="mt-4 font-gilroy-bold text-md text-black-dark">
                  See below the different payment options available to you.
                </p>

                <div className="flex flex-col items-center mt-8">
                  <span className="flex">
                    <img
                      className="flex pl-2 pb-2 w-20"
                      src={masterCard}
                      alt="60 Days"
                    />
                    <img
                      className="flex pl-2 pb-2 w-20"
                      src={visaCard}
                      alt="60 Days"
                    />
                  </span>
                  <span className="flex">
                    <img
                      className="flex pl-2 w-20"
                      src={applePayCard}
                      alt="60 Days"
                    />
                    <img
                      className="flex pl-2 w-20"
                      src={gPayCard}
                      alt="60 Days"
                    />
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="flex flex-col items-center mt-1">
              <h1 className="mt-1  font-gilroy-bold text-4xl text-purple-light text-center amount">
                {formatCurrency(requestedAmount)}
              </h1>

              <p
                className={`font-gilroy-regular text-sm text-black-dark`}
                style={{ fontStyle: "italic" }}
              >
                {payNowOpenTab == "Credit Card"
                  ? "1.25% Fee applies"
                  : "$2.95 per payment"}
              </p>

              <p className={`mt-4 font-gilroy-bold text-md text-black-dark`}>
                Start Date: {dateFormat(startDate)}
              </p>

              <div className="flex justify-center mt-8 mb-4 w-100">
                <button
                  type="button"
                  className="p-1 mt-2 py-2 px-4 text-purple-light rounded-3xl font-gilroy-semibold border border-purple-light"
                  onClick={(_) => closePopup("back")}
                >
                  BACK
                </button>

                <button
                  type="button"
                  className="p-1 mt-2 py-2 px-4 text-white rounded-3xl font-gilroy-semibold border border-purple-light bg-purple-light"
                  onClick={(_) => {
                    closePopup("continue");
                    scrollDown();
                  }}
                >
                  CONTINUE
                </button>
              </div>

              <p className={`mt-4 font-gilroy-bold text-md text-black-dark`}>
                You can always change this before you finalise this process in
                Step 3.
              </p>
            </div>
          </>
        ) : (
          <>
            <h2 className="font-gilroy-bold text-3xl text-purple-light text-center pb-0">
              Congratulations!
            </h2>

            <h3 className="mt-2 font-gilroy-bold text-xl text-purple-dark text-center">
              You can now Enjoy More Time
            </h3>

            <div className="image-container">
              <img
                src={image == "60" ? days60 : image == "120" ? days120 : days90}
                alt="Option Icon"
              />
            </div>

            <h3 className="mt-1 font-gilroy-bold text-xl text-purple-dark text-center">
              No Payment for {selectedPlan[0]?.product?.firstPayment} days,
            </h3>

            <p className={`mt-1 font-gilroy-bold text-md text-black-dark`}>
              followed by{" "}
              {selectedPlan[0]?.payments?.length > 1
                ? "4 x equal payments due on"
                : "1 x payment due on"}
              :
            </p>

            <p className="font-gilroy-bold text-sm text-black-dark text-center">
              {selectedPlan[0]?.payments?.length > 1
                ? selectedPlan[0]?.payments?.map((item, i) => {
                    return (
                      <>
                        {dateFormat(item?.date)}
                        <span className="dash-line">|</span>
                      </>
                    );
                  })
                : dateFormat(selectedPlan[0]?.payments[0]?.date)}
            </p>

            <h1 className="mt-5  mb-5 font-gilroy-bold text-4xl text-purple-light text-center amount">
              {selectedPlan[0]?.payments.length > 1
                ? formatCurrency(selectedPlan[0]?.payments[0]?.amount)
                : formatCurrency(selectedPlan[0]?.product?.totalrepayments)}
            </h1>

            <div className="flex justify-center">
              <button
                type="button"
                className="p-1 mt-2 py-2 px-4 text-purple-light rounded-3xl font-gilroy-bold border border-purple-light"
                onClick={(_) => closePopup("back")}
              >
                BACK
              </button>

              <button
                type="button"
                className="p-1 mt-2 py-2 px-4 text-white rounded-3xl font-gilroy-bold border border-purple-light bg-purple-light"
                onClick={(_) => {
                  closePopup("continue");
                  scrollDown();
                }}
              >
                CONTINUE
              </button>
            </div>

            <p className={`mt-7 font-gilroy-bold text-md text-black-dark`}>
              Changes to Payment Options can still be made up until you have
              clicked on your final Start Now button.
            </p>
          </>
        )}
      </div>
    </div>
  );
};
