import React from "react";
import FooterImage from "../assets/images/Footer.png";

const Footer = () => {
  return (
    <div className="relative bottom-0 w-full p-10 bg-purple-dark">
      <div className="flex justify-center items-center">
        <div className="-mt-20">
          <img src={FooterImage} alt="Elepay Footer" />
        </div>
        <div className="text-left">
          <p className="text-white font-gilroy-regular">
            <span className="font-light">Call us on</span> <b>1300 019 417</b>
          </p>
          <p className="text-white font-gilroy-regular">
            <span className="font-light">Or start live chat live at</span>{" "}
            <b>elepay.com.au</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
