import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import Tabs from "../calculator_components/Tabs";
import { motion } from "framer-motion";

import DayPickerInput from "react-day-picker/DayPickerInput";
import PopUpCalc from "../calculator_components/PopUpCalc";
import PopUpCalcContinue from "../calculator_components/PopUpContinue";

import { formatDate, parseDate } from "react-day-picker/moment";
import { useHistory } from "react-router-dom";

import Joi from "joi-browser";

import StateSelect from "../../components/common/StateSelect";

import ElepayLogo from "../../assets/images/ElepayLogo.png";
import days60 from "../../assets/images/60_days.svg";
import days90 from "../../assets/images/90_days.svg";
import days120 from "../../assets/images/120_days.svg";
import masterCard from "../../assets/images/mastercard.svg";
import visaCard from "../../assets/images/visa.svg";
import applePayCard from "../../assets/images/apple-pay.svg";
import gPayCard from "../../assets/images/g-pay.svg";
import ElepayPrimaryLogo from "../../assets/images/Elepay_Primary.svg";
import GirlCouch from "../../assets/images/CalcMobImage2.png";
import DirectDebit from "../../assets/images/direct-debit-1.svg";

import "../style.css";
import { fullMonthNames } from "../../constants/dateConstants";
import PaymentModal from "./PaymentModal";
import Modal from "./Modal";
import { getPartnerNameByCode } from "../../helpers/getPartnerInfo";
import { initTrack } from "../../helpers/tracking";

const PayNowPayLater = ({ location, match }) => {
  const [step, setStep] = useState(1);
  const path = match.url.split('/');
  const [selectedPlan, setSelectedPlan] = useState("90");
  const [selectedPlanData, setSelectedPlanData] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUpContinue, setShowPopUpContinue] = useState(false);
  const [payNowLaterSelected, setPayNowLaterSelected] = useState("");
  const referrer = location.search !== "" && location.search.split('?ref=');
  
  const history = useHistory();

  const payLaterTabOptions = [
    { index: 0, option: "1 x Payment", optionSelected: "a" },
    { index: 1, option: "4 x Payments", optionSelected: "b" },
  ];
  const [payLaterOpenTab, setPayLaterOpenTab] = useState({
    index: 0,
    option: payLaterTabOptions[0].option,
    optionSelected: "a",
  });

  const payNowTabOptions = [
    { index: 0, option: "Credit Card", optionSelected: "a" },
    { index: 1, option: "Direct Debit", optionSelected: "b" },
  ];
  const [payNowOpenTab, setNowOpenTab] = useState({
    index: 0,
    option: payNowTabOptions[0].option,
    optionSelected: "a",
  });

  const [options, setOptions] = useState([]);

  const { partner_code, form_code } = match.params;
  const defaultFormCode = form_code == null ? match.params[0] : '';
  const [partnername, setPartnername] = useState("");

  // Property Owner Details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  
  // Property Address
  const [unitNumber, setUnitNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [state, setState] = useState("");
  const [postCode, setPostCode] = useState("");

  // Application Details
  const [requestedAmount, setRequestedAmount] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  //Payment details
  const [transactionAgreementId, setTransactionAgreementId] = useState("");
  const [transactionReference, setTransactionReference] = useState("");
  const [sourceCreationUrl, setSourceCreationUrl] = useState("");

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState({});

  // Validation Errors
  const [errors, setErrors] = useState({});
  const [urlError, setUrlError] = useState(false);

  const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

  // Validation Schema
  const schema = {
    firstName: Joi.string().min(3).max(20).label("First Name").required(),
    lastName: Joi.string().min(2).max(20).label("Last Name").required(),
    email: Joi.string().email().min(3).max(50).label("Email").required(),
    mobile: Joi.number().label("Mobile").required(),
    unitNumber: Joi.string().allow("").label("Unit Number"),
    middleName: Joi.string().alphanum().allow("").label("Middle Name"),
    streetNumber: Joi.string().label("Street Number").required(),
    streetName: Joi.string().label("Street Name").required(),
    suburb: Joi.string().min(3).max(20).label("Suburb").required(),
    state: Joi.string().min(1).max(10).label("State").required(),
    postCode: Joi.number().label("Postcode").required(),
    requestedAmount: Joi.number()
      .min(1)      
      .label("Requested Amount")
      .required(),
    startDate: Joi.date().required(),
  };

  const validate = () => {
    const validateDetails = {
      firstName,
      lastName,
      email,
      mobile,
      unitNumber,
      streetNumber,
      streetName,
      suburb,
      state,
      postCode,
      requestedAmount,
      startDate,
    };
    const options = { abortEarly: false };
    const { error } = Joi.validate(validateDetails, schema, options);
    if (!error) return null;

    const errors = {};
    error.details.map((error) => (errors[error.path[0]] = error.message));

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaForProperty = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaForProperty);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const errors = {};
    const errorMessage = validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    setErrors(errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validating upon submit
    const errors = validate();
    setErrors(errors || {});
    if (errors) {
      return;
    } else generateLeadId();
  };

  const ValidateUrl = (_) => {     
    axios.get(`${process.env.REACT_APP_ENV_PREFIX}/partner/validatePath?partnerCode=${partner_code}&formCode=${form_code || defaultFormCode}`)
      .then(function (response) {
        // handle success
        console.log({response});
        if (response.data.partner_name != null) {
          setPartnername(response.data.partner_name); 
          setUrlError(response.data.partner_name == 'invalid' ? true : false);      
        }   
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(initTrack("PayNowPayLater", partner_code, form_code, path[3]));
    ValidateUrl();
  }, []);

  const CalcPayments = (_) => {
    setStep(2);

    axios
      .post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_calc`, {
        requestedamount: requestedAmount,
        startdate: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, "$10$2"),
        form: { code: "paylater_paynow" },
      })
      .then(function (response) {
        setOptions(response.data.options[0]);
        setPlanData(response.data.options[0]);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    setPlanData();
  }, [selectedPlan, payLaterOpenTab]);

  const setPlanData = (res) => {
    let data = !res ? options : res;
    let newData = [];

    for (let i = 0; data?.products?.length > i; i++) {
      let arr = data.products;

      if (arr[i].application_url.includes("elepay" + selectedPlan)) {
        if (
          payLaterOpenTab.optionSelected == "a" &&
          arr[i].product.numberOfPayments == 1
        ) {
          newData.push(arr[i]);
        } else if (
          payLaterOpenTab.optionSelected == "b" &&
          arr[i].product.numberOfPayments > 1
        ) {
          newData.push(arr[i]);
        }
      }
    }

    setSelectedPlanData(newData);
  };

  const formatCurrency = (elm) => {
    if (!elm) return "$0.00";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(elm);
  };

  const changeDateFormat = (date) => {
    const startDate = !date ? new Date() : new Date(date);

    return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()].slice(
      0,
      3
    )} '${startDate.getFullYear().toString().split("0")[1]}`;
  };

  const closePopup = (type) => {
    if (type == "back") {
      setStep(2);
      setPayNowLaterSelected("");
    } else if (type == "continue") {
      setStep(3);
    }

    setShowPopUp(!showPopUp);
  };

  const redirectToSourceUrl = (url) => {
    window.location.href = `${url}`;
  };

  //UNCOMMENT BELOW WHEN READY TO USE

  const generateLeadId = (_) => {
    let formCode = "paylater_paynow";

    axios
      .post(
        `${process.env.REACT_APP_ENV_PREFIX}/lead/re_create?partner_code=${partner_code}`,
        {
          firstname: firstName,
          lastname: lastName,
          email: email,
          phone: mobile,
          address: {
            unitNumber: unitNumber,
            streetNumber: streetNumber,
            streetName: streetName,
            suburb: suburb,
            postcode: postCode,
            state: state,
          },
          form: {
            code: formCode,
          },
          referrer: referrer.length > 1 ? referrer[1] : undefined,
          requestedamount: requestedAmount,
          requestedamount_2: null,
          requestedamount_3: null,
          startdate: `${startDate.getFullYear()}-${
            startDate.getMonth() + 1
          }-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, "$10$2"),
        }
      )
      .then(function (response) {
        setShowPopUpContinue(!showPopUpContinue);

        const lastSegment = response.data.url.split("/").pop();

        setLeadId(lastSegment);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const closePopupContinue = (type) => {
    setDetails({
      firstname: firstName,
      lastname: lastName,
      address: {
        fullAddress: `${streetNumber}, ${streetName}, ${suburb}, ${state}, ${postCode}`,
      },
      phone: mobile,
      email: email,
      startdate: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, "$10$2"),
    });

    if (type == "back") {
      setStep(2);
      setPayNowLaterSelected("");
    } else if (type == "continue") {
      if (payNowLaterSelected == "Now") {
        axios
          .post(
            `${process.env.REACT_APP_ENV_PREFIX}/bizcore/createAgreement`,
            {
              lead_id: leadId,
              givenName: firstName,
              familyName: lastName,
              email: email,
              mobile: mobile,
              amount: requestedAmount,
              paymentType: payNowOpenTab.index == 0 ? 2 : 0,
            }
          )
          .then(function (response) {
            const {
              transactionAgreementId,
              transactionAgreementReference,
              sourceCreationUrl,
            } = response.data;

            setTransactionAgreementId(transactionAgreementId);
            setTransactionReference(transactionAgreementReference);
            setSourceCreationUrl(sourceCreationUrl);
            payNowOpenTab.index == "0"
              ? redirectToSourceUrl(sourceCreationUrl)
              : setShowModal(true);
          })
          .catch(function (error) {
            console.log("error", error);
          });
      } else {
        // navigate to Novatti (protect-portal)
        window.location.href = `${process.env.REACT_APP_ENV_PREFIX}/lead/apply/${leadId}${selectedPlanData[0].application_url}`;
      }
    }

    setShowPopUpContinue(!showPopUpContinue);
  };

  const interval = setInterval(() => {
    if (showPaymentModal) {
      axios
        .get(
          `${process.env.REACT_APP_ENV_PREFIX}/bizcore/checkpayment/${transactionAgreementId}`
        )
        .then(function (response) {
          // handle success
          const { transaction } = response.data;
          transaction && clearInterval(interval);
          if (
            transaction.transactionStatusId === 1 ||
            transaction.transactionStatusId === 3
          ) {
            setShowPaymentModal(false);
            history.push({
              pathname: "/payment-thank-you",
              state: {
                firstName: details.firstname,
                transactionReference: transaction.transactionReference,
                transactionDateFormatted: transaction.transactionDateFormatted,
                transactionSourceType: transaction.transactionSourceType,
                totalAmount: transaction.totalAmountFormatted,
              },
            });
          }
          clearInterval(interval);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      return null;
    }
  }, 1000);

  const scrollRef = useRef(null);

  const executeScroll = () => scrollRef.current.scrollIntoView();

  return (
    <>
      {showModal && (
        <Modal
          details={details}
          requestedAmount={{ amount: formatCurrency(requestedAmount) }}
          showModal={showModal}
          onCloseModal={() => setShowModal(false)}
          onHandleCard={() => {
            setShowModal(false);
            setShowPaymentModal(true);
          }}
        />
      )}

      {showPaymentModal && (
        <PaymentModal
          showPaymentModal={showPaymentModal}
          url={sourceCreationUrl}
        />
      )}

      {showPopUp && (
        <PopUpCalc
          selectedPlan={selectedPlanData}
          scrollDown={executeScroll}
          dateFormat={changeDateFormat}
          formatCurrency={formatCurrency}
          closePopup={closePopup}
          payNowLaterSelected={payNowLaterSelected}
          requestedAmount={requestedAmount}
          startDate={startDate}
          payNowOpenTab={payNowOpenTab.option}
        />
      )}

      {showPopUpContinue && (
        <PopUpCalcContinue
          selectedPlan={selectedPlanData}
          dateFormat={changeDateFormat}
          formatCurrency={formatCurrency}
          closePopupContinue={closePopupContinue}
          payNowLaterSelected={payNowLaterSelected}
          requestedAmount={requestedAmount}
          startDate={startDate}
          payNowOpenTab={payNowOpenTab.option}
          payLaterOpenTab={payLaterOpenTab.option}
        />
      )}

      <div className="pb-10 full-banner customer-landing pay-now-pay-later-form">
        <div className="top-banner flex flex-col pb-6 xl:pb-0 xl:flex-row items-center justify-between flex-wrap border-bottom">
          <div className="p-8 pl-24 flex flex-col xl:flex-row flex-wrap xl:flex-start items-center">
            <a href="https://elepay.com.au"><img className="w-80" src={ElepayLogo} alt="Elepay Logo" /></a>            
          </div>
          <div className="text-white text-2xl font-gilroy-bold text-right text-right pr-10">{partnername}</div>
        </div>

        {urlError == false && (
          <div className="pl-large pr-large pt-10 pb-10 new-header">
            <div className="flex flex-row xl:flex-start justify-wrap">
              <div className="text-left flex-1">
                <h3 className="text-green-light text-1xl uppercase font-gilroy-bold pb-4 pt-14">
                  Pay now or pay later
                </h3>

                <h1 className="text-white text-6xl pb-8 font-gilroy-bold">
                  Discover how affordable <br /> it is to enjoy more time
                </h1>

                <p className="text-white text-2xl font-gilroy-regular">
                  Use our{" "}
                  <span className="font-gilroy-bold">Plan calculator below</span>{" "}
                  to discover multiple ways in which Elepay can help you enjoy
                  more time.
                </p>
              </div>

              <div className="text-right flex-1">
                <img
                  className="image-large ml-auto "
                  src={GirlCouch}
                  alt="Elepay Logo"
                />
              </div>
            </div>
          </div>
        )}

        {urlError == false && (
          <form onSubmit={handleSubmit}>
            <div
              className={`form flex flex-row text-left pl-large pr-large pt-10 ${
                payNowLaterSelected &&
                payNowOpenTab.optionSelected &&
                "paynow-paylater-selected"
              }`}
            >
              <div
                className={`card mr-6 flex-1 step-1-block ${
                  step == 1 && "active"
                }`}
              >
                <div className="tag active">
                  <p className="font-gilroy-semibold">STEP 1</p>
                </div>

                <h2 className="font-gilroy-bold text-3xl text-purple-light">
                  View Your Payment Options
                </h2>

                <p
                  className={`mt-6 font-gilroy-bold text-md text-black-dark text-left`}
                >
                  Enter Your Amount
                </p>

                <div className="relative dollar-container">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>

                  <input
                    type="tel"
                    prefix={"$"}
                    name="firstName"
                    className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold`}
                    placeholder="0.00"
                    onChange={(e) => {
                      setStep(1);
                      setRequestedAmount(e.target.value);
                    }}
                    value={requestedAmount}
                    maxlength={10}
                  />
                </div>

                <p
                  className={`mt-4 font-gilroy-bold text-md text-black-dark text-left`}
                >
                  Start date
                </p>

                <div className="w-full flex justify-start items-center date-section">
                  <span
                    className="w-7 pl-3 absolute"
                    style={{ color: "#4139ff" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      style={{ marginLeft: "-0.2rem" }}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </span>
                  <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="LL"
                    minDate={new Date()}
                    onDayChange={(date) => {
                      setStartDate(date);
                      setStep(1);
                    }}
                    value={startDate}
                    dayPickerProps={{
                      modifiers: {
                        disabled: [
                          {
                            before: new Date(),
                          },
                        ],
                      },
                    }}
                  />
                </div>

                <div className="flex" style={{ height: "45%" }}>
                  <div className="m-a">
                    <h3 className="mt-4 font-gilroy-bold text-xl text-purple-dark text-center">
                      You decide what works for you!
                    </h3>

                    <p className="mt-2 font-gilroy-regular text-sm text-black-dark text-center">
                      <span className="font-gilroy-bold">Pay Later -</span> Choose
                      between a single repayment at the end of the plan or 4
                      repayments spread over the last 45 days.
                    </p>

                    <p className="mt-4 mb-4 font-gilroy-regular text-sm text-black-dark text-center">
                      <span className="font-gilroy-bold">Pay Now -</span> Pay in
                      one lump sum today
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  className="top-button p-2 mt-2 py-3 px-4  rounded-3xl font-gilroy-bold border text-purple-light"
                  onClick={CalcPayments}
                  disabled={requestedAmount == 0 && true}
                >
                  CALCULATE PAYMENT OPTIONS
                </button>
              </div>

              <div
                className={`card step-2-block flex-3 ${step == 2 && "active"} ${
                  step < 2 && "no-click"
                }`}
              >
                <div
                  className={`change-option ${
                    payNowLaterSelected == "" && " hidden"
                  }`}
                  onClick={(_) => {
                    setPayNowLaterSelected("");
                    setStep(2);
                  }}
                >
                  <p className="font-gilroy-semibold text-purple-dark">
                    CLICK HERE TO CHANGE PAYMENT OPTION
                  </p>
                </div>

                <div className={`tag ${step > 1 && "active"}`}>
                  <p className="font-gilroy-semibold">STEP 2</p>
                </div>

                <div className="flex step-2-container">
                  <div
                    className={`flex-1 step-2-section ${
                      payNowLaterSelected == "Now" && " hidden"
                    }`}
                  >
                    <h2 className="font-gilroy-bold text-3xl text-purple-light text-center">
                      Select a Pay Later Option
                    </h2>

                    {payNowLaterSelected == "" && (
                      <p className="mt-4 font-gilroy-bold text-sm text-black-dark text-center">
                        Choose from three different plan options
                      </p>
                    )}

                    <div
                      className={`less-bottom ${
                        payNowLaterSelected != "" && "hidden"
                      }`}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Tabs
                          textColor={"text-black-light"}
                          bgColor={"bg-purple-light"}
                          selectedTextColor={"text-purple-extra-light"}
                          borderColor={"border-purple-light"}
                          tabBackgroundColor={"bg-white"}
                          normalTabTextColor={"text-purple-light"}
                          activeWidth={36}
                          activeTabWidth="9rem"
                          tabOptions={payLaterTabOptions}
                          onTabChange={(option) => {
                            setPayLaterOpenTab(option);
                          }}
                          selectedTab={payLaterOpenTab}
                        />
                      </motion.div>
                    </div>

                    {payNowLaterSelected == "Later" && (
                      <div className="full-tab">
                        <p className="font-gilroy-semibold">
                          {payLaterOpenTab.option}
                        </p>
                      </div>
                    )}

                    <div
                      className={`flex plan-selector ${
                        payNowLaterSelected ? "selected" : ""
                      }`}
                    >
                      <div
                        className={`chevron chev-left m-auto ${
                          payNowLaterSelected && "hidden"
                        }`}
                        onClick={(_) => {
                          selectedPlan == "60"
                            ? setSelectedPlan("12")
                            : selectedPlan == "90"
                            ? setSelectedPlan("60")
                            : selectedPlan == "12" && setSelectedPlan("90");
                        }}
                      ></div>

                      <div
                        className={`plan-selector-container ${
                          selectedPlan == "60" && "active"
                        } ${payNowLaterSelected ? "large" : ""} ${
                          payNowLaterSelected && selectedPlan != "60"
                            ? "hidden"
                            : ""
                        }`}
                        onClick={(_) => {
                          setSelectedPlan("60");
                        }}
                      >
                        <img src={days60} alt="60_days icon" />
                      </div>

                      <div
                        className={`plan-selector-container ${
                          selectedPlan == "90" && "active"
                        } ${payNowLaterSelected ? "large" : ""} ${
                          payNowLaterSelected && selectedPlan != "90"
                            ? "hidden"
                            : ""
                        }`}
                        onClick={(_) => {
                          setSelectedPlan("90");
                        }}
                      >
                        <img src={days90} alt="90_days icon" />
                      </div>

                      <div
                        className={`plan-selector-container ${
                          selectedPlan == "12" && "active"
                        } ${payNowLaterSelected ? "large" : ""} ${
                          payNowLaterSelected && selectedPlan != "12"
                            ? "hidden"
                            : ""
                        }`}
                        onClick={(_) => {
                          setSelectedPlan("12");
                        }}
                      >
                        <img src={days120} alt="120_days icon" />
                      </div>

                      <div
                        className={`chevron chev-right m-auto ${
                          payNowLaterSelected && "hidden"
                        }`}
                        onClick={(_) => {
                          selectedPlan == "60"
                            ? setSelectedPlan("90")
                            : selectedPlan == "90"
                            ? setSelectedPlan("12")
                            : selectedPlan == "12" && setSelectedPlan("60");
                        }}
                      ></div>
                    </div>

                    <h3 className="mt-8 font-gilroy-bold text-xl text-purple-dark text-center">
                      No Payment for {selectedPlanData[0]?.product?.firstPayment}{" "}
                      days,
                    </h3>

                    <p className="mt-2 font-gilroy-semibold text-sm text-black-dark text-center">
                      followed by{" "}
                      {selectedPlanData[0]?.payments.length > 1
                        ? "4 x equal payments due on"
                        : "1 x Payment due on"}
                      :
                    </p>

                    <p className="mt-1 font-gilroy-bold text-sm text-black-dark text-center">
                      {selectedPlanData[0]?.payments.length > 1
                        ? selectedPlanData[0]?.payments?.map((item, i) => {
                            return (
                              <>
                                {changeDateFormat(item?.date)}
                                <span className="dash-line">|</span>
                              </>
                            );
                          })
                        : changeDateFormat(
                            selectedPlanData[0]?.payments[0]?.date
                          )}
                    </p>

                    <h1 className="mt-4  mb-8 font-gilroy-bold text-4xl text-purple-light text-center amount">
                      {selectedPlanData[0]?.payments.length > 1
                        ? formatCurrency(selectedPlanData[0]?.payments[0]?.amount)
                        : formatCurrency(
                            selectedPlanData[0]?.product?.totalrepayments
                          )}
                    </h1>

                    <button
                      type="button"
                      className={`top-button p-2 py-3 px-4  rounded-3xl font-gilroy-bold border text-purple-light ${
                        step == 3 && "indent"
                      }`}
                      onClick={(_) => {
                        setShowPopUp(!showPopUp);
                        setPayNowLaterSelected("Later");
                      }}
                      disabled={step == 1 || (step == 3 && true)}
                    >
                      {step == 3 ? "SELECTED" : "SELECT"} PAY LATER
                    </button>
                  </div>

                  <div
                    className={`vertical-container ${
                      payNowLaterSelected && " hidden"
                    }`}
                  >
                    <p className="text-purple-light font-gilroy-semibold">OR</p>
                    <div className="vertical-line"></div>
                  </div>

                  <div
                    className={`flex-1 step-2-section ${
                      payNowLaterSelected == "Later" && " hidden"
                    }`}
                  >
                    {payNowLaterSelected == "" && (
                      <>
                        {" "}
                        <h2 className="font-gilroy-bold text-3xl text-purple-light text-center">
                          Our Pay Now Options
                        </h2>
                        <p className="mt-4 font-gilroy-bold text-sm text-black-dark text-center">
                          Choose a Repayment method:
                        </p>
                      </>
                    )}

                    <div
                      className={`less-bottom ${
                        payNowLaterSelected != "" && "hidden"
                      }`}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Tabs
                          textColor={"text-black-light"}
                          bgColor={"bg-purple-light"}
                          selectedTextColor={"text-purple-extra-light"}
                          borderColor={"border-purple-light"}
                          tabBackgroundColor={"bg-white"}
                          normalTabTextColor={"text-purple-light"}
                          activeWidth={36}
                          activeTabWidth="9rem"
                          tabOptions={payNowTabOptions}
                          onTabChange={(option) => setNowOpenTab(option)}
                          selectedTab={payNowOpenTab}
                        />
                      </motion.div>
                    </div>

                    {payNowLaterSelected == "Now" && (
                      <div className="full-tab">
                        <p className="font-gilroy-semibold">
                          {payNowOpenTab.option}
                        </p>
                      </div>
                    )}

                    <p className="mb-6 font-gilroy-bold text-sm text-black-dark text-center">
                      To make your life as easy and as hassle-free as possible, we
                      offer a selection of payment options with your order:
                    </p>

                    <div className="pay-options-block">
                      <div className="flex flex-col items-center">
                        {payNowOpenTab.option == "Direct Debit" ? (
                          <span>
                            <img
                              className="direct-debit-img"
                              src={DirectDebit}
                              alt="direct debit"
                            />
                          </span>
                        ) : (
                          <>
                            <span className="flex">
                              <img
                                className="flex pl-2 pb-2 w-20"
                                src={masterCard}
                                alt="60 Days"
                              />
                              <img
                                className="flex pl-2 pb-2 w-20"
                                src={visaCard}
                                alt="60 Days"
                              />
                            </span>
                            <span className="flex">
                              <img
                                className="flex pl-2 w-20"
                                src={applePayCard}
                                alt="60 Days"
                              />
                              <img
                                className="flex pl-2 w-20"
                                src={gPayCard}
                                alt="60 Days"
                              />
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    <p className="mt-4 font-gilroy-bold text-sm text-black-dark text-center">
                      {payNowOpenTab.option == "Credit Card"
                        ? "1.25% Fee applies"
                        : "$2.95 per payment"}
                    </p>

                    <h1
                      className="mt-4  mb-8 font-gilroy-bold text-4xl text-purple-light text-center amount"
                      placeholder="00.00"
                    >
                      {formatCurrency(requestedAmount)}
                    </h1>

                    <button
                      type="button"
                      className={`top-button p-2 py-3 px-4  rounded-3xl font-gilroy-bold border text-purple-light ${
                        step == 3 && "indent"
                      }`}
                      onClick={(_) => {
                        setStep(3);
                        setShowPopUp(!showPopUp);
                        setPayNowLaterSelected("Now");
                      }}
                      disabled={step == 1 || (step == 3 && true)}
                    >
                      {step == 3 ? "SELECTED" : "SELECT"} PAY NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex flex-col justify-center w-4/5 xl:w-1/5 xl:w-3/5 m-auto mt-6 step-3-block"
              ref={scrollRef}
            >
              <div
                className={`card flex-3 ${step == 3 && "active"} ${
                  step != 3 && "no-click"
                }`}
              >
                <div className={`tag tag-3 ${step > 2 && "active"}`}>
                  <p className="font-gilroy-semibold">YOUR FINAL STEP 3</p>
                </div>

                <h2 className="font-gilroy-bold text-3xl text-purple-light text-left">
                  Enter Your Personal Details
                </h2>

                <div className="flex">
                  <div className="flex-1 mr-4">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["firstName"] && "text-red-dark"
                      }`}
                    >
                      First Name
                    </p>
                    <input
                      type="text"
                      name="firstName"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["firstName"] && "border-red-dark"
                      }`}
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["firstName"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["firstName"]}
                      </p>
                    )}
                  </div>

                  <div className="flex-1 mr-4">
                    <p className="mt-4 font-gilroy-bold text-md text-black-dark text-left">
                      Middle Name
                    </p>
                    <input
                      type="text"
                      name="middleName"
                      className="p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold"
                      value={middleName}
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                        handleChange(e);
                      }}
                    />
                  </div>

                  <div className="flex-1">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["lastName"] && "text-red-dark"
                      }`}
                    >
                      Last Name
                    </p>
                    <input
                      type="text"
                      name="lastName"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["lastName"] && "border-red-dark"
                      }`}
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["lastName"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["lastName"]}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 mr-4">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["email"] && "text-red-dark"
                      }`}
                    >
                      Email
                    </p>
                    <input
                      type="text"
                      name="email"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["email"] && "border-red-dark"
                      }`}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["email"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["email"]}
                      </p>
                    )}
                  </div>

                  <div className="flex-1">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["mobile"] && "text-red-dark"
                      }`}
                    >
                      Mobile
                    </p>
                    <input
                      type="text"
                      name="mobile"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["mobile"] && "border-red-dark"
                      }`}
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["mobile"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["mobile"]}
                      </p>
                    )}
                  </div>
                </div>

                <h2 className="font-gilroy-bold text-3xl text-purple-light text-left mt-7">
                  The property address where the requested funds will be expended
                </h2>

                <div className="flex">
                  <div className="flex-1 mr-4">
                    <p className="mt-4 font-gilroy-bold text-md text-black-dark text-left">
                      Unit Number
                    </p>
                    <input
                      type="text"
                      name="unitNumber"
                      className="p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold"
                      value={unitNumber}
                      onChange={(e) => {
                        setUnitNumber(e.target.value);
                        handleChange(e);
                      }}
                    />
                  </div>

                  <div className="flex-1 mr-4">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["streetNumber"] && "text-red-dark"
                      }`}
                    >
                      Street Number
                    </p>
                    <input
                      type="text"
                      name="streetNumber"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["streetNumber"] && "border-red-dark"
                      }`}
                      value={streetNumber}
                      onChange={(e) => {
                        setStreetNumber(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["streetNumber"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["streetNumber"]}
                      </p>
                    )}
                  </div>

                  <div className="flex-1">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["streetName"] && "text-red-dark"
                      }`}
                    >
                      Street Name
                    </p>
                    <input
                      type="text"
                      name="streetName"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["streetName"] && "border-red-dark"
                      }`}
                      value={streetName}
                      onChange={(e) => {
                        setStreetName(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["streetName"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["streetName"]}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-3 mr-4">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["suburb"] && "text-red-dark"
                      }`}
                    >
                      Suburb
                    </p>
                    <input
                      type="text"
                      name="suburb"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["suburb"] && "border-red-dark"
                      }`}
                      value={suburb}
                      onChange={(e) => {
                        setSuburb(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["suburb"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["suburb"]}
                      </p>
                    )}
                  </div>

                  <div className="flex-1 mr-4">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["postCode"] && "text-red-dark"
                      }`}
                    >
                      Postcode
                    </p>
                    <input
                      type="text"
                      name="postCode"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["postCode"] && "border-red-dark"
                      }`}
                      value={postCode}
                      onChange={(e) => {
                        setPostCode(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["postCode"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm text-left">
                        {errors["postCode"]}
                      </p>
                    )}
                  </div>

                  <div className="flex-1">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark text-left ${
                        errors["state"] && "text-red-dark"
                      }`}
                    >
                      State
                    </p>

                    <StateSelect
                      className="border-2 rounded-xl font-gilroy-semibold p-4"
                      options={states}
                      onOptionChange={(e) => setState(e.target.value)}
                      selectedOption={state}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="small-button p-2 mt-10 py-3 px-4 text-purple-light rounded-3xl font-gilroy-bold border border-purple-light"
                  disabled={step != 3 && true}
                >
                  START NOW
                </button>
              </div>
            </div>
          </form>
        )}

        {urlError == true && (
          <div className="pl-large pr-large pt-10 pb-20 new-header">
            <div className="flex flex-row xl:flex-start justify-wrap">
              <div className="text-left flex-1">
                <h3 className="text-green-light text-1xl uppercase font-gilroy-bold pb-4 pt-14">
                  Oops!
                </h3>

                <h1 className="text-white text-6xl pb-8 font-gilroy-bold">
                  Something has gone wrong!
                </h1>

                <p className="text-white text-2xl font-gilroy-regular">
                  It appears that {getPartnerNameByCode(partner_code) != '' ? getPartnerNameByCode(partner_code) : ' this particular partner'} has not been registered for this product!{" "}
                  Please contact {getPartnerNameByCode(partner_code) != '' ? <span className="font-gilroy-bold">{getPartnerNameByCode(partner_code)}</span> : 'them'}{" "}
                  for further assistance.
                </p>
              </div>

              <div className="text-right flex-1">
                <img
                  className="image-large ml-auto "
                  src={GirlCouch}
                  alt="Elepay Logo"
                />
              </div>
            </div>
          </div>
        )}

        <div className="p-16 pb-16 flex flex-col text-center xl:text-right xl:flex-row flex-wrap xl:justify-between items-center">
          <img className="flex w-40" src={ElepayPrimaryLogo} alt="Elepay" />
          <p className="text-sm pt-4 xl:pt-0 font-gilroy-regular">
            ABN: 47 634 728 591. Copyright 2022 Elepay. <br />
            All rights reserved.
          </p>
        </div>

        <div className="p-16 pt-12 pb-12 flex flex-col text-left xl:flex-row flex-wrap " style={{backgroundColor: "#12026D" }}>   
          <div class="font-gilroy-bold" style={{color: "#00ffc4", fontSize: "14px"}}>IMPORTANT INFORMATION</div>           
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
            ^ By submitting this enquiry, you acknowledge that you have read <a href="https://go.elepay.com.au/static/pdf/PrivacyPolicy-ElevarePayEasyPtyLtd.pdf" target="_blank" style={{color: "#00ffc4"}} >Elepay's Privacy Policy</a> and understand that Elepay will use your personal information to contact you in relation to this enquiry and for other general marketing purposes. You can change your marketing preferences by contacting our support team on 1300 019 417 (or support@elepay.com.au) or by clicking the unsubscribe button on our marketing communications.
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^ Our online calculator should be used as an indication only and does not indicate approval for the loan or that the product is suitable for you. The calculator should be used as a guide to see the loan repayments based on the loan amount, loan term and number of repayments. The calculator does not take into account default fees or late interest and any applicable discount on early repayment. It's important that you are comfortable with, and have the financial capacity to make, the required repayments. 
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^All information including interest rate is subject to change without notice. Full details available on application.
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^Approval is subject to Elepay's lending and credit criteria and suitability assessment. Terms and conditions apply and are available upon submission of your application or upon request. Any information on this website is of a general nature only and does not take into account your objectives, financial situation or needs.   
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^Before applying, you should consider whether this product is appropriate for you by reviewing your individual circumstances and seeking independent professional financial advice. Please review our <a href="https://go.elepay.com.au/static/pdf/ElepayTMD.pdf" target="_blank" style={{color: "#00ffc4"}} >Target Market Determination</a> when considering whether our product is right for you. For further information or an individual assessment of your eligibility, please contact our Support Team.  
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"#00ffc4", fontSize: "14px" }}>
          ^Member of the Australian Financial Complaints Authority Limited A.B.N. 38 620 494 340 (Member number 96789). 
          </p>
        </div>

      </div>
    </>
  );
};

export default PayNowPayLater;
