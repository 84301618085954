import React from "react";
import { motion } from "framer-motion";
import days60 from '../../assets/images/60.svg';
import days90 from '../../assets/images/90.svg';
import days120 from '../../assets/images/120.svg';

export default function ProductConfirmationBox({ showModal, onCloseModal, product, typeOfPayment, fundsDisbursed, onHandleProduct, onCloseConfirmBox }) {

    return (
        <>
            {showModal ? (
                <div className="justify-center items-center flex h-full overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="flex flex-col justify-center items-center bg-white inset-0 z-50 outline-none focus:outline-none rounded-2xl" style={{ width: "24rem", boxShadow: "-1px 0px 13px 0px #000000" }}>
                        <h3 className="font-gilroy-semibold text-purple-light text-2xl py-4" style={{ paddingTop: "1.5rem" }}>You have selected</h3>
                        <div className="flex flex-col justify-center items-center p-4 bg-black-light rounded-2xl" style={{ border: "1px solid black", width: "13rem" }}>
                            <h3 className="flex items-center py-2 text-xl text-purple-light font-gilroy-semibold">{product === 60 ? <img src={days60} alt="60 Day" /> : product === 90 ? <img src={days90} alt="90 Day" /> : <img src={days120} alt="120 Day" />} <span style={{ fontSize: "1.5rem", paddingTop: "2px", paddingLeft: "0.3rem" }}>{" "}Day plan</span></h3>
                            <p className="text-lg font-gilroy-bold text-black-dark py-1">{typeOfPayment === 1 ? '1' : '4'} x payment{typeOfPayment === 4 && 's'}</p>
                            {fundsDisbursed && !fundsDisbursed.includes(undefined || NaN) && <><p className="text-md font-gilroy-regular text-black-dark py-1">Funds will be available in your account on</p>
                            <h3 className="text-lg font-gilroy-bold text-black-dark py-1">{fundsDisbursed}</h3></>}
                        </div>
                        <div className="flex justify-around w-full py-4" style={{ paddingBottom: "1.5rem" }}>
                            <button className="rounded-3xl font-gilroy-bold text-purple-light" style={{ border: "1px solid black", padding: "0.6rem 1rem" }} onClick={onCloseConfirmBox}>
                                <motion.button whileHover={{ scale: 1.1 }}>
                                    BACK
                                </motion.button>
                            </button>
                            <button className="rounded-3xl font-gilroy-bold bg-purple-light text-white" style={{ border: "1px solid black", padding: "0.6rem 1rem" }} onClick={onHandleProduct}>
                                <motion.button whileHover={{ scale: 1.1 }}>
                                    CONTINUE
                                </motion.button>
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}