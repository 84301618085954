import React, { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";

const Tabs = ({ tabOptions, onTabChange, selectedTab }) => {
  const [[page], setPage] = useState([0, 0]);

  return (
    <div className="flex flex-wrap mb-10">
      <div className="w-full">
        <AnimateSharedLayout>
          <ul
            className="flex justify-center mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            {tabOptions && (
              <li className="flex w-80 text-center border-2 rounded-3xl">
                {tabOptions.map((option, i) => {
                  const isActive = i === page;

                  return (
                    <div className="flex flex-column">
                      {isActive && (<motion.div className="absolute w-40 h-12 rounded-3xl text-purple-dark bg-green-light" layoutId="underline" />)}
                      <a
                        className={
                          "z-10 text-md font-bold font-gilroy-regular px-5 py-3 rounded-3xl block leading-normal text-white " +
                          (selectedTab === option
                            ? "text-purple-dark"
                            : "text-white ")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          onTabChange(option);
                          setPage([i, i - page]);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                      >
                        {option}
                        {/* {isActive && (<motion.div className=" w-full h-1 rounded-2xl text-purple-dark bg-green-light" layoutId="underline" />)} */}
                      </a></div>)
                })}
              </li>
            )}
          </ul>
        </AnimateSharedLayout>
      </div>
    </div>
  );
};

export default Tabs;
