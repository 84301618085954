import { motion } from "framer-motion";

import days60 from "../../assets/images/60_days.svg";
import days90 from "../../assets/images/90_days.svg";
import days120 from "../../assets/images/120_days.svg";
import masterCard from "../../assets/images/mastercard.svg";
import visaCard from "../../assets/images/visa.svg";
import applePayCard from "../../assets/images/apple-pay.svg";
import gPayCard from "../../assets/images/g-pay.svg";

import DayPickerInput from "react-day-picker/DayPickerInput";
import { parseDate } from "react-day-picker/moment";

export default ({
  selectedPlan,
  dateFormat,
  formatCurrency,
  closePopupContinue,
  payNowLaterSelected,
  requestedAmount,
  startDate,
  payNowOpenTab,
  payLaterOpenTab,
}) => {
  let image = selectedPlan[0]?.product?.numberOfDays;

  return (
    <div className="calc-popup continue">
      <div className="popup-container">
        <div className="m-a">
          {payNowLaterSelected == "Now" ? (
            <>
              <h2 className="font-gilroy-bold text-3xl text-purple-light text-center pb-0">
                Confirm your Pay Now Payment Selection
              </h2>

              <h3 className="mt-6 font-gilroy-bold text-xl text-purple-dark text-center">
                Via {payNowOpenTab}
              </h3>

              {payNowOpenTab == "Credit Card" ? (
                <>
                  <p className="mt-4 font-gilroy-bold text-md text-black-dark">
                    See below the different payment options available to you.
                  </p>

                  <div className="flex flex-col items-center mt-8">
                    <span className="flex">
                      <img
                        className="flex pl-2 pb-2 w-20"
                        src={masterCard}
                        alt="60 Days"
                      />
                      <img
                        className="flex pl-2 pb-2 w-20"
                        src={visaCard}
                        alt="60 Days"
                      />
                    </span>
                    <span className="flex">
                      <img
                        className="flex pl-2 w-20"
                        src={applePayCard}
                        alt="60 Days"
                      />
                      <img
                        className="flex pl-2 w-20"
                        src={gPayCard}
                        alt="60 Days"
                      />
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="flex flex-col items-center mt-1">
                <div className="flex">
                  <div className="mr-1">
                    <p
                      className={`mt-4  font-gilroy-bold text-sm text-black-dark text-left`}
                    >
                      Amount
                    </p>

                    <input
                      type="text"
                      name="firstName"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-bold disabled`}
                      placeholder="$0.00"
                      value={formatCurrency(requestedAmount)}
                      readOnly
                    />
                  </div>

                  <div className="ml-1">
                    <p
                      className={`mt-4 pb-1 font-gilroy-bold text-sm text-black-dark text-left`}
                    >
                      Start date
                    </p>

                    <div className="w-full flex justify-start items-center disabled">
                      <span
                        className="w-7 pl-3 absolute"
                        style={{ color: "#4139ff" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          style={{ marginLeft: "-0.2rem" }}
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                      </span>
                      <DayPickerInput
                        formatDate={dateFormat}
                        value={startDate}
                      />
                    </div>
                  </div>
                </div>

                <p
                  className={`mt-2 justify-start font-gilroy-regular text-sm text-black-dark text-left`}
                  style={{ fontStyle: "italic" }}
                >
                  {payNowOpenTab == "Credit Card"
                    ? "1.25% Fee applies"
                    : "$2.95 per payment"}
                </p>

                <div className="flex justify-center mt-8 mb-4 button-container">
                  <button
                    type="button"
                    className="p-1 mt-2 py-2 px-4 text-purple-light rounded-3xl font-gilroy-bold border border-purple-light"
                    onClick={(_) => closePopupContinue("back")}
                  >
                    BACK
                  </button>

                  <button
                    type="button"
                    className="p-1 mt-2 py-2 px-4 text-white rounded-3xl font-gilroy-bold border border-purple-light bg-purple-light"
                    onClick={(_) => closePopupContinue("continue")}
                  >
                    CONTINUE
                  </button>
                </div>

                <p className={`mt-4 font-gilroy-bold text-md text-black-dark`}>
                  You will be redirected to a new page to complete your Elepay
                  Pay Later Application.
                </p>
              </div>
            </>
          ) : (
            <>
              <h2 className="font-gilroy-bold text-3xl text-purple-light text-center pb-0">
                Confirm your Pay Later Payment Selection
              </h2>

              <div className="flex">
                <div className="image-container">
                  <img
                    src={
                      image == "60" ? days60 : image == "120" ? days120 : days90
                    }
                    alt="Option Icon"
                  />
                </div>

                <div className="m-a">
                  <div className="mr-1">
                    <p
                      className={`mt-4  font-gilroy-bold text-sm text-black-dark text-left`}
                    >
                      Amount
                    </p>

                    <input
                      type="text"
                      name="firstName"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-bold disabled`}
                      placeholder="$0.00"
                      value={formatCurrency(requestedAmount)}
                      readOnly
                    />
                  </div>

                  <div className="ml-1">
                    <p
                      className={`mt-4 pb-1 font-gilroy-bold text-sm text-black-dark text-left`}
                    >
                      Start date
                    </p>

                    <div className="w-full flex justify-start items-center disabled">
                      <span
                        className="w-7 pl-3 absolute"
                        style={{ color: "#4139ff" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          style={{ marginLeft: "-0.2rem" }}
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                      </span>
                      <DayPickerInput
                        formatDate={dateFormat}
                        value={startDate}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h3 className="mt-4 font-gilroy-bold text-xl text-purple-dark text-center">
                No Payment for {selectedPlan[0]?.product?.firstPayment} days
              </h3>

              <p className={`mt-1 font-gilroy-bold text-md text-black-dark`}>
                followed by{" "}
                {selectedPlan[0]?.payments?.length > 1
                  ? "4 x equal payments due on"
                  : "1 x payment due on"}
                :
              </p>

              <p className="font-gilroy-bold text-md text-black-dark text-center">
                {selectedPlan[0]?.payments?.length > 1
                  ? selectedPlan[0]?.payments?.map((item, i) => {
                      return (
                        <>
                          {dateFormat(item?.date)}
                          <span className="dash-line">|</span>
                        </>
                      );
                    })
                  : dateFormat(selectedPlan[0]?.payments[0]?.date)}
              </p>

              <h1 className="mt-5  mb-5 font-gilroy-bold text-4xl text-purple-light text-center amount">
                {selectedPlan[0]?.payments.length > 1
                  ? formatCurrency(selectedPlan[0]?.payments[0]?.amount)
                  : formatCurrency(selectedPlan[0]?.product?.totalrepayments)}
              </h1>

              <div className="flex justify-center">
                <button
                  type="button"
                  className="p-1 mt-2 py-2 px-4 text-purple-light rounded-3xl font-gilroy-semibold border border-purple-light"
                  onClick={(_) => closePopupContinue("back")}
                >
                  BACK
                </button>

                <button
                  type="button"
                  className="p-1 mt-2 py-2 px-4 text-white rounded-3xl font-gilroy-semibold border border-purple-light bg-purple-light"
                  onClick={(_) => closePopupContinue("continue")}
                >
                  CONTINUE
                </button>
              </div>

              <p className={`mt-6 font-gilroy-bold text-md text-black-dark`}>
                You will be redirected to a new page to complete your Elepay Pay
                Later Application.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
