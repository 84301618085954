import React, {useState, useEffect}  from 'react';
import axios from 'axios';
import Header from './common/Header';
import ChooseProduct from './ChooseProduct';

const Partner = ({location, match}) => {
    const { firstname, lastname, partner_code, partner_name, user_id } = location.state || {partner_name: "Realestate"};
    
    return (
        <div>
            <Header partner_name={partner_name} />
            <ChooseProduct />
        </div>
    )
}

export default Partner;