import React, { useState } from "react";
import { Link } from "react-router-dom";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { motion } from "framer-motion";
import NormalSelect from "./common/NormalSelect";
import Header from "./Header";
import Footer from "./Footer";

const Landing = () => {
  const [use, setUse] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  // Sample Data
  // TODO: Data to be revised
  const useArray = ["", "renovating", "buying", "managing"];
  const nameArray = ["", "kitchen", "bathroom", "bedroom", "deck", "roof"];
  const priceArray = ["", "5,000", "20,000", "35,000", "50,000"];

  return (
    <>
    <Header />
    <div className="relative flex flex-col items-center justify-center w-full bg-purple-light" style={{ height: "80vh" }}>
      <div>
        <p className="leading-normal text-4xl font-gilroy-regular text-white text-center m-auto mb-20">
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <span>I am </span>
            <NormalSelect
              options={useArray}
              onOptionChange={(e) => setUse(e.target.value)}
              selectedOption={use}
            />
            &nbsp;&nbsp;&nbsp;
          </motion.span>
          {use && (
            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              <span>my </span>
              <NormalSelect
                options={nameArray}
                onOptionChange={(e) => setName(e.target.value)}
                selectedOption={name}
              />
              &nbsp;&nbsp;&nbsp;
            </motion.span>
          )}
          {name && (
            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              <span>
                and <br /> I need $
              </span>
              <NormalSelect
                options={priceArray}
                onOptionChange={(e) => setPrice(e.target.value)}
                selectedOption={price}
              />
              &nbsp;&nbsp;&nbsp;
            </motion.span>
          )}
          {price && (
            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              <span>by the </span>
              <DayPickerInput
                wrapperClassName="dateInputClass"
                formatDate={formatDate}
                parseDate={parseDate}
                format="LL"
                minDate={new Date()}
                onDayChange={(date) => setStartDate(date)}
                value={startDate}
                style={{ color: "#120270", background: "transparent" }}
              />
            </motion.span>
          )}
        </p>
        {use && name && price && startDate && (
          <motion.button whileHover={{ scale: 1.1 }}>
            <Link
              to={{
                pathname: "/test/options",
                state: {
                  use,
                  name,
                  price,
                },
              }}
              className="p-4 mt-14 font-gilroy-bold text-white border-2 border-white-200 rounded-3xl"
            >
              Let's see options
            </Link>
          </motion.button>
        )}
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Landing;
