import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import Joi from 'joi-browser';
import Header from './Pay_107_Calc_HeaderNormal';
import Pay_107_Calc_Head from './Pay_107_Calc_HeadNormal';
import Pay_107_Calc_PayLater from './Pay_107_Calc_PayLaterNormal';

import 'react-tabs/style/react-tabs.css';
import './style.css';

const Pay_107_CalcNormal = ({ location, match }) => {
    const {partner_code, leadId } = match.params;
    const [details, setDetails] = useState(
        {
            "requestedamount": 0,
            "firstname": "",
            "address": {
              "fullAddress": "",
              "id": 0,
              "postcode": "0",
              "state": "",
              "streetName": "",
              "streetNumber": "",
              "suburb": "",
              "unitNumber": ""
            },
            "phone": "",
            "options": [
              {
                "amount": 0,
                "products": [
                  {
                    "application_url": "",
                    "earlyPayments": [
                        
                    ],
                    "payments": [
                      
                    ],
                    "product": {
                      "code": "",
                      "firstPayment": 0,
                      "name": "",
                      "numberOfDays": 0,
                      "numberOfPayments": 0,
                      "paymentInterval": 0
                    }
                  },
                ]
              }
            ],
            "payment_methods": [],
            "startdate": "",
            "email": "",
            "form_code": "",
            "lastname": ""
        }
    );

    const [options, setOptions] = useState([{
        "amount": 0,
        "products": [
          {
            "application_url": "",
            "earlyPayments": [
                
            ],
            "payments": [
              
            ],
            "product": {
              "code": "",
              "firstPayment": 0,
              "name": "",
              "numberOfDays": 0,
              "numberOfPayments": 0,
              "paymentInterval": 0
            }
          }
        ]
      }]);

    const history = useHistory();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [requestedAmount, setRequestedAmount] = useState({ index: 0, amount: "0", option: "a" });
    const [payOptions, setPayOptions] = useState([]);

    // Validation Errors
    const [errors, setErrors] = useState({});

    // const [planOptions, setPlanOptions] = useState(details['options']);
    const [showPayTodayOptions, setShowPayTodayOptions] = useState(false);
    const [showPayLaterOptions, setShowPayLaterOptions] = useState(false);

    const [showBlur, setShowBlur] = useState(false);

    const handleBlur = () => {
        setShowBlur(true);
    }

    const handleCalculate = (fundAmount, startDate) => {
      // Validation Schema
      const schema = {
          fundAmount: Joi.number().min(1).label("Fund amount").required(),
          startDate: Joi.date().required()
      }

      const validate = () => {
          const validateDetails = {
              fundAmount, startDate
          }
          const options = { abortEarly: false };
          const { error } = Joi.validate(validateDetails, schema, options);
          if (!error) return null;

          const errors = {};
          error.details.map(error => errors[error.path[0]] = error.message);

          return errors;
      }

      // Validating upon submit
      const errors = validate();
      console.log('errors', errors);
      setErrors(errors || {});
      if (errors) return;

      axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_update`, {
          requestedamount: fundAmount,
          startdate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2'),
          form: { code: "gen107calc" }
      })
          .then(function (response) {
              console.log('hello response', response);
              if(!errors) setShowPayLaterOptions(true);
              setOptions(response.data.options);
          })
          .catch(function (error) {
              console.log('error', error);
          });
  };
    return (
        <div className="calc107Container m-auto">
            <Header />
            <div className="flex flex-col tablet:flex-row">
                <div className="w-full flex flex-wrap justify-center ml-0">
                    <div className="flex flex-col m-4 tablet:m-0 tablet:mt-4 w-full">
                        <div className="flex flex-col justify-center w-full">
                            <div className="w-full order-1 tablet:order-2 ml-2 p-8 bg-purple-light rounded-3xl border border-purple-extra-light">
                                <Pay_107_Calc_Head showPayLaterOptions={showPayLaterOptions} errors={errors} planOptions={options} onHandleCalculate={handleCalculate} details={details} leadId={leadId} requestedAmount={requestedAmount} /> 
                                {showPayLaterOptions && <Pay_107_Calc_PayLater planOptions={options} details={details} payOptionsLength={payOptions.length} leadId={leadId} reqAmount={requestedAmount} onRequestedAmountChange={(e) => setRequestedAmount(e.target.value)} showBlur={showBlur} />}
                            </div>
                            {location.state && <button type="button" onClick={() => history.goBack()} className="order-2 p-2 mt-8 py-3 px-8 text-purple-light border-2 rounded-3xl m-auto font-gilroy-bold w-max" style={{ border: "1px solid"}}>
                                <motion.button whileHover={{ scale: 1.1 }} >
                                    BACK
                                </motion.button>
                            </button>}
                        </div>
                        <script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/8513039.js"></script>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pay_107_CalcNormal;