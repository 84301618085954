import React, { useState } from 'react';
import { motion } from 'framer-motion';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { fullMonthNames } from '../../constants/dateConstants';
import StateSelect from '../../components/common/StateSelect';
import CalcMobileImage from '../../assets/images/CalcMobImage.png';
import _ from 'lodash';
import './style.css';


const CalculatorPlanFields = ({ maxFundAmount, fundAmount, startDate, onChangeFundAmount, onChangeStartDate, errors, disbursementDate, purposeOfFunds, purposeOfFund, onPurposeChange, onHandleCalculate , details, options, modifyData, onModifyData}) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const formatDates = (date) => {
      const startDate = new Date(date);
      return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
  };

  const singlePayment = _.orderBy(options[0] && options[0].products.filter(product => product.payments.length === 1), item => item.product.numberOfDays, ['asc']);

  return (
    <div className="text-left flex flex-col items-center">
      <h3 className="block px-4 xl:px-0 xl:hidden text-white text-center text-3xl py-4 font-gilroy-bold">Welcome to Australia's Pay Later specialist for property owners</h3>
      <div className="w-full xl:w-1/3 flex xl:hidden justify-center xl:justify-end">
          <img src={CalcMobileImage} alt="Elepay Calculator" className="w-56" />
      </div>
      <div className="w-96 px-4">
        <p className="text-purple-light xl:text-white text-center xl:text-left text-2xl py-8 xl:py-4 font-gilroy-semibold">Let's calculate your plan</p>
        <div className="flex flex-col">
          <p className={`mt-2 font-gilroy-semibold text-sm xl:text-md text-black xl:text-white`}>How much funding is required?</p>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              name="fundAmount"
              className={`p-4 pl-7 w-full h-12 mt-1 border-2 bg-white border-none rounded-xl font-gilroy-semibold`}
              style={{ background: "white", border: "none" }}
              placeholder="0.00"
              onChange={(e) => {
                onModifyData();
                onChangeFundAmount(e.target.value);
              }}
              value={(!modifyData && details.requestedamount) ? details.requestedamount : fundAmount }
            />
          </div>
          {errors['fundAmount'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['fundAmount']}</p>}
        </div>

        <p className={`mt-5 font-gilroy-semibold text-sm xl:text-md text-black xl:text-white`}>When would you like your plan to start?</p>
        <div className="w-full mt-1 flex justify-start items-center">
          <span className="w-7 pl-3 absolute" style={{ color: "#4139ff" }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" style={{ marginLeft: "-0.2rem" }} viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </span>
          <DayPickerInput
            formatDate={formatDate}
            parseDate={parseDate}
            format="LL"
            minDate={new Date()}
            inputProps={{readOnly: true}}
            onDayChange={(date) => {onModifyData(); onChangeStartDate(date)}}
            value={details.startdate ? formatDates(details.startdate) : formatDates(startDate)}
            dayPickerProps={{
              modifiers: {
                disabled: [
                  {
                    before: new Date()
                  }
                ]
              }
            }}
          />
        </div>

        {!disableSubmitButton && <div className="mt-6 flex self-center w-full">
            <button type="button" onClick={onHandleCalculate} className="bg-purple-light p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold w-full" disabled={disableSubmitButton}>
              <motion.button whileHover={{ scale: 1.1 }} >
                CALCULATE NOW
              </motion.button>
            </button>
          </div>
        }

        {details.referrer !== "flood" && disbursementDate && <div className="flex flex-col"><p className={`mt-5 font-gilroy-semibold text-sm xl:text-md text-black xl:text-white`}>Funds disbursed into your account*:</p>
        <input type="text" disabled name="disbursement_date" className={`disbursement_date p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold bg-green-light text-purple-light`} placeholder="" value={formatDates(disbursementDate ? disbursementDate : details.disbursement_date)} /></div>}
        
        {singlePayment.length >1 &&
          <div className="flex flex-col xl:hidden">
            <p className="mt-5 font-gilroy-semibold text-sm xl:text-md text-black xl:text-white">Purpose of fundsg</p>
            <StateSelect className="border-2 rounded-xl font-gilroy-semibold p-4" options={purposeOfFunds} onOptionChange={onPurposeChange} selectedOption={purposeOfFund} />
          </div>
        }
      </div>
    </div>
  )
};

export default CalculatorPlanFields;