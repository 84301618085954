import React from 'react';

const Pay_107_Calc_Head = ({ details, planOptions, leadId, payOptionsLength, showBlur, reqAmount, amountBefore107days }) => {
    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    const dayPlan = 107;
    return (<div className="flex justify-center tablet:block">
        <div className={`m-0 flex flex-col items-center md:justify-center lg:flex-start lg:flex-start bg-purple-light ${payOptionsLength === 1 && 'rounded-2xl rounded-b-none' }`} style={{ minWidth: 0, filter: `${showBlur && 'blur(10px)'}` }}>
            <div className="flex flex-col justify-center items-center">
                <h3 className="w-full tablet:w-4/5 text-white font-gilroy-bold text-3xl">Welcome to Australia's Pay Later Specialist for Property Owners</h3>
            </div>
            <div className="flex flex-col">
                {/* {options.map((option) => ( */}
                    <div className="mt-4 flex justify-center flex-wrap" key={dayPlan}>
                        <div className="p-4 bg-white rounded-2xl">
                            <p className="text-md font-gilroy-bold text-purple-dark">Your payment of {formatCurrency(planOptions[0].products[0].payments[0].amount)} is in</p>
                            <h3 className="text-5xl pt-2 text-purple-dark font-gilroy-bold">107 days</h3>
                        </div>
                    </div>
                {/* ))} */}
            </div>
        </div>
    </div>)
}

export default Pay_107_Calc_Head;