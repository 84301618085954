import React from 'react';
import { useHistory } from 'react-router';

const CalculatorPlanFields = ({ location, firstName, disbursementDate, purposeOfFunds, purposeOfFund, onPurposeChange, onHandleCalculate , details, options, modifyData, onModifyData}) => {
    const history = useHistory();

    return (
        <div className="text-left flex flex-col items-center">
            {/* <h3 className="block px-4 xl:px-0 xl:hidden text-white text-center text-3xl py-4 font-gilroy-bold">Welcome to Australia's Pay Later specialist for property owners</h3> */}
            {/* <div className="w-full xl:w-1/3 flex xl:hidden justify-center xl:justify-end">
                <img src={CalcMobileImage} alt="Elepay Calculator" className="w-56" />
            </div> */}
            <div className="px-4" style={{ minWidth: 0, maxWidth: "40rem", width: "100%" }}>
                <p className="text-white text-left text-5xl xl:text-8xl pb-8 pt-0 xl:py-4 font-gilroy-regular" style={{ lineHeight: "1.2" }}>No payment for <span className="welcome-back-name underline decoration-green-light border-green-light font-gilroy-regular" style={{ textDecorationColor: "#00ffc4", textDecorationPosition: "under" }}>107 days</span></p>
                <p className="text-white py-4 font-gilroy-regular leading-7 xl:leading-8" style={{ fontSize: "1.4rem" }}>Deferring Property expenses just makes perfect sense. <br /> With no payment for 107 days Elepay Pay Later is ideal when you're looking to invest in your property and funding is required quickly.</p>
                <p className="text-green-light xl:text-white py-4 font-gilroy-bold leading-7 xl:leading-8" style={{ fontSize: "1.4rem" }}>To access our calculator and discover how affordable it is to enjoy more time, simply <button onClick={() => history.push({ pathname: '/calculate/gen107calc', state: { from: location }})} className="text-white xl:text-green-light font-gilroy-bold">CLICK HERE</button>. When you are ready to begin an application fill in your details below.</p>
            </div>
        </div>
    )
};

export default CalculatorPlanFields;