import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { motion } from "framer-motion";
// import { isJwtExpired } from 'jwt-check-expiration';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';

const PayResult = ({ location, match }) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { transactionAgreementId } = params;
    // const jwt = localStorage.getItem('token');

    const [transaction, setTransaction] = useState({});
    const [declineMessage, setDeclineMessage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        setShowLoading(true);
        const interval = setInterval(() => {
                axios.get(`${process.env.REACT_APP_ENV_PREFIX}/bizcore/checkpayment/${transactionAgreementId}`)
                    .then(function (response) {
                        // handle success
                        const { transaction, errors } = response.data;
                        setShowLoading(true);
                        if (transaction?.transactionStatusId === 1 || transaction?.transactionStatusId === 2 || transaction?.transactionStatusId === 3 || transaction?.transactionStatusId === 7) {
                            setTransaction(transaction);
                            setShowLoading(false);
                            clearInterval(interval);
                        }
                        if(transaction?.transactionStatusId !== 1 && transaction?.transactionStatusId !== 2 && transaction?.transactionStatusId !== 3 && transaction?.transactionStatusId !== 7) {
                            setDeclineMessage(transaction?.declineMessage);
                            setShowLoading(false);
                            clearInterval(interval);
                        }
                        if(!transaction && !transaction?.transactionStatusId && errors) {
                            let time = 0;
                            const newInterval = setInterval(() => {
                                time += 1;
                                if(time < 20) {
                                    setShowLoading(true);
                                    axios.get(`${process.env.REACT_APP_ENV_PREFIX}/bizcore/checkpayment/${transactionAgreementId}`)
                                        .then(function (response) {
                                                const { transaction } = response.data;

                                                if (transaction && (transaction?.transactionStatusId === 1 || transaction?.transactionStatusId === 2 || transaction?.transactionStatusId === 3 || transaction?.transactionStatusId === 7)) {
                                                    setTransaction(transaction);
                                                    setShowLoading(false);
                                                    clearInterval(newInterval);
                                                }
                                                if(transaction && (transaction?.transactionStatusId !== 1 && transaction?.transactionStatusId !== 2 && transaction?.transactionStatusId !== 3 || transaction?.transactionStatusId !== 7)) {
                                                    setDeclineMessage(transaction?.declineMessage);
                                                    setShowLoading(false);
                                                    clearInterval(newInterval);
                                                }
                                        })
                                } else {
                                    setShowLoading(false);
                                    setDeclineMessage("Payment Failed");
                                    clearInterval(newInterval);
                                }
                            }, 1000);
                        }
                    })
            }, 1000);

        return () => clearInterval(interval);
    }, []);
    const { transactionReference, transactionDateFormatted, transactionSourceType, totalAmountFormatted } = transaction || {};

    return (<>
    <div className="flex justify-center flex-wrap items-center p-8 bg-purple-dark">
        <img className="pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
    </div>
    {/* {!showLoading && jwt && !isJwtExpired(jwt) && <button type="button" className="border border-purple-light w-max p-2 py-3 px-4 mt-10 text-purple-light rounded-3xl font-gilroy-bold" onClick={() => window.location = process.env.REACT_APP_PORTAL_PREFIX}>
        <motion.button whileHover={{ scale: 1.1 }}>
            Back to Portal
        </motion.button>
    </button>} */}
    {showLoading && <p className="mt-20 text-purple-light text-2xl">Please wait...</p>}
    {declineMessage && (<><h3 className="mt-20 self-center font-gilroy-bold leading-14 text-6xl text-center text-red-dark">{declineMessage}</h3><p className="text-red-dark font-gilroy-semibold">please try again.</p></>)}
    {!declineMessage && transaction?.transactionStatusId && (<>
        <div className="flex flex-col justify-center items-center w-4/5 lg:w-1/5 xl:w-3/5 m-auto">
            <h3 className="mt-20 self-center font-gilroy-bold leading-14 text-6xl text-center text-purple-light">Thank you!</h3>
            <h3 className="font-gilroy-semibold text-purple-light text-2xl">Your payment has been successful!</h3>

            <table className="mt-4">
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Transaction Reference: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{transactionReference} </p>}</td>
                </tr>
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Transaction Date: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{transactionDateFormatted} </p>}</td>
                </tr>
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Total Amount: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{totalAmountFormatted} </p>}</td>
                </tr>
                <tr>
                    <td><p className="font-gilroy-bold text-purple-light text-left">Payment Type: </p></td>
                    <td className="pl-4">{<p className="font-gilroy-semibold text-purple-dark text-left">{transactionSourceType} </p>}</td>
                </tr>

            </table>
        </div></>)}
    </>)
}

export default PayResult;