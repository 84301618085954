import React from "react";

const NormalSelect = ({ options, onOptionChange, selectedOption }) => {
  return (
    <select
      className="bg-transparent appearance-none text-center border-2 border-t-0 border-l-0 border-r-0 border-green-light"
      onChange={onOptionChange}
      value={selectedOption}
      style={{ padding: "0 1rem", height: "3rem" }} //remove later
    >
      <option value="" disabled selected>
        Select
      </option>

      {options.map((option, index) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default NormalSelect;
