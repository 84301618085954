import React from "react";
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';

const CalculatorHeader = ({ partner_code, partner_name }) => {
  return (<>
        <div className="tablet:hidden flex flex-col p-10 tablet:flex-row items-center justify-between flex-wrap items-center">
            {/* <img className="pl-5 pr-10 w-48" src={getPartnerLogoByCode(partner_code)} alt={getPartnerNameByCode(partner_code)} /> */}
            {/* {
                getPartnerLogoByCode(partner_code) ? <img className="pl-5 pr-10 w-48 text-white" src={getPartnerLogoByCode(partner_code)} alt={getPartnerNameByCode(partner_code)} /> : <h3 className="font-gilroy-semibold text-white text-xl">{getPartnerNameByCode(partner_code)}</h3>
            } */}
            <h3 className="text-white font-gilroy-semibold text-2xl">{partner_name}</h3>
            <a href="https://elepay.com.au"><img className="pl-5 pr-10 pt-4 w-48" src={ElepayLogo} alt="Elepay Logo" /></a>
        </div>
        <div className="hidden px-10 py-10 flex tablet:flex flex-row flex-wrap items-center tablet:justify-between">
            {/* <img className="w-40" src={getPartnerLogoByCode(partner_code)} alt={partner_name} /> */}
            {/* {
                getPartnerLogoByCode(partner_code) ? <img className="pl-5 pr-10 w-48 text-white" src={getPartnerLogoByCode(partner_code)} alt={partner_name} /> : <h3 className="font-gilroy-semibold text-white text-xl">{partner_name}</h3>
            } */}
            <h3 className="text-white font-gilroy-semibold text-2xl">{partner_name}</h3>
            <img className="w-40" src={ElepayLogo} alt="Elepay Logo" />
        </div>
  </>
    );
};

export default CalculatorHeader;