import React from 'react';

const CalculatorPlanFields = ({ firstName, disbursementDate, purposeOfFunds, purposeOfFund, onPurposeChange, onHandleCalculate , details, options, modifyData, onModifyData}) => {
    return (
        <div className="text-left flex flex-col items-center">
            {/* <h3 className="block px-4 xl:px-0 xl:hidden text-white text-center text-3xl py-4 font-gilroy-bold">Welcome to Australia's Pay Later specialist for property owners</h3> */}
            {/* <div className="w-full xl:w-1/3 flex xl:hidden justify-center xl:justify-end">
                <img src={CalcMobileImage} alt="Elepay Calculator" className="w-56" />
            </div> */}
            <div className="w-96 px-4">
                <p className="text-white text-center xl:text-left text-6xl py-8 xl:py-4 font-gilroy-regular" style={{ lineHeight: "1.2" }}>Welcome back, <span className="welcome-back-name underline decoration-green-light border-green-light font-gilroy-regular" style={{ textDecorationColor: "#00ffc4", textDecorationPosition: "under" }}>{firstName} !</span></p>
                <p className="text-white py-4 font-gilroy-semibold" style={{ fontSize: "1.4rem", lineHeight: "2rem" }}>Simply fill out the form and funds will be in your account in no time. It's that easy.</p>
            </div>
        </div>
    )
};

export default CalculatorPlanFields;