import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import Joi from 'joi-browser';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import New107MessageBox from './New107MessagBox';
import StateSelect from '../../components/common/StateSelect';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';
import { initTrack } from "../../helpers/tracking";

const PayLaterOptions107NoAuth = ({ location, match }) => {
    const { fastrack_id } = match.params;
    const path = match.url.split('/');
    // Supplier Details
    const [propertyManager, setPropertyManager] = useState("");

    // Property Owner Details
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");

    // Property Address
    const [unitNumber, setUnitNumber] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [streetName, setStreetName] = useState("");
    const [suburb, setSuburb] = useState("");
    const [postCode, setPostCode] = useState("");
    const [state, setState] = useState("QLD");

    // Application Details
    const [requestedAmount, setRequestedAmount] = useState("");
    const [showRequestedAmount2, setShowRequestedAmount2] = useState(false);
    const [requestedAmount2, setRequestedAmount2] = useState("");
    const [showRequestedAmount3, setShowRequestedAmount3] = useState(false);
    const [requestedAmount3, setRequestedAmount3] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [partnerCode, setPartnerCode] = useState("");
    const [partnerName, setPartnerName] = useState("");
    const [show107MessageModal, setShow107MessageModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [cookies, setCookie] = useCookies(['107MessageBox']);

    // Payment link & QR Code Checks
    const [paymentLink, setPaymentLink] = useState(false);
    const [QRCheck, setQRCheck] = useState(false);

    // Validation Errors
    const [errors, setErrors] = useState({});

    const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

    const history = useHistory();

    useEffect(() => {
        console.log(initTrack("PayLaterOptions107NoAuth", partnerCode, null, path[3]));
        const timer = setTimeout(() => {
            console.log('come here bbb');
            const getCookie = cookies['107MessageBox'];
            console.log('getCookie', getCookie);
            getCookie ? setShow107MessageModal(false) : setShow107MessageModal(true);
            getCookie ? setShowMessage(true) : setShowMessage(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_qry/${fastrack_id}?type=ftpartner`)
          .then(function (response) {
            if(response.status === 400 || response.status === 401) history.push('/404');
              const { name, id, type } = response.data;
              setPartnerName(name);
              setPartnerCode(id);
            })
          .catch(function (error) {
              console.log('error', error);
              history.push('/404');
          });
    }, []);

    
    const onShowMessage = () => {
        console.log('cookie received');
        let d = new Date();
        d.setDate(d.getDate() + (30*60*1000));
        setShowMessage(true);
        setCookie('107MessageBox', true, { path: '/', expires: d });
    };

    const handleCloseModal = () => {
      setShow107MessageModal(false);
    };

    // Validation Schema
    const schema = {
        // propertyManager: Joi.string().min(3).max(30).label("Property Manager").required(),
        firstName: Joi.string().min(3).max(20).label("First Name").required(),
        lastName: Joi.string().min(2).max(20).label("Last Name").required(),
        email: Joi.string().email().min(3).max(50).label("Email").required(),
        mobile: Joi.number().label("Mobile").required(),
        unitNumber: Joi.string().allow("").label("Unit Number"),
        streetNumber: Joi.string().label("Street Number").required(),
        streetName: Joi.string().label("Street Name").required(),
        suburb: Joi.string().min(3).max(20).label("Suburb").required(),
        postCode: Joi.number().label("Postcode").required(),
        state: Joi.string().min(1).max(10).label("State").required(),
        requestedAmount: Joi.number().min(1).label("Requested amount").required(),
        requestedAmount2: Joi.number().min(1).allow("").label("Requested Amount b"),
        requestedAmount3: Joi.number().min(1).allow("").label("Requested Amount c"),
        startDate: Joi.date().required()
    }

    const validate = () => {
        const validateDetails = {
            firstName, lastName, email, mobile, unitNumber, streetNumber, streetName, suburb, postCode, state, requestedAmount, requestedAmount2, requestedAmount3, startDate
        }
        const options = { abortEarly: false };
        const { error } = Joi.validate(validateDetails, schema, options);
        if (!error) return null;

        const errors = {};
        error.details.map(error => errors[error.path[0]] = error.message);

        return errors;
    }

    const validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schemaForProperty = { [name]: schema[name] };
        const { error } = Joi.validate(obj, schemaForProperty);
        return error ? error.details[0].message : null;
    }

    const handleChange = ({ currentTarget: input }) => {
        const errors = {};
        const errorMessage = validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        setErrors(errors);
    }

    console.log('Partner CODDe', partnerCode);
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validating upon submit
        const errors = validate();
        setErrors(errors || {});
        if (errors) return;

        setDisableSubmitButton(true);
        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_create?partner_code=${partnerCode}`, {
            firstname: firstName,
            lastname: lastName,
            email: email,
            phone: mobile,
            form: {
                code: "gen107later"
            },
            referrer: "fastrack id:" + fastrack_id,
            address: {
                streetName: streetName,
                unitNumber: unitNumber,
                streetNumber: streetNumber,
                suburb: suburb,
                postcode: postCode,
                state: state
            },
            requestedamount: requestedAmount,
            requestedamount_2: requestedAmount2 === "" ? null : requestedAmount2,
            requestedamount_3: requestedAmount3 === "" ? null : requestedAmount3,
            // startdate: `${startDate.toISOString().split('T')[0]}`
            startdate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2').replace(/(^|\D)(\d)(?!\d)/g, '$10$2')
        })
            .then(function (response) {
                const { qrcode, url } = response.data;
                history.push({
                    pathname: "/thank-you",
                    state: {
                        firstName,
                        lastName,
                        email,
                        mobile,
                        unitNumber: unitNumber,
                        streetNumber: streetNumber,
                        streetName: streetName,
                        suburb,
                        postCode,
                        state,
                        requestedamount: requestedAmount,
                        requestedamount_2: requestedAmount2,
                        requestedamount_3: requestedAmount3,
                        startDate,
                        qrCode: qrcode,
                        url: url,
                        formCode: "gen107later" ,
                        // propertyManager: `${firstname} ${lastname}`,
                        partner_code: partnerCode,
                        partner_name: partnerName
                    },
                })
            })
            .catch(function (error) {
                setDisableSubmitButton(false);
                console.log('error', error);
            });
    }

    return (<div className="mb-10">
        {show107MessageModal && <New107MessageBox show107MessageModal={show107MessageModal} showMessage={showMessage} onShowMessage={onShowMessage} onCloseModal={handleCloseModal} />}
        <div className="flex flex-col pb-6 lg:pb-0 lg:flex-row items-center justify-between flex-wrap items-center bg-purple-dark">
            <div className="p-10 flex flex-col lg:flex-row flex-wrap lg:flex-start items-center bg-purple-dark">
                {/* <img className="flex md:pl-0 lg:pl-40 w-52 lg:w-72" src={getPartnerLogoByCode(partnerCode)} alt={getPartnerNameByCode(partnerCode)} /> */}
                <h3 className="text-white flex md:pl-0 lg:pl-40 font-gilroy-semibold text-2xl">{partnerName}</h3>                                
                <p className="flex text-green-light text-2xl font-gilroy-bold md:pl-0 lg:pl-16">Pay Later Lead Form</p>
            </div>
            <a href="https://elepay.com.au"><img className="pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" /></a>
        </div>
        <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center w-4/5 lg:w-1/5 xl:w-3/5 m-auto">
            <h3 className="mt-20 self-center font-gilroy-bold leading-14 text-4xl text-left text-purple-light">Providing Property Owners with the opportunity to manage property expenses is now even easier with elepay.</h3>
                <p className="mt-12 text-left font-gilroy-semibold text-purple-dark text-lg">You're one step closer to closing the deal. We can help you make it happen. Fill in your customers details below (ensure they are correct). These details will be used in the application process. As soon as you submit this form Elepay will send your customer an email and sms to continue the application online.</p>
                {/* <p className="mt-4 text-left font-gilroy-regular text-black-dark text-md">Once you submit this form, we'll send your property owner an email and sms, inviting them to select a relevant payment option as well as generating a QR code.</p> */}

                {/* Supplier Details */}
                <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Partner details</h3>
                        {/* <p className="mt-4 font-gilroy-regular text-md text-black-dark">Partner agency</p>
                        <input type="text" name="propertyAgency" className="p-4 w-full h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={propertyAgency} disabled /> */}
                        <p className="mt-4 font-gilroy-regular text-md text-black-dark">Partner</p>
                        <input type="text" name="propertyManager" className="p-4 w-full h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={`${partnerName}`} disabled />

                        {/* <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['propertyManager'] && 'text-red-dark'}`}>Property Manager</p>
                        <input type="text" name="propertyManager" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['propertyManager'] && 'border-red-dark'}`} value={propertyManager} onChange={(e) => { setPropertyManager(e.target.value); handleChange(e) }} />
                        {errors['propertyManager'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['propertyManager']}</p>} */}
                    </div>
                </div>

                {/* Property owner details */}
                <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Property owner details</h3>
                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['firstName'] && 'text-red-dark'}`}>First name</p>
                        <input type="text" name="firstName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['firstName'] && 'border-red-dark'}`} value={firstName} onChange={(e) => { setFirstName(e.target.value); handleChange(e) }} />
                        {errors['firstName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['firstName']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['lastName'] && 'text-red-dark'}`}>Last name</p>
                        <input type="text" name="lastName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['lastName'] && 'border-red-dark'}`} value={lastName} onChange={(e) => { setLastName(e.target.value); handleChange(e) }} />
                        {errors['lastName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['lastName']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['email'] && 'text-red-dark'}`}>Email</p>
                        <input type="text" name="email" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['email'] && 'border-red-dark'}`} value={email} onChange={(e) => { setEmail(e.target.value); handleChange(e) }} />
                        {errors['email'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['email']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['mobile'] && 'text-red-dark'}`}>Mobile</p>
                        <input type="text" name="mobile" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['mobile'] && 'border-red-dark'}`} value={mobile} onChange={(e) => { setMobile(e.target.value); handleChange(e) }} />
                        {errors['mobile'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['mobile']}</p>}
                    </div>
                </div>

                {/* Property address */}
                <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Property address</h3>

                        <div className="flex">
                            <div className="w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['unitNumber'] && 'text-red-dark'}`}>Unit Number</p>
                                <input type="text" name="unitNumber" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['unitNumber'] && 'border-red-dark'}`} value={unitNumber} onChange={(e) => { setUnitNumber(e.target.value); handleChange(e) }} />
                                {errors['unitNumber'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['unitNumber']}</p>}
                            </div>
                            <div className="ml-2 w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['streetNumber'] && 'text-red-dark'}`}>Street Number</p>
                                <input type="text" name="streetNumber" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['streetNumber'] && 'border-red-dark'}`} value={streetNumber} onChange={(e) => { setStreetNumber(e.target.value); handleChange(e) }} />
                                {errors['streetNumber'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['streetNumber']}</p>}
                            </div>
                        </div>

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['streetName'] && 'text-red-dark'}`}>Street Name</p>
                        <input type="text" name="streetName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['streetName'] && 'border-red-dark'}`} value={streetName} onChange={(e) => { setStreetName(e.target.value); handleChange(e) }} />
                        {errors['streetName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['streetName']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['suburb'] && 'text-red-dark'}`}>Suburb</p>
                        <input type="text" name="suburb" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['suburb'] && 'border-red-dark'}`} value={suburb} onChange={(e) => { setSuburb(e.target.value); handleChange(e) }} />
                        {errors['suburb'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['suburb']}</p>}

                        <div className="flex">
                            <div className="w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['postCode'] && 'text-red-dark'}`}>Postcode</p>
                                <input type="number" name="postCode" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['postCode'] && 'border-red-dark'}`} value={postCode} onChange={(e) => { setPostCode(e.target.value); handleChange(e) }} />
                                {errors['postCode'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['postCode']}</p>}
                            </div>
                            <div className="ml-2 w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['state'] && 'text-red-dark'}`}>State</p>
                                <StateSelect className="border-2 rounded-xl font-gilroy-semibold p-4" options={states} onOptionChange={(e) => setState(e.target.value)} selectedOption={state} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Application details */}
                <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Application details</h3>
                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['requestedAmount'] ? 'text-red-dark' : ''}`}>Requested amount</p>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">
                                    $
                                </span>
                            </div>
                            <input type="text" name="requestedAmount" className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['requestedAmount'] ? 'border-red-dark' : ''}`} placeholder="0.00" value={`${requestedAmount}`} onChange={(e) => { setRequestedAmount(e.target.value); handleChange(e) }} />
                        </div>
                        {errors['requestedAmount'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['requestedAmount']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['startDate'] && 'text-red-dark'}`}>Start date</p>
                        <div className="w-full flex justify-start items-center">
                            <span className="w-7 pl-3 absolute" style={{ color: "#4139ff" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" style={{ marginLeft: "-0.2rem" }} viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </span>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="LL"
                                minDate={new Date()}
                                onDayChange={(date) => setStartDate(date)}
                                value={startDate}
                                dayPickerProps={{
                                    modifiers: {
                                        disabled: [
                                            {
                                                before: new Date()
                                            }
                                        ]
                                    }
                                }}
                            />
                        </div>

                    </div>
                </div>

                {/* Payment link and QR code instructions */}
                {/* <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Select payment link and QR instructions</h3>
                        <div className="flex w-full items-baseline">
                            <input type="checkbox" name="Payment Link via email or SMS" checked={paymentLink} onChange={() => setPaymentLink(!paymentLink)} />
                            <p className="pl-2 font-gilroy-regular">Send payment link via email & sms to property owner</p>
                        </div>
                        <div className="flex mt-2 w-full items-baseline">
                            <input type="checkbox" name="QR Code" checked={QRCheck} onChange={() => setQRCheck(!QRCheck)} />
                            <p className="pl-2 font-gilroy-regular">Send QR code to <span className="font-gilroy-regular text-purple-light">bhanu@elepay.com.au</span></p>
                        </div>
                    </div>
                </div> */}
                {!disableSubmitButton && <div className="mt-10 flex self-center w-80">
                    <button type="submit" className="bg-purple-light p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold" disabled={disableSubmitButton}>
                        <motion.button whileHover={{ scale: 1.1 }}>
                            Submit
                        </motion.button>
                    </button>
                </div>}
                {disableSubmitButton && <div className="mt-10 flex self-center w-80" data-title=".dot-pulse"><button type="button" className="w-40 opacity-50 bg-purple-light flex items-baseline p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold" disabled>Submitting<div className="dot-pulse ml-5"></div></button></div>}
            </div>
        </form>
    </div>)
}

export default PayLaterOptions107NoAuth;