import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';
import CoronisLogo from '../../assets/images/CoronisLogo.png';

const CoronisChooseProduct = ({location, match}) => {
    const [showAllProducts, setShowAllProducts] = useState([]);
    const {
        form_codes,
        products,
        authenticated,
        partner_code,
        partner_name,
        firstname,
        lastname,
        user_id,
        forms } = location.state || {forms: []};

    const history = useHistory();

    let productInfo = [
    {
        form_code: 'cor107nl',
        product_code: 'ELEPAY107',
        id: '107',
        name: '107',
        // noPayFor: 15,
    },
    {
        form_code: 'cor107nlr',
        product_code: 'ELEPAY107',
        id: '107',
        name: '107',
        // noPayFor: 15,
    },
    {
        form_code: 'cor60nlr',
        product_code: 'ELEPAY60',
        id: '60',
        name: '60'
    },
    {
        form_code: 'cor90nlr',
        product_code: 'ELEPAY90',
        id: '90',
        name: '90'
    },
    {
        form_code: 'cor120nlr',
        product_code: 'ELEPAY120',
        id: '120',
        name: '120'
    },
    {
        form_code: 'cor6090nlr',
        product_code: 'ELEPAY60-ELEPAY90',
        id: '60-90',
        name: '60/90',
        // noPayFor: 45,
    },
    {
        form_code: 'cor6090nlr',
        product_code: 'ELEPAY90-ELEPAY60',
        id: '60-90',
        name: '60/90',
        // noPayFor: 45,
    },
    {
        form_code: 'cor60120nlr',
        product_code: 'ELEPAY60-ELEPAY120',
        id: '60-120',
        name: '60/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor60120nlr',
        product_code: 'ELEPAY120-ELEPAY60',
        id: '60-120',
        name: '60/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor90120nlr',
        product_code: 'ELEPAY90-ELEPAY120',
        id: '90-120',
        name: '90/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor90120nlr',
        product_code: 'ELEPAY120-ELEPAY90',
        id: '90-120',
        name: '90/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor6090120nlr',
        product_code: 'ELEPAY60-ELEPAY90-ELEPAY120',
        id: '60-90-120',
        name: '60/90/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor6090120nlr',
        product_code: 'ELEPAY90-ELEPAY120-ELEPAY60',
        id: '60-90-120',
        name: '60/90/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor6090120nlr',
        product_code: 'ELEPAY120-ELEPAY60-ELEPAY90',
        id: '60-90-120',
        name: '60/90/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor6090120nlr',
        product_code: 'ELEPAY120-ELEPAY90-ELEPAY60',
        id: '60-90-120',
        name: '60/90/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor6090120nlr',
        product_code: 'ELEPAY60-ELEPAY120-ELEPAY90',
        id: '60-90-120',
        name: '60/90/120',
        // noPayFor: 45,
    },
    {
        form_code: 'cor6090120nlr',
        product_code: 'ELEPAY90-ELEPAY60-ELEPAY120',
        id: '60-90-120',
        name: '60/90/120',
        // noPayFor: 45,
    },
    // ,{
    //     id: '365',
    //     name: '365',
    //     noPayFor: 75,
    // }
];

    const handleProduct = (formCode) => {
        history.push({
            pathname: `/${partner_code}/lead/${formCode}`,
                state: {
                    authenticated,
                    partner_code,
                    partner_name,
                    firstname,
                    lastname,
                    user_id,
                    forms
                },
        })
    }

    // Render once when the component loads
    useEffect(() => {
        const singleProduct = [];
        const product6090120 = [];
        products.map((product) => {
            product.length === 1 && singleProduct.push(product[0].name.replace(/\s/g, ''));
            product.length > 1 && product.map(prod => (
                product6090120.push(prod.name.replace(/\s/g, ''))
            ))
    
        const allProducts = [...singleProduct, _.join(product6090120, '-')];
        setShowAllProducts(allProducts);
        });
    }, []);

    return (
        <div>
            <div className="flex justify-between flex-wrap items-center bg-purple-dark">
                <div className="p-10 flex flex-wrap lg:flex-start items-baseline bg-purple-dark">
                    <img className="flex md:pl-0 lg:pl-40" src={CoronisLogo} alt="Coronis" />
                </div>
                <img className="pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
            </div>
            <div className="flex flex-col justify-center">
                <h3 className="mt-20 p-4 items-center font-gilroy-bold leading-14 text-5xl text-center text-purple-light">Choose a product</h3>
                <div className="m-8">
                    {showAllProducts.map(product => {
                        {/* console.log('product', product); */}
                        {/* console.log('productInfo', productInfo); */}
                        const productDesc = productInfo.find(prod => prod.product_code === product);
                        console.log('productDesc', productDesc);
                        {/* console.log('productDesc', productDesc); */}

                        return <button className="w-max p-8 m-4 mb-2 border border-purple-extra-light hover:bg-purple-extra-light focus:bg-purple-light text-purple-light focus:text-white rounded-2xl" onClick={() => handleProduct(productDesc.form_code)}>
                            <small className="text-md font-gilroy-regular">days</small>
                            <h3 className="text-4xl font-gilroy-semibold">{productDesc.name}</h3>
                            {/* <h3>No pay for {product.noPayFor}</h3> */}
                        </button>
                    })}
                </div>
            </div>
        </div>
    );
    
}

export default CoronisChooseProduct;