import React from "react";

export default function Modal({ details, showModal, amount, onCloseModal, onHandleCard, requestedAmount }) {

    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex h-full overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-5xl h-full">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-gilroy-semibold text-purple-light">
                                        Debit Card / Direct Debit
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={onCloseModal}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="overflow-y-scroll">
                                    <div className="flex flex-col">
                                        <h3 className="pl-6 pt-4 font-gilroy-semibold text-purple-dark text-left text-2xl">Direct Debit Request Agreement Form</h3>
                                        <div className="flex flex-row flex-wrap">
                                            <div className="relative p-6 flex flex-col items-start flex-wrap">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">First Name</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.firstname} disabled />
                                            </div>
                                            <div className="relative p-6 flex flex-col items-start flex-wrap">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Middle Name</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={``} disabled />
                                            </div>
                                            <div className="relative p-6 flex flex-col items-start flex-wrap">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Last Name</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.lastname} disabled />
                                            </div>
                                        </div>
                                        <div className="relative px-6 flex flex-col w-full flex-wrap">
                                            <p className="font-gilroy-regular text-left text-md text-black-dark">Address</p>
                                            <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.address.fullAddress} disabled />
                                        </div>
                                        <div className="flex flex-row mb-4 flex-wrap">
                                            <div className="relative pl-6 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Phone</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.phone} disabled />
                                            </div>
                                            <div className="relative pl-6 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Email</p>
                                                <input type="text" name="propertyAgency" className="p-4 w-60 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.email} disabled />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="flex flex-col">
                                        <h3 className="pl-6 pt-4 font-gilroy-semibold text-purple-dark text-left text-2xl">Direct Debit Details</h3>
                                        <div className="flex flex-row flex-wrap">
                                            <div className="relative p-6 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Recurring or Once Only Debit:</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={`Once Only`} disabled />
                                            </div>
                                            <div className="relative p-6 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Payment Frequency</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={`Single`} disabled />
                                            </div>
                                        </div>
                                        <div className="flex flex-row flex-wrap">
                                            <div className="relative p-6 pt-0 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Start Date</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={details.startdate} disabled />
                                            </div>
                                            <div className="relative p-6 pt-0 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Duration</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={`1 Payment`} disabled />
                                            </div>
                                        </div>
                                        <div className="flex flex-row mb-4 flex-wrap">
                                            <div className="relative pl-6 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Debit Amount</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={`${formatCurrency(amount)}`} disabled />
                                            </div>
                                            <div className="relative pl-6 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Transaction Fee</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={`$2.95`} disabled />
                                            </div>
                                            <div className="relative pl-6 flex flex-col items-start">
                                                <p className="mt-4 font-gilroy-regular text-left text-md text-black-dark">Failed Payment Fee</p>
                                                <input type="text" name="propertyAgency" className="p-4 h-12 mt-1 border-2 border-purple-light bg-purple-extra-light rounded-xl font-gilroy-semibold" value={`$20.00`} disabled />
                                            </div>
                                        </div>
                                        <hr />
                                        <p className="text-sm text-black-dark font-gilroy-regular text-left p-6">I/We authorise FSoft Pty. Ltd. ACN: 610 678 209 (BECS ID 503761) to debit my/our account from the Financial Institution to be specified through the Bulk Electronic Clearing System (BECS) in accordance to the above Debit Arrangement and as per FSofts DDR Terms and Conditions (v1.0) provided.</p>
                                        <p className="text-sm text-black-dark font-gilroy-regular text-left p-6 pt-2">This Authorisation is to remain in force in accordance to the Terms and Conditions (v1.0) on this provided on this Agreement. I/We acknowledge that my/our personal information will be collected, used, held and disclosed in accordance with the FSoft Privacy Policy (https://www.bizcore.com.au/privacy-policy)</p>

                                        <div>
                                            <h3 className="pl-6 pt-4 font-gilroy-semibold text-purple-dark text-left text-2xl">Direct Debit Service Agreement Terms and Conditions</h3>
                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">1. Definitions:</h4>

                                                <br />
                                                <div className="text-purple-light">In this agreement:-</div>
                                                <div className="np">Account means the account held at your financial institution from which we are authorised to arrange for funds to be debited.</div>

                                                <div className="np">Agreement means this Direct Debit Request Service Agreement between you and us.</div>

                                                <div className="np">Bulk Electronic Clearing System (BECS) means the system which is used for such payments as internet banking transactions and direct debit and direct credit.</div>

                                                <div className="np">Business means {Text.SubscriberTradingName}, ABN: {Text.SubscriberABN}.</div>

                                                <div className="np">Banking day means a day other than a Saturday or a Sunday or a public holiday listed throughout Australia.</div>

                                                <div className="np">Debit payment means a particular transaction where a debit is made.</div>

                                                <div className="np">FSOFT means FSoft Pty Ltd ABN 85 610 678 209 Bulk Electronic Clearing System (BECS) User ID 503761</div>

                                                <div className="np">You means the person(s) who signed the direct debit request.</div>

                                                <div className="np">Your financial institution means the financial institution where you hold the account that you have authorised us to debit.</div>
                                            </div>
                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">2. Agreement</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">2.1.</div><div className="pl-2">By signing the Agreement, you authorise FSOFT to debit your nominated account on behalf of the Business for the amount and the frequency as specified in your direct debit form.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">2.2.</div><div className="pl-2"> The payments are debited through the Bulk Electronic Clearing System (BECS) from your nominated account.</div>
                                                </div>

                                                <div className="flex">
                                                    <div className="nl">2.3.</div><div className="pl-2"> If the payment due date falls on a weekend or public holiday the transaction will be processed on either the banking day before or the next banking day. If you are uncertain of the day the transaction will be processed, please contact the Business.</div>
                                                </div>

                                                <div className="flex">
                                                    <div className="nl">2.4.</div><div className="pl-2"> In the event of a failed payment, you will be liable for any fees or charges incurred by FSOFT and any fees charged to you by your bank. FSOFT may retrieve the funds from your account as well as any other associated fees incurred by them.</div>
                                                </div>

                                                <div className="flex">
                                                    <div className="nl">2.5.</div><div className="pl-2"> FSoft may terminate its direct debit facility provided to you at any time by giving you 7 days' notice in writing.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">3. Fees</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">3.1.</div><div className="pl-2"> You acknowledge and agree that in addition to the payment due to be debited, FSOFT will deduct an amount for the fee payable to them for the provision of the direct debit service.</div>
                                                </div>

                                                <div className="flex">
                                                    <div className="nl">3.2.</div><div className="pl-2"> At the time of entering into this agreement, the fee charged by FSOFT for each attempt at debiting your account, whether successful or not, is up to {Text.DirectDebitFee}. Should the amount of this fee change, FSOFT or the Business will notify you in writing no less than 14 days before such change is made to enable you to make other payment arrangements if you wish.</div>
                                                </div>

                                                <div className="flex">
                                                    <div className="nl">3.3.</div><div className="pl-2"> If FSOFT become liable to pay any GST in respect to the payment collected by them pursuant to this agreement, then you agree to allow us to add the amount of the GST to any future payment to be taken by us from your account.</div>
                                                </div>

                                                <div className="flex">
                                                    <div className="nl">3.4.</div>
                                                    <div className="flex flex-col">
                                                        <div className="pl-2"> If you do not have enough funds in your Account for a Debit payment, FSOFT may:</div>
                                                        <div className="flex flex-col pl-2">
                                                            <div className="flex">
                                                                <div className="na">3.4.1.</div><div className="pl-2"> charge you a failed payment fee of up to {Text.PayDishonourFee} for each unsuccessful Debit payment;</div>
                                                            </div>
                                                            <div className="flex">
                                                                <div className="na">3.4.2.</div><div className="pl-2"> charge you the failed payment fee even though you have also been charged a fee by your financial institution; and</div>
                                                            </div>
                                                            <div className="flex">
                                                                <div className="na">3.4.3.</div><div className="pl-2"> implement re-debit measures to recover any outstanding amounts.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">4. Variation of the Agreement by you</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">4.1.</div><div className="pl-2"> If you wish to vary any of the terms of this agreement you should contact the Business via phone or email.</div>
                                                </div>

                                                <div className="flex">
                                                    <div className="nl">4.2.</div><div className="pl-2"> You must notify the Business if you wish to vary the amount, cancel the debit or change the due date of the debit.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">4.3.</div>
                                                    <div className="flex flex-col">
                                                        <div className="pl-2">If you wish to terminate this Agreement:</div>
                                                        <div className="flex flex-col pl-2">
                                                            <div className="flex">
                                                                <div className="na">4.3.1.</div><div className="pl-2"> you must first attempt to terminate the arrangement with the Business by providing the Business with notice of the termination in writing; and</div>
                                                            </div>
                                                            <div className="flex">
                                                                <div className="na">4.3.2.</div><div className="pl-2"> you may then notify your financial institution of the termination of the arrangement with the business in accordance with your financial institution’s procedures.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">4.4.</div><div className="pl-2"> Only in the event that the termination of the arrangement is unsuccessful after completing the steps in clauses 4.3.1 and 4.3.2, you may request FSoft terminate this Agreement by providing notice in writing. The notice must confirm that you have completed the steps in clauses 4.3.1 and 4.3.2 by providing FSoft with evidence of your attempts and your attempts to terminate have been unsuccessful.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">4.5.</div><div className="pl-2"> If you cancel the direct debit service directly with your financial institution contrary to clause 4.3 of this Agreement and notice is not provided to the Business, FSoft will charge you an administration fee of $35.00 if you later re-enter into that same direct debit service agreement.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">5. Variation of the Agreement by us</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">5.1.</div><div className="pl-2"> FSOFT may change any details of the Agreement and the terms and conditions by giving you at least 14 days’ notice in writing before such change takes effect.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">5.2.</div><div className="pl-2"> You acknowledge that FSOFT may change the fee set out in clause 3.2, by giving you 14 days' notice in writing.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">5.3.</div><div className="pl-2"> You further acknowledge that FSOFT may cancel the direct debit request should circumstances require it.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">6. The services</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">6.1.</div><div className="pl-2"> The services provided by us are administrative to this Agreement in order to assist you in complying with your obligations and do not extend to the provision of any other services or benefits.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">7. Your obligations</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">7.1.</div><div className="pl-2"> It is your obligation to make sure that there are sufficient cleared funds available in your nominated account to allow the payment to be made in accordance with the direct debit request.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">7.2.</div><div className="pl-2"> It is your obligation to verify your nominated account details with your financial institution and to confirm if your account is eligible for direct debit processing prior to providing the account details to FSOFT.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">7.3.</div><div className="pl-2"> It is your obligation to ensure that you have the authority to authorise direct debit payments to be taken from your nominated account.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">7.4.</div><div className="pl-2"> You must notify the Business if you close your nominated account or if you make any changes to your accounts which may affect the direct debit payment.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">7.5.</div><div className="pl-2"> It is your responsibility to check your bank statements to ensure the correct amounts are being debited as requested.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">7.6.</div><div className="pl-2"> You should ensure that the cleared funds are available at the beginning of the day on which the payment is due as the payment may be debited from your nominated account at any time during that day, or any time over the following 3 days. You should not assume that the payment has been debited from your account.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">7.7.</div><div className="pl-2"> If the Agreement is cancelled for any reason it is your responsibility to arrange an alternative payment method with the Business.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">7.8.</div><div className="pl-2"> If you believe that you will not be in a position to have sufficient clear funds available in your account, you should immediately contact the Business to discuss the matter.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">8. Acknowledgements</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">8.1.</div><div className="pl-2"> You acknowledge that FSOFT is a direct debit Agent for the Business which is supplying you the goods and or services which you have requested. FSOFT does not provide any goods and services to you whatsoever and will not be liable for any of the goods and services which the payments are being made for.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">9. Dispute</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">9.1</div><div className="pl-2"> FSOFT have an internal dispute resolution procedure (IDRP) in place to ensure customer satisfaction. If there are any issues or complaints you may contact FSOFT to resolve any issue which may arise.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">9.2</div><div className="pl-2"> If an unauthorised or mistaken transaction has been made to your account, you should notify FSOFT on Phone as soon as you become aware of the transaction.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">9.3</div><div className="pl-2"> FSOFT will request that you provide the dispute in writing and upon completing their investigations of the dispute, FSOFT will provide you with their decision, the reasons for the decision and any supporting documentation for the decision.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">9.4</div><div className="pl-2"> If as a result of the investigations FSOFT determine that a mistake has been made when debiting your account an adjustment will be made to your account reversing the error made.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">9.5</div><div className="pl-2"> If any issue or complaint has not been resolved to your satisfaction you may contact our external dispute resolution centre the Australia Financial Complaints Authority (AFCA) on 1800 931 678.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">10. Confidentiality</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">10.1.</div><div className="pl-2"> We will, subject to the following clause, keep any information (including your account details) confidential;</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">10.2.</div><div className="pl-2"> We will only disclose that information as we are required to by law, for the purposes of this agreement or as authorised (expressly or impliedly) by you.</div>
                                                </div>
                                            </div>

                                            <div className="text-left p-6 text-black-dark font-gilroy-regular text-md">
                                                <h4 className="text-purple-dark">11. No Liability</h4>
                                                <br />
                                                <div className="flex">
                                                    <div className="nl">11.1.</div><div className="pl-2"> It is your sole obligation to ensure the information you provide FSoft is correct, as FSoft will accept no responsibility or liability for debits to incorrect amounts, and any dishonour fees resulting from such incorrect amounts.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">11.2.</div><div className="pl-2"> To the extent permitted by law, we are not liable for any loss or damage you suffer as a result of using FSoft’s direct debit facility or any delay, omission or failure in respect of any debit (including but not limited to technical/system failure or third-party failure).</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">11.3.</div><div className="pl-2"> If we debit your account under a direct debit arrangement, we are not acting as agent for you, and we are not liable for any loss or damage to you.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">11.4.</div><div className="pl-2"> You will indemnify and keep us indemnified fully in respect of any claims, actions, losses, damages or expenses howsoever arising and by whomsoever caused, made against or incurred by us in respect of matters resulting from your use of the direct debit facility.</div>
                                                </div>
                                                <div className="flex">
                                                    <div className="nl">11.5.</div><div className="pl-2"> FSoft is not liable for any direct debit made in good faith notwithstanding your death or bankruptcy or the revocation of this request by any other means until notice of your death or bankruptcy or of such revocation is received by FSoft</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end border-t border-solid border-blueGray-200 p-6 mb-16 lg:mb-0 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-gilroy-bold"
                                        type="button"
                                        onClick={onCloseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-purple-light text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 font-gilroy-bold"
                                        type="button"
                                        onClick={onHandleCard}
                                    >
                                        I Accept and Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}