import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Landing from "./components/Landing";
import Options from "./components/Options/";
import NotFound from "./components/NotFound";
import Modal from "./coronis/components/Modal";
import Login from "./coronis/components/Login";
import ResetPassword from "./coronis/components/ResetPassword";

// Coronis Imports
import Coronis from "./coronis/PaymentHub";
import CustomerLanding from "./coronis/components/CustomerLanding";
import CoronisChooseProduct from "./coronis/components/CoronisChooseProduct";
import Lead107 from "./coronis/components/Lead107";
import Pay_107 from "./coronis/Pay_107_New";
import Pay_120 from "./ray_white_aspley_2/Pay_120";
import PaymentOptions from "./coronis/components/PaymentOptions";
import PaymentOptions120Lead from "./coronis/components/PaymentOptions120Lead";
import ThankYou from "./coronis/components/ThankYou";
import PaymentThankYou from "./coronis/components/PaymentThankYou";
import PayResult from "./coronis/components/PayResult";
import PayFromRentThankYou from "./coronis/components/PayFromRentThankYou";
import PayLaterPayNow107 from "./coronis/components/PayLaterPayNow107";

// RayWhite Imports
import RayWhite from "./ray_white_aspley_2/PaymentHub";
import PayNowPayLater from "./ray_white_aspley_2/components/PayNowPayLater";
import PayNowOnly from "./ray_white_aspley_2/components/PayNowOnly";
import PayLaterOnly from "./ray_white_aspley_2/components/PayLaterOnly";
import PayLaterElepayDirect from "./ray_white_aspley_2/components/PayLaterElepayDirect";
import RayWhiteCustomerLanding from "./ray_white_aspley_2/components/CustomerLanding";
import Customer107Landing from "./ray_white_aspley_2/components/Customer107Landing";
import RayWhiteChooseProduct from "./ray_white_aspley_2/components/RayWhiteChooseProduct2";
import RayWhiteLead107 from "./ray_white_aspley_2/components/Lead107";
import RayWhitePay_107 from "./ray_white_aspley_2/Paylater_107";
import RayWhitePay_107_Calc from "./ray_white_aspley_2/Pay_107_Calc";
import Elepay107CalculatorNormal from "./ray_white_aspley_2/Pay_107_CalcNormal";
import RayWhitePaymentOptions from "./ray_white_aspley_2/components/PaymentOptions";
import PayLaterOptions107 from "./ray_white_aspley_2/components/PayLaterOptions107";
import PayLaterOptions107NoAuth from "./ray_white_aspley_2/components/PayLaterOptions107NoAuth";

import ElepayCalculator from "./ray_white_aspley_2/calculator_components";
import ElepayCalculatorNormal from "./ray_white_aspley_2/calculator_components_normal";
import WelcomeBack from "./ray_white_aspley_2/welcome_back_components";
import WelcomeBack107 from "./ray_white_aspley_2/welcome_back_107";
import Custom404 from "./ray_white_aspley_2/404";

// Bowerbird Projects
import Pay180 from "./bowerbird/Pay180";

// Partner Imports
import Partner from "./partner";
import PartnerLead107 from "./partner/leads/PartnerLead107";
import PartnerLead60_90_120 from "./partner/leads/PartnerLead60_90_120";
import PartnerLead365 from "./partner/leads/PartnerLead365";

import "./App.css";
import "./index.css";
import "react-day-picker/lib/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Pay_6M_ni from "./ray_white_aspley_2/Pay_6M_ni";

function App() {
  return (
    <Router>
      <CookiesProvider>
        <div className="App">
          <Modal />

          {/* <Header /> */}
          <Switch>
            <Route exact path="/test" component={Landing} />
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/coronis/pay/cor6090nlr/:leadId"
              component={Coronis}
            />
            <Route
              exact
              path="/coronis/pay/cor6090120nlr/:leadId"
              component={Coronis}
            />
            <Route
              exact
              path="/coronis/choose-product"
              component={CoronisChooseProduct}
            />
            <Route exact path="/:partner_code/pay/cor107nl/:leadId" component={PayLaterPayNow107} />            
            <Route
              exact
              path="/:partner_code/pay/gen121_rlc/:leadId"
              component={Pay_120}
            />
            <Route
              exact
              path="/:partner_code/calculate/121_rlc_calc/:leadId"
              component={ElepayCalculator}
            />
            <Route
              exact
              path="/:partner_code/calculate/121_calc/:leadId"
              component={ElepayCalculator}
            />
            <Route
              exact
              path="/:partner_code/pay/gen12ni_rlc/:leadId"
              component={Pay_120}
            />
            <Route
              exact
              path="/:partner_code/pay/renosell12ni_rlc/:leadId"
              component={Pay_120}
            />
            <Route
              exact
              path="/:partner_code/pay/testing"
              component={Pay_6M_ni}
            />
            <Route exact path="/coronis/lead/cor107nl" component={Lead107} />
            <Route path="/:partner_code/lead/cor107nl/:token" component={Lead107} />
            <Route exact path="/coronis/start" component={CustomerLanding} />
            <Route
              exact
              path="/coronis/lead/cor6090nlr"
              component={PaymentOptions}
            />{" "}
            {/* Pay from rent as well */}
            <Route
              path="/coronis/lead/cor6090nlr/:token"
              component={PaymentOptions}
            />{" "}
            {/* Pay from rent as well */}
            <Route
              exact
              path="/coronis/lead/cor6090120nlr"
              component={PaymentOptions120Lead}
            />{" "}
            {/* Pay from rent as well */}
            <Route
              path="/coronis/lead/cor6090120nlr/:token"
              component={PaymentOptions120Lead}
            />{" "}                   
            <Route path='/:partner_code/stylist6090120/start'  
              render={({ match }) => 
              { 
                window.location.replace(process.env.REACT_APP_STATIC_PREFIX + '/stylist/6090120/customer-form.html?partner=' + match.params.partner_code); 
                return null;
              }} />               
            <Route
              path="/:partner_code/choose-product/:token"
              component={RayWhiteChooseProduct}
            />
            <Route
              path="/:partner_code/choose-product"
              component={RayWhiteChooseProduct}
            />
            <Route
              path="/:partner_code/pay/rwa107nl/:leadId"
              component={RayWhitePay_107}
            />
            <Route
              exact
              path="/:partner_code/pay/rwa6090120nl/:leadId"
              component={RayWhite}
            />
            <Route
              exact
              path="/:partner_code/lead/rwa107nl"
              component={RayWhiteLead107}
            />
            <Route
              exact
              path="/:partner_code/lead/bowerbird180"
              component={RayWhiteLead107}
            />

            <Route path="/:partner_code/lead/bowerbird180/:token" 
              render={({ match }) => 
              { 
                const query = window.location.search;
                let env = (process.env.REACT_APP_WIX_PREFIX_2.indexOf('stage') > 0) ? "stage/" : ""; 
                let baseurl = process.env.REACT_APP_WIX_PREFIX_2.replace('/stage','');                
                window.location.replace(`${baseurl}/partner-lead/${env}${match.params.partner_code}/bowerbird180/${match.params.token}${query}`); 
                return null;
              }} />  

            <Route
              exact
              path="/:partner_code/lead/stylist180/:token"
              component={RayWhiteLead107}
            />
            <Route
              exact
              path="/:partner_code/lead/gen12ni_rlc"
              component={RayWhiteLead107}
            />
            <Route
              exact
              path="/:partner_code/lead/gen12ni_rlc/:token"
              component={RayWhiteLead107}
            />
            <Route
              exact
              path="/:partner_code/lead/gen121_rlc/:token"
              component={RayWhiteLead107}
            />
            <Route
              path="/:partner_code/lead/rwa107nl/:token"
              component={RayWhiteLead107}
            />
            <Route
              exact
              path="/:partner_code/lead/rwa6090120nl"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:partner_code/lead/rwa6090120nl/:token"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/paynow/:token"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/paynow"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/renosell121rlc"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/renosell120/:token"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/renosell120"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/paylater_paynow/:token"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/stylist6090120/:token"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/paylater_paynow"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:partner_code/pay/paynow/:leadId"
              component={RayWhite}
            />{" "}
            {/* // Check this route once (Lead or Pay Hub) */}
            <Route path="/:product_code/pay/paylater_paynow/:leadId" component={RayWhite} />
            <Route
              path="/:product_code/lead/paylater/:token"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/paylater"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/pay/paylater/:leadId"
              component={RayWhite}
            />
            <Route
              path="/:product_code/pay/renosell120/:leadId"
              component={RayWhite}
            />
            <Route
              path="/:product_code/lead/gen107later/:token"
              component={PayLaterOptions107}
            />
            <Route
              path="/:product_code/lead/gen107later"
              component={PayLaterOptions107}
            />
            <Route
              path="/fast/:fastrack_id/lead"
              component={PayLaterOptions107NoAuth}
            />
            <Route
              path="/:partner_code/calculate/gen107later/:leadId"
              component={RayWhitePay_107_Calc}
            />
            <Route
              path="/:partner_code/calculate/gen107calc/:leadId"
              component={RayWhitePay_107_Calc}
            />
            <Route
              path="/:partner_code/pay/gen107later/:leadId"
              component={RayWhitePay_107}
            />
            <Route
              path="/:product_code/lead/paylater_cal/:token"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:product_code/lead/paylater_cal"
              component={RayWhitePaymentOptions}
            />
            <Route
              path="/:partner_code/calculate/paylater_cal/:leadId"
              component={ElepayCalculator}
            />
            <Route
              exact
              path="/calculate/gen107calc"
              component={Elepay107CalculatorNormal}
            />
            <Route
              path="/calculate/:form_code"
              component={ElepayCalculatorNormal}
            />
            <Route path="/welcome-back/:leadId" component={WelcomeBack} />
            <Route
              path="/:partner_code/:form_code/fast"
              component={WelcomeBack107}
            />
            <Route
              path="/fast/:fastrack_id/:form_code"
              component={WelcomeBack107}
            />
            <Route path="/404" component={Custom404} />
            <Route
              path="/:partner_code/gen107later/start"
              component={Customer107Landing}
            />
            <Route path="/:partner_code/(paylater_paynow)/start" component={PayNowPayLater} />
            <Route path="/:partner_code/(paylater_paynow)/calc" component={PayNowPayLater} />
            <Route path="/:partner_code/(paylater)/calc" component={PayLaterOnly} />
            <Route path="/:partner_code/(paylater|paylater_cal)/start" component={PayLaterOnly} />
            <Route path="/:partner_code/paylater_elepay_direct/calc" component={PayLaterElepayDirect} />
            <Route path="/:partner_code/(paynow)/calc" component={PayNowOnly} />
            <Route path="/:partner_code/(paynow)/start" component={PayNowOnly} />
            {/* <Route path="/:partner_code/bowerbird180/start" component={RayWhiteCustomerLanding} /> */}
            <Route path="/:partner_code/:form_code/start" component={RayWhiteCustomerLanding} />
            <Route path="/:partner_code/pay/:form_code/start" component={RayWhiteCustomerLanding} />
            <Route path="/:partner_code/:form_code/calc" component={PayNowPayLater} />

            <Route path="/test/options" component={Options} />
            <Route path="/:partner_code/pay/bowerbird180/:leadId" component={Pay180} />  
            <Route path="/:partner_code/pay/gen180/:leadId" component={Pay180} />             
            
            <Route path="/:partner_code/pay/stylist180/:leadId" 
              render={({ match }) => 
              { 
                window.location.replace(process.env.REACT_APP_WIX_PREFIX + '/lead-pay-sympatico-180/' + match.params.leadId); 
                return null;
              }} /> 

            <Route
              path="/:partner_code/lead/:form_code/:token"
              component={RayWhitePaymentOptions}
            />     

            <Route path="/:partner_code/pay/121pnpl/:leadId" 
              render={({ match }) => 
              { 
                const query = window.location.search;
                let env = (process.env.REACT_APP_WIX_PREFIX_2.indexOf('stage') > 0) ? "stage/" : ""; 
                let baseurl = process.env.REACT_APP_WIX_PREFIX_2.replace('/stage','');                
                window.location.replace(`${baseurl}/bigginscott120/${env}${match.params.leadId}${query}`); 
                return null;
              }} /> 
            
            {/* Partner Routes */}
            <Route exact path="/partner" component={Partner} />
            <Route path="/partner/leads/107" component={PartnerLead107} />
            <Route
              path="/partner/leads/60-90-120"
              component={PartnerLead60_90_120}
            />
            <Route path="/partner/leads/365" component={PartnerLead365} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/thank-you" component={ThankYou} />
            <Route path="/payment-thank-you" component={PaymentThankYou} />
            <Route path="/pay-result" component={PayResult} />
            <Route
              path="/authorise-thank-you"
              component={PayFromRentThankYou}
            />
          </Switch>
          {/* <Footer /> */}
        </div>
      </CookiesProvider>
    </Router>
  );
}

export default App;
