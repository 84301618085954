import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import { motion } from "framer-motion";
import { useHistory } from 'react-router';
import axios from 'axios';
import Modal from '../components/Modal';
import Tabs from "./Tabs";
import OptionCard from './PayNowOptionCard';
import PaymentModal from '../components/PaymentModal';

const PayNow = ({ details, options, leadId, onHandleBlur, dayPlanAmount60, dayPlanAmount90, dayPlanAmount120, showBlur, requestedAmount }) => {
    const payNowTabOptions = [{index: 0, option: "Credit Card", optionSelected: "a"}, {index: 1, option: "Direct Debit", optionSelected: "b"}];
    const [openTab, setOpenTab] = useState(payNowTabOptions[0]);
    const [showModal, setShowModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [transactionAgreementId, setTransactionAgreementId] = useState("");
    const [transactionReference, setTransactionReference] = useState("");
    const [sourceCreationUrl, setSourceCreationUrl] = useState("");

    const history = useHistory();

    const numFormatter = (elm) => {
        return parseFloat(elm.replace(/,/g, '')).toFixed(2);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(showPaymentModal) {
                axios.get(`${process.env.REACT_APP_ENV_PREFIX}/bizcore/checkpayment/${transactionAgreementId}`)
                    .then(function (response) {
                        // handle success
                        const { transaction } = response.data;
                        transaction && clearInterval(interval);
                        if (transaction.transactionStatusId === 1 || transaction.transactionStatusId === 3) {
                            setShowPaymentModal(false);
                            history.push({
                                pathname: "/payment-thank-you",
                                state: {
                                    firstName: details.firstname,
                                    transactionReference: transaction.transactionReference,
                                    transactionDateFormatted: transaction.transactionDateFormatted,
                                    transactionSourceType: transaction.transactionSourceType,
                                    totalAmount: transaction.totalAmountFormatted
                                },
                            })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            } else {
                return null;
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [transactionAgreementId, transactionReference, showPaymentModal]);

    const redirectToSourceUrl = (url) => {
        window.location.href=`${url}`;
    }

    const handleCard = (paymentType) => {
        paymentType != "2" && onHandleBlur();
        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/bizcore/createAgreement`, {
            lead_id: leadId,
            givenName: details.firstname,
            familyName: details.lastname,
            email: details.email,
            mobile: details.phone,
            amount: numFormatter(requestedAmount.amount.substring(requestedAmount.amount.indexOf('$') + 1)),
            paymentType: paymentType
        })
            .then(function (response) {
                const { transactionAgreementId, transactionAgreementReference, sourceCreationUrl } = response.data;
                setTransactionAgreementId(transactionAgreementId);
                setTransactionReference(transactionAgreementReference);
                setSourceCreationUrl(sourceCreationUrl);
                paymentType == "2" ? redirectToSourceUrl(sourceCreationUrl) : setShowPaymentModal(true);
                // setShowPaymentModal(true);
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    return (<div>
        {showModal && <Modal details={details} requestedAmount={requestedAmount} showModal={showModal} onCloseModal={() => setShowModal(false)} onHandleCard={() => { setShowModal(false); handleCard("0"); }} />}
        {showPaymentModal && <PaymentModal showPaymentModal={showPaymentModal} url={sourceCreationUrl} />}
        <div className="m-0 pb-12 md:justify-center lg:flex-start lg:flex-start bg-purple-light rounded-3xl" style={{ minWidth: 0, filter: `${showBlur && 'blur(10px)'}` }}>
            <div className="flex flex-col items-center justify-between items-end pt-12">
                <p className="font-gilroy-bold w-full text-xl text-white text-center"><span><span className="text-3xl font-gilroy-semibold">Pay Now </span><span className="text-3xl font-gilroy-regular">Options</span></span></p>
                {payNowTabOptions.length > 1 && 
                    (<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                        {/* <p className="text-4xl text-purple-dark m-auto mt-20 mb-10 font-gilroy-bold">
                            Requested Amount
                        </p> */}
                        <Tabs
                            textColor={'text-purple-light'}
                            bgColor={'bg-green-light'}
                            selectedTextColor={'text-purple-light'}
                            borderColor={'border-green-light'}
                            activeWidth={32}
                            activeTabWidth="8.2rem"
                            tabBackgroundColor={'bg-purple-light'}
                            normalTabTextColor={'text-white'}
                            tabOptions={payNowTabOptions}
                            onTabChange={(option) => setOpenTab(option)}
                            selectedTab={openTab}
                        />
                        </motion.div>)
                }
            </div>
            <div className="flex justify-center">
                <div className={`px-1 flex justify-center flex-wrap`}>
                    <OptionCard
                        requestedAmount={requestedAmount}
                        selectedTab={openTab}
                        onHandlePayNow={(option) => (option === 2 && handleCard(option)) || (option === 0 && setShowModal(true))}
                    />
                </div>
            </div>
        </div>
    </div>)
}

export default PayNow;