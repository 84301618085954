import React from "react";
import ElepayLogo from '../../assets/images/elepay-logo.png';

const CalculatorHeader = () => {
  return (
        <div className="flex flex-col pb-6 xl:pb-0 xl:flex-row items-center justify-end flex-wrap items-center">
            <div className="w-full p-10 flex flex-col xl:flex-row flex-wrap justify-center xl:justify-end items-center">
                <img className="flex w-40" src={ElepayLogo} alt="Elepay" />
            </div>
        </div>
    );
};

export default CalculatorHeader;