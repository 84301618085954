import React, { useState } from 'react';
import axios from 'axios';
import Joi from 'joi-browser';
import CalculatorHeader from './CalculatorHeader';
import ProductConfirmationBox from '../components/ProductConfirmationBox';
import { fullMonthNames } from '../../constants/dateConstants';
import CalculatorPlanFields from './CalculatorPlanFields';
import CalculatorPlanOptions from './CalculatorPlanOptions';
import CalculatorHeaderImage from '../../assets/images/calc_header.png';
import ElepayPrimaryLogo from '../../assets/images/Elepay_Primary.svg';
import CalculatorPlanOptionsBackground from '../../assets/images/CalculatorPlanOptionsBackground.png';

import './style.css';

const ElepayCalculator = ({ match }) => {
    const { leadId, form_code } = match.params;

    const [details, setDetails] = useState(
        {
            "requestedamount": 0,
            "firstname": "Bhanu Prakash",
            "address": {
              "fullAddress": "242 Abbotsford Rd",
              "id": 0,
              "postcode": "4006",
              "state": "QLD",
              "streetName": "Abbotsford",
              "streetNumber": "345",
              "suburb": "Brisbane",
              "unitNumber": "2"
            },
            "phone": "",
            "purpose": "",
            "payment_methods": [],
            "disbursement_date": "",
            "options": [
              {
                "amount": 0,
                "products": [
                  {
                    "application_url": "",
                    "payments": [
                      {
                        "amount": 0,
                        "date": "2022-02-17",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-02-02",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-18",
                        "sequence": 0
                      },
                      {
                        "amount": 0,
                        "date": "2022-01-03",
                        "sequence": 0
                      }
                    ],
                    "product": {
                      "code": "",
                      "firstPayment": 0,
                      "name": "",
                      "numberOfDays": 0,
                      "numberOfPayments": 0,
                      "paymentInterval": 0
                    }
                  },
                ]
              }
            ],
            "startdate": "",
            "email": "",
            "form_code": "",
            "lastname": ""
        }
    );

    const [options, setOptions] = useState([{
        "amount": 0,
        "products": [
          {
            "application_url": "",
            "payments": [
              {
                "amount": 0,
                "date": "2022-02-17",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-02-02",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-18",
                "sequence": 0
              },
              {
                "amount": 0,
                "date": "2022-01-03",
                "sequence": 0
              }
            ],
            "product": {
              "code": "",
              "firstPayment": 0,
              "name": "",
              "numberOfDays": 0,
              "numberOfPayments": 0,
              "paymentInterval": 0
            }
          }
        ]
      }]);

    const [showPlanOptions, setShowPlanOptions] = useState(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [fundAmount, setFundAmount] = useState(0.00);
    const [startDate, setStartDate] = useState(new Date());
    // Validation Errors
    const [errors, setErrors] = useState({});

    const [requestedAmount, setRequestedAmount] = useState({ index: 0, amount: "0", option: "a", products: [] });
    const [modifyData, setModifyData] = useState(false);
    const [disableSelectButton, setDisableSelectButton] = useState(true);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [confirmBoxInfo, setConfirmBoxInfo] = useState({ product: "", typeOfPayment: "", fundsDisbursed: "", applicationUrl: ""});
    const [disbursementDate, setDisbursementDate] = useState("");
    const [purposeOfFund, setPurposeOfFund] = useState("");

    const purposeOfFunds = ["- Select Your Purpose of Funds -", "Preparing Property for Sale", "Maintenance for investment property", "Refreshing your own home", "Need a personal loan"];

    const formatDate = (date) => {
        const startDate = new Date(date);
        return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
    }

    const handleProduct = (applicationUrl) => {
        window.location.href=`${applicationUrl}`;
    }

      // Validation Schema
      const schema = {
        fundAmount: Joi.number().min(1).label("Fund amount").required(),
        startDate: Joi.date().required()
      }

      const validate = () => {
          const validateDetails = {
              fundAmount, startDate
          }
          const options = { abortEarly: false };
          const { error } = Joi.validate(validateDetails, schema, options);
          if (!error) return null;

          const errors = {};
          error.details.map(error => errors[error.path[0]] = error.message);

          return errors;
      }

    const handleCalculate = (fundAmount, startDate) => {
      // Validating upon submit
      const errors = validate();
      console.log('errors', errors);
      setErrors(errors || {});
      if (errors) return;

      setDisableSelectButton(false);
      setShowPlanOptions(true);

        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_update`, {
            // id: leadId,
            requestedamount: fundAmount,
            purpose: purposeOfFund === "- Select Your Purpose of Funds -" ? null : purposeOfFund,
            form: { code : form_code },
            startdate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2')
        })
            .then(function (response) {
                setOptions(response.data.options);
                setDisbursementDate(response.data.disbursement_date);
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    const handleModifyData = () => {
        setModifyData(true);
        setDisableSelectButton(true);
    };

  return (
      <>
      {showConfirmBox && <ProductConfirmationBox showModal={showConfirmBox} product={confirmBoxInfo.product} typeOfPayment={confirmBoxInfo.typeOfPayment} fundsDisbursed={formatDate(disbursementDate ? disbursementDate : details.disbursement_date)} onHandleProduct={() => handleProduct(confirmBoxInfo.applicationUrl)} onCloseConfirmBox={() => setShowConfirmBox(false)}  />}
        <div className="headerBG">
            <div>
                <CalculatorHeader />
            </div>
            <div className="flex flex-col px-4 xl:px-0 pb-12 items-center xl:flex-row xl:items-start justify-end">
                <div className="w-full xl:w-1/3 flex justify-center xl:justify-center">
                    <CalculatorPlanFields fundAmount={fundAmount} onChangeFundAmount={(amount) => setFundAmount(amount)} onChangeStartDate={(date) => setStartDate(date)} startDate={startDate} errors={errors} details={details} options={options} modifyData={modifyData} onModifyData={handleModifyData} purposeOfFund={purposeOfFund} purposeOfFunds={purposeOfFunds} onPurposeChange={(e) => setPurposeOfFund(e.target.value)} onHandleCalculate={handleCalculate} />
                </div>
                <div className="w-full xl:w-1/3 hidden xl:flex justify-center xl:justify-end">
                    <img src={CalculatorHeaderImage} alt="Elepay Calculator" className="w-64" />
                </div>
            </div>
        </div>
        {showPlanOptions && 
          <div className="py-12" style={{ backgroundImage: `url(${CalculatorPlanOptionsBackground})`, backgroundSize: "2160px", backgroundRepeat: "round", paddingBottom: "6rem", marginTop: "-6rem", paddingTop: "6rem"  }}>
              <div style={{ position: "relative", zIndex:"0"}}>
                  <CalculatorPlanOptions onConfirmBox={(dayPlan, easyPayments, applicationUrl) => {setShowConfirmBox(true); setConfirmBoxInfo({ product: dayPlan, typeOfPayment: easyPayments, applicationUrl: applicationUrl }) }} disableSelectButton={disableSelectButton} details={details} options={options} requestedAmount={requestedAmount} />
              </div>
          </div>
        }
        <div className="p-10 flex flex-col text-center xl:text-right xl:flex-row flex-wrap xl:justify-between items-center">
            <img className="flex w-40" src={ElepayPrimaryLogo} alt="Elepay" />
            <p className="text-sm pt-4 xl:pt-0 font-gilroy-regular">ABN: 47 634 728 591. Copyright 2022 Elepay. <br />All rights reserved.</p>
        </div>
      </>
  )
};

export default ElepayCalculator;