import React from 'react';
import { fullMonthNames } from '../../constants/dateConstants';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';

import '../style.css';

const Details = ({ details, options, variationOptions, showBlur, requestedAmount }) => {
    const { firstname, lastname, email, address, requestedamount, startdate } = details;

    const formatDate = (date) => {
        const startDate = new Date(date);
        return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
    }

    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    return (<>
        <div className="text-left xl:absolute items-center m-4 xl:m-0 xl:w-56" style={{ filter: `${showBlur && 'blur(10px)'}` }}>
            {/* <div className="hidden py-10 flex xl:flex flex-row justify-center flex-wrap bg-purple-dark border-2 border-purple-dark">
                <img className="w-40" src={ElepayLogo} alt="Elepay Logo" />
            </div> */}
            <div className="xl:flex xl:w-56 detailsContainer -z-1 xl:flex-col xl:absolute h-100 border rounded-2xl xl:rounded-none" style={{ zIndex: "-1" }}>
                <div className="bg-black-light text-center pt-3 pl-6 pr-6 xl:px-0 pb-3 w-full h-full details-in-payment-hub">
                    <h3 className="pt-6 font-gilroy-bold text-xl text-purple-dark">Your details</h3>
                    <p className="pt-2 font-gilroy-bold">{`${firstname} ${lastname}`}</p>
                    <p className="text-sm leading-1 font-gilroy-regular">{email}</p>
                    <p className="text-sm leading-1 font-gilroy-regular">{address?.unitNumber} {address?.streetNumber} {address?.streetName}</p>
                    <p className="text-sm leading-1 font-gilroy-regular">{address?.suburb}</p>
                    <p className="text-sm font-gilroy-regular">{address?.state} {address?.postcode}</p>
                    <div className="bg-bg-black-light pt-3 pb-3 w-full">
                        <h3 className="pt-2 font-gilroy-bold text-md text-black-dark">Requested Amount</h3>
                        {/* <h3 className="font-gilroy-bold text-md text-purple-light">${requestedamount}</h3> */}
                        <h3 className="font-gilroy-bold text-md text-purple-light">{variationOptions && variationOptions[0].amount ? (variationOptions ? formatCurrency(variationOptions[0].amount) : formatCurrency(0)) : (options ? formatCurrency(options[0].amount) : formatCurrency(0))}</h3>

                        <h3 className="pt-2 font-gilroy-bold text-md text-black-dark">Start Date</h3>
                        <h3 className="font-gilroy-bold text-md text-purple-light">{formatDate(startdate)}</h3>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Details;