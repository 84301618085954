import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Joi from 'joi-browser';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import CalculatorHeader from './CalculatorHeader';
import CalculatorPlanFields from './CalculatorPlanFields';
import CalculatorPlanOptions from './CalculatorPlanOptions';
import StateSelect from '../../components/common/StateSelect';
import CalculatorHeaderImage from '../../assets/images/WelBackHeaderImage.png';
import ElepayPrimaryLogo from '../../assets/images/Elepay_Primary.svg';
import WelBackFooterImage from '../../assets/images/WelBackFooterImage.png';
import WelBackPlanOptionsBg1 from '../../assets/images/WelBackPlanOptionsBg1.png';
import WelBackPlanOptionsBg2 from '../../assets/images/WelBackPlanOptionsBg2.png';
import { initTrack } from "../../helpers/tracking";

import './style_2.css';

const WelcomeBack107 = ({ location, match }) => {
    const { partner_code, form_code, fastrack_id } = match.params;
    const path = match.url.split('/');
    const history = useHistory();

    const [modifyData, setModifyData] = useState(false);
    const [disableSelectButton, setDisableSelectButton] = useState(true);
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [confirmBoxInfo, setConfirmBoxInfo] = useState({ product: "", typeOfPayment: "", fundsDisbursed: "", applicationUrl: ""});
    const [disbursementDate, setDisbursementDate] = useState("");
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [partnerName, setPartnerName] = useState("");

    // Property Owner Details
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");

    // Property Address
    const [unitNumber, setUnitNumber] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [streetName, setStreetName] = useState("");
    const [suburb, setSuburb] = useState("");
    const [postCode, setPostCode] = useState("");
    const [state, setState] = useState("QLD");
    const [formCode, setFormCode] = useState("");
    const [partnerCode, setPartnerCode] = useState(partner_code);
    const [requestedAmount, setRequestedAmount] = useState(0);
    const [startDate, setStartDate] = useState(new Date());

    const [purposeOfFund, setPurposeOfFund] = useState("Preparing Property for Sale");

    const purposeOfFunds = ["Preparing Property for Sale", "Maintenance for investment property", "Refreshing your own home", "Need a personal loan"];

    // Validation Errors
    const [errors, setErrors] = useState({});

    const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

    const referrer = location.search !== "" && location.search.split('?ref=');

    const handleModifyData = () => {
        setModifyData(true);
        setDisableSelectButton(true);
    };

    const schema = {
      // propertyManager: Joi.string().min(3).max(30).label("Property Manager").required(),
      firstName: Joi.string().min(3).max(20).label("First Name").required(),
      lastName: Joi.string().min(2).max(20).label("Last Name").required(),
      email: Joi.string().email().min(3).max(50).label("Email").required(),
      mobile: Joi.number().label("Mobile").required(),
      unitNumber: Joi.string().allow("").label("Unit Number"),
      streetNumber: Joi.string().label("Street Number").required(),
      streetName: Joi.string().label("Street Name").required(),
      suburb: Joi.string().min(3).max(20).label("Suburb").required(),
      postCode: Joi.number().label("Postcode").required(),
      state: Joi.string().min(1).max(10).label("State").required(),
      // requestedAmount: Joi.number().min(1).max(250000).label("Requested amount").required(),
      // requestedAmount2: Joi.number().min(1).max(250000).allow("").label("Requested Amount b"),
      // requestedAmount3: Joi.number().min(1).max(250000).allow("").label("Requested Amount c"),
      // startDate: Joi.date().required()
  }

  const validate = () => {
      const validateDetails = {
          firstName, lastName, email, mobile, unitNumber, streetNumber, streetName, suburb, postCode, state
      }
      const options = { abortEarly: false };
      const { error } = Joi.validate(validateDetails, schema, options);
      if (!error) return null;

      const errors = {};
      error.details.map(error => errors[error.path[0]] = error.message);

      return errors;
  }

  const validateProperty = ({ name, value }) => {
      const obj = { [name]: value };
      const schemaForProperty = { [name]: schema[name] };
      const { error } = Joi.validate(obj, schemaForProperty);
      return error ? error.details[0].message : null;
  }

  const handleChange = ({ currentTarget: input }) => {
      const errors = {};
      const errorMessage = validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];

      setErrors(errors);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validating upon submit
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    setDisableSubmitButton(true);
    axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_create?partner_code=${partnerCode}`, {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: mobile,
        form: {
            code: form_code
        },
        requestedamount: requestedAmount,
        referrer: referrer.length > 1 ? referrer[1] : undefined,
        startdate: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2'),
        address: {
            streetName: streetName,
            unitNumber: unitNumber,
            streetNumber: streetNumber,
            suburb: suburb,
            postcode: postCode,
            state: state
        },
    })
      .then(function (response) {
          const { url } = response.data;
          window.location.href = url;
      })
      .catch(function (error) {
          setDisableSubmitButton(false);
          console.log('error', error);
      });
}

    useEffect(() => {
      console.log(initTrack("WelcomeBack107", partner_code, form_code, path[3]));
      if(match.params.hasOwnProperty('fastrack_id')) {
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_qry/${fastrack_id}?type=ftpartner`)
          .then(function (response) {
            if(response.status === 400 || response.status === 401) history.push('/404');
              const { name, id, type } = response.data;
              setPartnerName(name);
              setPartnerCode(id);
            })
          .catch(function (error) {
              console.log('error', error);
              history.push('/404');
          });
      } else if(match.params.hasOwnProperty('partner_code')) {
        axios.get(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_qry/${partnerCode}?type=partner`)
          .then(function (response) {
              if(response.status === 400 || response.status === 401) history.push('/404');
              const { name, id, type } = response.data;
              setPartnerName(name);
          })
          .catch(function (error) {
              console.log('error', error);
              history.push('/404');
          });
      }
    }, []);

  return (
      <>
        <div className="headerBackground">
            <div>
                <CalculatorHeader partner_code={partnerCode} partner_name={partnerName} />
            </div>
            <div className="flex flex-col px-4 xl:px-0 pb-12 items-center xl:flex-row xl:items-start justify-between">
                <div className="w-full xl:w-1/2 flex justify-center">
                    <CalculatorPlanFields location={location} firstName={firstName} disbursementDate={disbursementDate} modifyData={modifyData} onModifyData={handleModifyData} purposeOfFund={purposeOfFund} purposeOfFunds={purposeOfFunds} onPurposeChange={(e) => setPurposeOfFund(e.target.value)} />
                </div>
                <div className="w-full xl:w-1/2 flex justify-center">
                    <img src={CalculatorHeaderImage} className="w-64 xl:w-auto" alt="Elepay Calculator" />
                </div>
            </div>
        </div>
        <div className="py-12" style={{ backgroundImage: `url(${WelBackPlanOptionsBg2})`, backgroundSize: "2160px", backgroundRepeat: "round", paddingBottom: "1rem", marginTop: "-6rem", paddingTop: "10rem"  }}>
            <div className="pt-0 pb-24" style={{ backgroundImage: `url(${WelBackPlanOptionsBg1})`, backgroundSize: "2160px", backgroundRepeat: "round", paddingBottom: "-6rem"}}>
                <CalculatorPlanOptions onConfirmBox={(dayPlan, easyPayments, applicationUrl) => {setShowConfirmBox(true); setConfirmBoxInfo({ product: dayPlan, typeOfPayment: easyPayments, applicationUrl: applicationUrl }) }} disableSelectButton={disableSelectButton}  requestedAmount={requestedAmount} />
            </div>
        </div>
        <div className="p-4">
          <div className="flex flex-col xl:flex-row justify-center items-center xl:items-start">
            <div className="w-full xl:w-1/2 flex flex-col justify-center">
              <div className="w-full flex xl:hidden justify-center">
                <img src={WelBackFooterImage} className="w-64 xl:w-auto" alt="Elepay Calculator" />
              </div>
              <div className="m-none xl:m-auto">
                <h3 className="text-3xl text-left pt-8 pb-0 font-gilroy-semibold text-purple-light">To get started, simply fill in your details below</h3>
                <form onSubmit={handleSubmit}>
                  <div>
                    {/* Property owner details */}
                    <div className="mt-20 flex flex-col">
                        <div className="flex flex-col text-left">
                            <h3 className="font-gilroy-regular text-2xl text-black-dark text-left">Personal details</h3>
                            <div className="flex flex-col xl:flex-row">
                              <div className="w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['firstName'] && 'text-red-dark'}`}>First name</p>
                                <input type="text" name="firstName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['firstName'] && 'border-red-dark'}`} value={firstName} onChange={(e) => { setFirstName(e.target.value); handleChange(e) }} />
                                {errors['firstName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['firstName']}</p>}
                              </div>
                              <div className="ml-0 xl:ml-2 w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['lastName'] && 'text-red-dark'}`}>Last name</p>
                                <input type="text" name="lastName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['lastName'] && 'border-red-dark'}`} value={lastName} onChange={(e) => { setLastName(e.target.value); handleChange(e) }} />
                                {errors['lastName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['lastName']}</p>}
                              </div>
                            </div>
                            <div className="flex flex-col xl:flex-row">
                              <div className="w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['email'] && 'text-red-dark'}`}>Email</p>
                                <input type="text" name="email" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['email'] && 'border-red-dark'}`} value={email} onChange={(e) => { setEmail(e.target.value); handleChange(e) }} />
                                {errors['email'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['email']}</p>}
                              </div>
                              <div className="ml-0 xl:ml-2 w-full">
                                <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['mobile'] && 'text-red-dark'}`}>Mobile</p>
                                <input type="text" name="mobile" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['mobile'] && 'border-red-dark'}`} value={mobile} onChange={(e) => { setMobile(e.target.value); handleChange(e) }} />
                                {errors['mobile'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['mobile']}</p>}
                              </div>
                            </div>
                        </div>
                    </div>

                    {/* Property address */}
                    <div className="mt-20 flex flex-col">
                        <div className="flex flex-col text-left">
                            <h3 className="font-gilroy-regular text-2xl text-black-dark text-left">Property details</h3>

                            <div className="flex flex-col xl:flex-row">
                                <div className="w-full">
                                    <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['unitNumber'] && 'text-red-dark'}`}>Unit Number</p>
                                    <input type="text" name="unitNumber" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['unitNumber'] && 'border-red-dark'}`} value={unitNumber} onChange={(e) => { setUnitNumber(e.target.value); handleChange(e) }} />
                                    {errors['unitNumber'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['unitNumber']}</p>}
                                </div>
                                <div className="ml-0 xl:ml-2 w-full">
                                    <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['streetNumber'] && 'text-red-dark'}`}>Street Number</p>
                                    <input type="text" name="streetNumber" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['streetNumber'] && 'border-red-dark'}`} value={streetNumber} onChange={(e) => { setStreetNumber(e.target.value); handleChange(e) }} />
                                    {errors['streetNumber'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['streetNumber']}</p>}
                                </div>
                                <div className="ml-0 xl:ml-2 w-full">
                                  <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['streetName'] && 'text-red-dark'}`}>Street Name</p>
                                  <input type="text" name="streetName" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['streetName'] && 'border-red-dark'}`} value={streetName} onChange={(e) => { setStreetName(e.target.value); handleChange(e) }} />
                                  {errors['streetName'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['streetName']}</p>}
                                </div>
                            </div>

                            <div className="flex flex-col xl:flex-row">
                                <div className="w-full">
                                  <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['suburb'] && 'text-red-dark'}`}>Suburb</p>
                                  <input type="text" name="suburb" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['suburb'] && 'border-red-dark'}`} value={suburb} onChange={(e) => { setSuburb(e.target.value); handleChange(e) }} />
                                  {errors['suburb'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['suburb']}</p>}
                                </div>
                                <div className="ml-0 xl:ml-2 w-full">
                                    <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['postCode'] && 'text-red-dark'}`}>Postcode</p>
                                    <input type="number" name="postCode" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['postCode'] && 'border-red-dark'}`} value={postCode} onChange={(e) => { setPostCode(e.target.value); handleChange(e) }} />
                                    {errors['postCode'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['postCode']}</p>}
                                </div>
                                <div className="ml-0 xl:ml-2 w-full">
                                    <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['state'] && 'text-red-dark'}`}>State</p>
                                    <StateSelect className="border-2 rounded-xl font-gilroy-semibold p-4" options={states} onOptionChange={(e) => setState(e.target.value)} selectedOption={state} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 flex">
                            <button type="submit" className="w-full xl:w-1/3 bg-purple-light p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold" style={{ fontSize: "1.5rem" }}>
                                <motion.button whileHover={{ scale: 1.1 }}>
                                    CONTINUE
                                </motion.button>
                            </button>
                        </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="w-full xl:w-1/2 hidden xl:flex justify-center">
              <img src={WelBackFooterImage} className="w-64 xl:w-auto" alt="Elepay Calculator" />
            </div> */}
          </div>
        </div>
        <div className="p-10 flex flex-col text-center xl:text-right xl:flex-row flex-wrap xl:justify-between items-center">
            <img className="flex w-40" src={ElepayPrimaryLogo} alt="Elepay" />
            <p className="text-sm pt-4 xl:pt-0 font-gilroy-regular">ABN: 47 634 728 591. <br /> @Elepay 2022. All rights reserved.</p>
        </div>

        <div className="p-16 pt-12 pb-12 flex flex-col text-left xl:flex-row flex-wrap " style={{backgroundColor: "#12026D" }}>   
          <div class="font-gilroy-bold" style={{color: "#00ffc4", fontSize: "14px"}}>IMPORTANT INFORMATION</div>           
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
            ^ By submitting this enquiry, you acknowledge that you have read <a href="https://go.elepay.com.au/static/pdf/PrivacyPolicy-ElevarePayEasyPtyLtd.pdf" target="_blank" style={{color: "#00ffc4"}} >Elepay's Privacy Policy</a> and understand that Elepay will use your personal information to contact you in relation to this enquiry and for other general marketing purposes. You can change your marketing preferences by contacting our support team on 1300 019 417 (or support@elepay.com.au) or by clicking the unsubscribe button on our marketing communications.
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^ Our online calculator should be used as an indication only and does not indicate approval for the loan or that the product is suitable for you. The calculator should be used as a guide to see the loan repayments based on the loan amount, loan term and number of repayments. The calculator does not take into account default fees or late interest and any applicable discount on early repayment. It's important that you are comfortable with, and have the financial capacity to make, the required repayments. 
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^All information including interest rate is subject to change without notice. Full details available on application.
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^Approval is subject to Elepay's lending and credit criteria and suitability assessment. Terms and conditions apply and are available upon submission of your application or upon request. Any information on this website is of a general nature only and does not take into account your objectives, financial situation or needs.   
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^Before applying, you should consider whether this product is appropriate for you by reviewing your individual circumstances and seeking independent professional financial advice. Please review our <a href="https://go.elepay.com.au/static/pdf/ElepayTMD.pdf" target="_blank" style={{color: "#00ffc4"}} >Target Market Determination</a> when considering whether our product is right for you. For further information or an individual assessment of your eligibility, please contact our Support Team.  
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"#00ffc4", fontSize: "14px" }}>
          ^Member of the Australian Financial Complaints Authority Limited A.B.N. 38 620 494 340 (Member number 96789). 
          </p>
        </div>
        
      </>
  )
};

export default WelcomeBack107;