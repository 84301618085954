import React, { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";

const Tabs = ({
  textColor,
  showBlur,
  bgColor,
  selectedTextColor,
  normalTabTextColor,
  width,
  activeTabWidth,
  tabBackgroundColor,
  borderColor,
  tabOptions,
  onTabChange,
  selectedTab
}) => {
  const [[page], setPage] = useState([0, 0]);

  return (
    <div
      className="flex flex-wrap mb-6"
      style={{ filter: `${showBlur && "blur(10px)"}` }}
    >
      <div className="w-full">
        <AnimateSharedLayout>
          <ul
            className="flex justify-center mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            {tabOptions && (
              <li                 
                className={`flex w-auto text-center border-2 rounded-3xl ${borderColor} ${tabBackgroundColor}`}
              >
                {tabOptions.map((option, i) => {
                  const isActive = i === page;

                  return (
                    <div className={`flex flex-column items-center relative`}>
                      {isActive && (
                        <motion.div
                          style={{ borderRadius: "20px" }}
                          className={`absolute w-full h-full ${borderColor} ${textColor} ${bgColor}`}
                          layoutId="underline"
                        />
                      )}
                      <a                        
                        className={
                          `z-10 text-md px-5 py-3 block font-gilroy-bold leading-normal ` +
                          (selectedTab.index === option.index
                            ? selectedTextColor
                            : normalTabTextColor)
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          onTabChange(option);
                          setPage([i, i - page]);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                      >
                        {option.amount ? option.amount : option.option}
                        {/* {isActive && (<motion.div className=" w-full h-1 rounded-2xl text-purple-dark bg-green-light" layoutId="underline" />)} */}
                      </a>
                    </div>
                  );
                })}
              </li>
            )}
          </ul>
        </AnimateSharedLayout>
      </div>
    </div>
  );
};

export default Tabs;
