import React, { useState } from 'react';
import { motion } from "framer-motion";
import { fullMonthNames } from '../constants/dateConstants';

const Pay_107_Calc_PayLater = ({ planOptions, fundAmount, startDate, onHandleCalculate, leadId, productCode, payOptionsLength, showBlur, reqAmount }) => {
    const [payEarlyDiscountOptions, setPayEarlyDiscountOptions] = useState(false);

    const formatDate = (date) => {
        const startDate = new Date(date);
        return `${startDate.getDate()} ${fullMonthNames[startDate.getMonth()]}, ${startDate.getFullYear()}`;
    }

    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    const dayPlan = 107;

    return (<div className="flex justify-center tablet:block">
        <div className={`w-full m-0 md:justify-center lg:flex-start lg:flex-start bg-purple-light rounded-2xl ${payOptionsLength === 1 && 'rounded-2xl rounded-b-none' }`} style={{ minWidth: 0, filter: `${showBlur && 'blur(10px)'}` }}>
            <div className="block">
                {/* {options.map((option) => ( */}
                    <div className="mt-4 flex justify-between flex-wrap" key={dayPlan}>
                        {/* <OptionCard107
                            requestedAmount={requestedAmount}
                            leadId={leadId}
                            dayPlan={option.dayPlan}
                            noPayFor={option.noPayFor}
                            easyPayments={option.easyPayments}
                            price={option.price}
                            dateOptions={option.dateOptions}
                        /> */}
                        <table className="flex flex-col w-full mt-4 text-white font-gilroy-bold">
                            {!payEarlyDiscountOptions && <button className="mt-4 self-center mb-2" onClick={() => setPayEarlyDiscountOptions(true)}><p className="font-gilroy-bold text-white">Open for pay early discount options <span className="text-green-light">▾</span></p><hr className="table-row border-white w-full tablet:w-1/2 m-auto" /></button>}
                            {
                                payEarlyDiscountOptions && <button className="mt-4 self-center mb-2" onClick={() => setPayEarlyDiscountOptions(false)}><p className="font-gilroy-bold">Open for pay early discount options <span className="text-green-light">▴</span></p><hr className="table-row border-white w-full tablet:w-1/2 m-auto" /></button>
                            }
                            {/* <div className="flex justify-between tablet:justify-evenly">
                                <p className="mt-2 text-white text-left font-gilroy-bold">Pay on or before:</p>
                                <p className="mt-2 text-white text-left font-gilroy-bold">Amount Payable:</p>
                            </div> */}
                            <tr className="text-white font-gilroy-bold flex justify-between tablet:w-1/2 tablet:mx-auto mt-2">
                                <th>Pay on or before:</th>
                                <th>Amount Payable:</th>
                            </tr>
                            {payEarlyDiscountOptions && planOptions[0].products[0].earlyPayments.map((dateOption) => (
                                <>
                                    <tr className="flex justify-between tablet:w-1/2 tablet:m-auto py-2 text-md font-gilroy-bold text-white">
                                        <td>{formatDate(dateOption.date)}</td>
                                        <td className="font-gilroy-bold">{formatCurrency(dateOption.amount)}</td>
                                    </tr>
                                    <hr className="table-row border-white w-full tablet:w-1/2 m-auto" />
                                </>
                            ))}
                            <tr className="flex justify-between tablet:w-1/2 tablet:m-auto py-2 text-lg font-gilroy-bold text-green-light">
                                <th>{formatDate(planOptions[0].products[0].payments[0]?.date)}</th>
                                <th>{formatCurrency(planOptions[0].products[0].payments[0]?.amount)}</th>
                            </tr>
                            {fundAmount > 0 && <button onClick={() => onHandleCalculate(fundAmount, startDate, true)} className="z-10 bg-green-light p-2 py-2 m-auto px-8 mt-4 w-max text-purple-light rounded-3xl font-gilroy-bold">
                                <motion.button whileHover={{ scale: 1.1 }}>
                                    Start Now
                                </motion.button>
                            </button>}
                            {fundAmount <= 0 && <button className="z-10 opacity-50 cursor-default bg-green-light p-2 py-2 m-auto px-8 mt-4 w-max text-purple-light rounded-3xl font-gilroy-bold">
                                Start Now
                            </button>}
                        </table>
                    </div>
                {/* ))} */}
            </div>
        </div>
    </div>)
}

export default Pay_107_Calc_PayLater;