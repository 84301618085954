import React, { useState, useEffect } from "react";
import axios from "axios";

import { motion } from "framer-motion";
import Tabs from "./Tabs";

import BackgroundImage from "../../assets/images/Group_1137.jpg";
import CoronisHeaderLogo from "../../assets/images/coronis_white.png";
import ElepayHeaderLogo from "../../assets/images/Group_1134.png";
import ElepayButterflyLogo from "../../assets/images/single_butterfly.png";
import Plan107Logo from "../../assets/images/Group_1136.png";
import masterCard from "../../assets/images/mastercard.svg";
import visaCard from "../../assets/images/visa.svg";
import applePayCard from "../../assets/images/apple-pay.svg";
import gPayCard from "../../assets/images/g-pay.svg";
import ElepayPrimaryLogo from "../../assets/images/All_Elepay_Logo_02.png";
import DirectDebit from "../../assets/images/Group_1143.png";

import PayLaterPayNow107BankTransfer from "./PayLaterPayNow107BankTransfer";

import { month } from "../../constants/dateConstants";
import { useHistory } from "react-router";

import PaymentModal from "./PaymentModal";
import Modal from "./Modal";

import "../style.css";

const PayLaterPayNow107 = ({ location, match }) => {
  const { leadId } = match.params;

  const [details, setDetails] = useState({
    requestedamount: 0,
    firstname: "",
    address: {
      fullAddress: "",
      id: 0,
      postcode: "0",
      state: "",
      streetName: "",
      streetNumber: "",
      suburb: "",
      unitNumber: "",
    },
    phone: "",
    options: [
      {
        amount: 0,
        products: [
          {
            application_url: "",
            earlyPayments: [
              {
                amount: 0,
                date: "2022-02-17",
                sequence: 0,
              },
            ],
            payments: [
              {
                amount: 0,
                date: "2022-02-17",
                sequence: 0,
              },
              {
                amount: 0,
                date: "2022-02-02",
                sequence: 0,
              },
              {
                amount: 0,
                date: "2022-01-18",
                sequence: 0,
              },
              {
                amount: 0,
                date: "2022-01-03",
                sequence: 0,
              },
            ],
            product: {
              code: "",
              firstPayment: 0,
              name: "",
              numberOfDays: 0,
              numberOfPayments: 0,
              paymentInterval: 0,
            },
          },
        ],
      },
    ],
    payment_methods: [],
    startdate: "",
    email: "",
    form_code: "",
    lastname: "",
    external_id: "",
  });

  const [options, setOptions] = useState([
    {
      amount: 0,
      products: [
        {
          application_url: "",
          earlyPayments: [
            {
              amount: 0,
              date: "2022-02-17",
              sequence: 0,
            },
          ],
          payments: [
            {
              amount: 0,
              date: "2022-02-17",
              sequence: 0,
            },
            {
              amount: 0,
              date: "2022-02-02",
              sequence: 0,
            },
            {
              amount: 0,
              date: "2022-01-18",
              sequence: 0,
            },
            {
              amount: 0,
              date: "2022-01-03",
              sequence: 0,
            },
          ],
          product: {
            code: "",
            firstPayment: 0,
            name: "",
            numberOfDays: 0,
            numberOfPayments: 0,
            paymentInterval: 0,
          },
        },
      ],
    },
  ]);

  const [variationOptions, setVariationOptions] = useState([{}]);

  let amountBefore107days = variationOptions[0].amount
    ? variationOptions[0].amount
    : options[0].amount;
  amountBefore107days = amountBefore107days * (1 + 0.059);

  let discountAmountBefore107days =
    amountBefore107days -
    (variationOptions[0].amount
      ? variationOptions[0].amount
      : options[0].amount);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [requestedAmount, setRequestedAmount] = useState({
    index: 0,
    amount: "0",
    option: "a",
  });

  const [showBankTransferPopup, setShowBankTransferPopup] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const payNowTabOptions = [
    { index: 0, option: "Bank Transfer", optionSelected: "a" },
    { index: 1, option: "Credit Card", optionSelected: "b" },
    { index: 2, option: "Direct Debit", optionSelected: "c" },
  ];
  const [payNowOpenTab, setNowOpenTab] = useState({
    index: 0,
    option: payNowTabOptions[0].option,
    optionSelected: "a",
  });

  // Payment details
  const [transactionAgreementId, setTransactionAgreementId] = useState("");
  const [transactionReference, setTransactionReference] = useState("");
  const [sourceCreationUrl, setSourceCreationUrl] = useState("");

  const history = useHistory();

  const [showBlur, setShowBlur] = useState(false);

  const handleBlur = () => {
    setShowBlur(true);
  };

  const formatCurrency = (elm) => {
    if (!elm) return "$0.00";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(elm);
  };

  const formatDate = (date) => {
    const startDate = new Date(date);

    return `${startDate.getDate()} ${month[startDate.getMonth()]} '${startDate
      .getFullYear()
      .toString()
      .substr(2)}`;
  };

  useEffect(() => {
    let pay_options = [];
    axios
      .get(
        `${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}${location.search}`
      )
      .then(function (response) {
        // handle success
        setDetails(response.data);
        pay_options.push({
          index: 0,
          amount: response.data.requestedamount,
          option: "a",
        });
        response.data.requestedamount_2 &&
          pay_options.push({
            index: 1,
            amount: response.data.requestedamount_2,
            option: "b",
          });
        response.data.requestedamount_3 &&
          pay_options.push({
            index: 2,
            amount: response.data.requestedamount_3,
            option: "c",
          });
        response.data.variationoptions &&
          setVariationOptions(response.data.variationoptions);
        setOptions(response.data.options);
        setRequestedAmount({
          index: 0,
          amount: pay_options[0].amount,
          option: pay_options[0].option,
        });
        const queryOptionArr = pay_options.filter(
          (item) => item.option === params.option
        );
        params.option &&
          setRequestedAmount({
            index: queryOptionArr[0].index,
            amount: queryOptionArr[0].amount,
            option: queryOptionArr[0].option,
          });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const handleProduct = (applicationUrl) => {
    window.location.href = `${applicationUrl}`;
  };

  const handleCard = (paymentType) => {
    handleBlur();
    axios
      .post(`${process.env.REACT_APP_ENV_PREFIX}/bizcore/createAgreement`, {
        lead_id: leadId,
        givenName: details.firstname,
        familyName: details.lastname,
        email: details.email,
        mobile: details.phone,
        amount: variationOptions[0].amount
          ? variationOptions[0].amount
          : options[0].amount,
        paymentType: paymentType,
      })
      .then(function (response) {
        const {
          transactionAgreementId,
          transactionAgreementReference,
          sourceCreationUrl,
        } = response.data;
        setTransactionAgreementId(transactionAgreementId);
        setTransactionReference(transactionAgreementReference);
        setSourceCreationUrl(sourceCreationUrl);
        setShowPaymentModal(true);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (showPaymentModal) {
        axios
          .get(
            `${process.env.REACT_APP_ENV_PREFIX}/bizcore/checkpayment/${transactionAgreementId}`
          )
          .then(function (response) {
            // handle success
            // 1 -> DD
            // 3 -> CC
            const { transaction } = response.data;
            transaction && clearInterval(interval);

            if (
              transaction.transactionStatusId != null &&
              (transaction.transactionStatusId === 1 ||
                transaction.transactionStatusId === 3)
            ) {
              setShowPaymentModal(false);
              history.push({
                pathname: "/payment-thank-you",
                state: {
                  firstName: details.firstname,
                  transactionReference: transaction.transactionReference,
                  transactionDateFormatted:
                    transaction.transactionDateFormatted,
                  transactionSourceType: transaction.transactionSourceType,
                  totalAmount: transaction.totalAmountFormatted,
                },
              });
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [transactionAgreementId, transactionReference, showPaymentModal]);

  const updateBankTransferPopup = (show) => {
    axios
      .post(
        `${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}?method=eft`
      )
      .then(function (response) {
        setShowBankTransferPopup(show);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const closePopup = (type) => {
    if (type == "more_funding") {
      window.location.href = "https://www.elepay.com.au/";
    }
    setShowBankTransferPopup(!showBankTransferPopup);
  };

  return (
    <>
      {showBankTransferPopup == true && (
        <PayLaterPayNow107BankTransfer closePopup={closePopup} />
      )}

      {showPaymentModal && (
        <PaymentModal
          showPaymentModal={showPaymentModal}
          url={sourceCreationUrl}
          onCloseModal={() => setShowBlur(false)}
        />
      )}

      {showModal && (
        <Modal
          details={details}
          amount={
            variationOptions[0].amount
              ? variationOptions[0].amount
              : options[0].amount
          }
          requestedAmount={requestedAmount}
          showModal={showModal}
          onCloseModal={() => setShowModal(false)}
          onHandleCard={() => {
            setShowModal(false);
            handleCard("0");
          }}
        />
      )}

      <div
        className="pb-10 full-banner coronis-landing coronis-pay-now-pay-later-form"
        style={{ filter: `${showBlur && "blur(10px)"}` }}
      >
        <div className="flex flex-col xl:pb-0 xl:flex-row items-center justify-between flex-wrap header-coronis-banner">
          <table className="header-table">
            <tr>
              <td className="header-column-coronis">
                <img src={CoronisHeaderLogo} alt="Coronis Header Logo" />
              </td>
              <td className="header-column-elepay">
                <img className="header-column-full-elepay" src={ElepayHeaderLogo} alt="Elepay Header Logo" />
                <img className="header-column-butterfly" src={ElepayButterflyLogo} alt="Elepay Butterfly Logo" />
              </td>
            </tr>
          </table>
        </div>

        <div className="flex flex-col xl:pb-0 xl:flex-row items-center justify-between flex-wrap banner-main-container">
          <div className="flex flex-col xl:flex-row flex-wrap xl:flex-start items-center banner-container">
            <img
              className="w-100"
              src={BackgroundImage}
              alt="Background Image"
            />
            <div className="greeting">
              <h1>
                Hello {details.firstname} {details.lastname},
              </h1>
            </div>
            <div className="manageHeading">
              <h1>
                Let's manage your property expenses
                <br />
                the easy way
              </h1>
            </div>
          </div>
        </div>

        <div className="details-container">
          <div className="details-container-floating">
            <div className="details-table">
              <div className="details-column">
                <h1>Your Details</h1>
                <div className="details-column-heading">Email</div>
                <div className="details-column-data">{details.email}</div>
                <div className="details-column-heading">Property Address</div>
                <div className="details-column-data">
                  {details.address.fullAddress}
                </div>
                <div className="details-column-heading">Requested Amount</div>
                <div className="details-column-data">
                  {formatCurrency(requestedAmount.amount)}
                </div>
                <div className="details-column-heading">Start Date</div>
                <div className="details-column-data last-row">
                  {formatDate(details.startdate)}
                </div>
              </div>
              <div className="payment-column">
                <table className="payment-table">
                  <tr>
                    <td colSpan={2} className="payment-header">
                      <h1>Your Payment Options</h1>
                    </td>
                  </tr>
                  <tr className="payment-row">
                    <td className="payment-heading-column first-row">
                      Pay Now
                    </td>
                    <td className="payment-amount-column">
                      {variationOptions[0].amount
                        ? formatCurrency(variationOptions[0].amount)
                        : formatCurrency(options[0].amount)}
                    </td>
                  </tr>
                  <tr className="payment-row">
                    <td className="payment-heading-column">
                      Pay Now Discount
                    </td>
                    <td className="payment-amount-column">
                      {formatCurrency(discountAmountBefore107days)}
                    </td>
                  </tr>
                  <tr className="payment-row last-row">
                    <td className="payment-heading-column last-row">
                      Pay Later in 107 Days
                    </td>
                    <td className="payment-amount-column last-row">
                      {formatCurrency(amountBefore107days)}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="payment-container">
          <div className="pay-later-container">
            <div className="flex-1 step-2-section">
              <h2 className="font-gilroy-bold text-3xl text-black-dark text-center">
                Pay Later
              </h2>
              <img className="w-100" src={Plan107Logo} alt="Plan 107 Logo" />
              <p className="font-gilroy-bold text-sm text-black-dark text-center">
                Pay on or before:
              </p>
              <div className="pay-later-date-container">
                <span className="pay-later-date">
                  {formatDate(options[0].products[0].payments[0]?.date)}
                </span>
                <span className="pay-later-stroke">
                  <hr />
                </span>
                <span className="pay-later-amount">
                  {formatCurrency(options[0].products[0].payments[0]?.amount)}
                </span>
              </div>
              <div className="font-gilroy-bold fees-capped-container">
                Late Fees capped at $10 for plans overdue up to 60 days*
              </div>
              <div className="pay-early-options-heading">
                Pay early&nbsp;<span>discount</span>&nbsp;options:
              </div>
              <div>
                <table className="pay-early-options-table">
                  {options[0].products[0].earlyPayments.map((dateOption) => (
                    <tr className="pay-early-options">
                      <td className="pay-early-options-date">
                        Before {formatDate(dateOption.date)}
                      </td>
                      <td className="pay-early-options-amount">
                        {formatCurrency(dateOption.amount)}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
              <div className="pay-later-button-container">
                <button
                  type="button"
                  className="top-button p-2 mt-2 py-3 px-4 rounded-3xl font-gilroy-bold"
                  onClick={() =>
                    handleProduct(
                      variationOptions[0].products
                        ? variationOptions[0].products[0].application_url
                        : options[0].products[0].application_url
                    )
                  }
                >
                  SELECT PAY LATER
                </button>
              </div>
              <div className="conditions-apply font-gilroy-bold">
                *Conditions Apply
              </div>
            </div>
          </div>
          <div className="payment-divider"></div>
          <div className="pay-now-container">
            <div className="flex-1 step-2-section">
              <>
                <h2 className="font-gilroy-bold text-3xl text-black-dark text-center">
                  Pay Now
                </h2>
                <p className="font-gilroy-bold text-sm text-black-dark text-center">
                  Choose a payment method:
                </p>
              </>

              <div className="less-bottom">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Tabs
                    textColor={"text-black-light"}
                    bgColor={"#37ae9a"}
                    selectedTextColor={"text-white"}
                    borderColor={"#37ae9a"}
                    tabBackgroundColor={"bg-white"}
                    normalTabTextColor={"text-black-dark"}
                    width={396}
                    height={52}
                    activeWidth={132}
                    activeTabWidth="9rem"
                    tabOptions={payNowTabOptions}
                    onTabChange={(option) => setNowOpenTab(option)}
                    selectedTab={payNowOpenTab}
                    borderRadius={"50px"}
                  />
                </motion.div>
              </div>

              <div className="pay-now-content">
                {payNowOpenTab.index == 0 && (
                  <>
                    <div className="bank-transfer-table-container">
                      <table>
                        <tr>
                          <td className="bank-transfer-heading">
                            Bank Account Name:
                          </td>
                          <td className="bank-transfer-data">
                            Elevare Pay Easy Pty Ltd
                          </td>
                        </tr>
                        <tr>
                          <td className="bank-transfer-heading">BSB:</td>
                          <td className="bank-transfer-data">084 004</td>
                        </tr>
                        <tr>
                          <td className="bank-transfer-heading">
                            Account Number:
                          </td>
                          <td className="bank-transfer-data">26 322 6845</td>
                        </tr>
                        <tr>
                          <td className="bank-transfer-heading">Ref Number:</td>
                          <td className="bank-transfer-data">
                              {details.external_id ? details.external_id : leadId}
                          </td>
                        </tr>
                      </table>
                      <div className="bank-transfer-notify-text">
                        As soon as you've successfully completed the transfer,
                        please select the Bank
                        <br />
                        Transfer Complete button to notify our team.
                      </div>
                      <div className="bank-transfer-pay-today-text">
                        Pay today:
                      </div>
                      <div className="bank-transfer-amount-text">
                        {formatCurrency(requestedAmount.amount)}
                      </div>
                    </div>
                    <div className="pay-now-button-container">
                      <button
                        type="button"
                        className="top-button p-2 mt-2 py-3 px-4 rounded-3xl font-gilroy-bold"
                        onClick={() => updateBankTransferPopup(true)}
                      >
                        BANK TRANSFER COMPLETE
                      </button>
                    </div>
                  </>
                )}

                {payNowOpenTab.index == 1 && (
                  <>
                    <p className="mb-6 font-gilroy-bold text-sm text-black-dark text-center">
                      To make your life as easy and as hassle-free as
                      <br />
                      possible, we offer a selection of payment options
                      <br />
                      with your order:
                    </p>
                    <div className="cards-container">
                      <span className="flex">
                        <img
                          className="flex pl-2 pb-2 w-20"
                          src={masterCard}
                          alt="Master Card"
                        />
                        <img
                          className="flex pl-2 pb-2 w-20"
                          src={visaCard}
                          alt="Visa Card"
                        />
                      </span>
                      <span className="flex">
                        <img
                          className="flex pl-2 w-20"
                          src={applePayCard}
                          alt="Apple Pay Card"
                        />
                        <img
                          className="flex pl-2 w-20"
                          src={gPayCard}
                          alt="G Pay Card"
                        />
                      </span>
                    </div>
                    <p className="mb-2 pt-1 font-gilroy-bold text-black-dark text-center fee-applies">
                      1.25% Fee Applies
                    </p>
                    <div className="credit-card-amount-text">
                      {formatCurrency(requestedAmount.amount)}
                    </div>
                    <div className="pay-now-button-container">
                      <button
                        type="button"
                        className="top-button p-2 mt-2 py-3 px-4 rounded-3xl font-gilroy-bold"
                        onClick={() => handleCard("2")}
                      >
                        SELECT PAY NOW
                      </button>
                    </div>
                  </>
                )}

                {payNowOpenTab.index == 2 && (
                  <>
                    <p className="mb-6 font-gilroy-bold text-sm text-black-dark text-center">
                      To make your life as easy and as hassle-free as
                      <br />
                      possible, we offer a selection of payment options
                      <br />
                      with your order:
                    </p>
                    <div>
                      <img
                        className="direct-debit-img"
                        src={DirectDebit}
                        alt="direct debit"
                      />
                    </div>
                    <p className="mt-10 mb-2 pt-2 font-gilroy-bold text-black-dark text-center direct-debit-fee-applies">
                      $2.95 Per Payment
                    </p>
                    <div className="direct-debit-amount-text">
                      {formatCurrency(requestedAmount.amount)}
                    </div>
                    <div className="pay-now-button-container">
                      <button
                        type="button"
                        className="top-button p-2 mt-2 py-3 px-4 rounded-3xl font-gilroy-bold"
                        onClick={() => setShowModal(true)}
                      >
                        SELECT PAY NOW
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-16 pb-1 flex flex-col xl:text-right flex-wrap xl:justify-between">
          <img
            className="flex elepay-footer-logo"
            src={ElepayPrimaryLogo}
            alt="Elepay"
          />
          <p className="less-bottom xl:pt-0 text-right footer-rights">
            ABN: 47 634 728 591. Copyright 2022 Elepay. <br />
            All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default PayLaterPayNow107;
