import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import { isJwtExpired } from "jwt-check-expiration";
import { motion } from "framer-motion";
import ElepayLogo from "../../assets/images/Elepay_enjoy_more_time.png";

const RayWhiteChooseProduct2 = ({ location, match }) => {
  const {
    formCode,
    products,
    authenticated,
    partner_code,
    partner_name,
    firstname,
    lastname,
    user_id,
    forms,
  } = location.state || { forms: [] };

  const path = match.url.split("/");
  // const jwt = localStorage.getItem('token');

  const [showAllProducts, setShowAllProducts] = useState([]);
  const [singleProductDesc, setSingleProductDesc] = useState({
    formCode: "",
    products: [],
  });
  const [multipleProductDesc, setMultipleProductDesc] = useState({
    formCode: "",
    products: [],
  });

  const history = useHistory();

  var convertedForms = [];

  if (path[3]) {
    var jwt = path[3];
    localStorage.setItem("token", path[3]);
    if (isJwtExpired(jwt))
      window.location.href = process.env.REACT_APP_PORTAL_PREFIX;

    var decode = jwtDecode(jwt);

    for (let i = 0; i < decode.forms.length; i++) {
      convertedForms.push(JSON.parse(decode.forms[i]));
    }
  }

  const [partnerFirstName, setPartnerFirstName] = useState(
    firstname || decode.firstname
  );
  const [partnerLastName, setPartnerLastName] = useState(
    lastname || decode.lastname
  );
  const [partnerCode, setPartnerCode] = useState(
    partner_code || decode.partnercode
  );
  const [userId, setUserId] = useState(user_id || decode.upn);

  let productInfo = [
    {
      product_code: "ELEPAY107",
      id: "107",
      name: "107",
      // noPayFor: 15,
    },
    {
      product_code: "ELEPAY60",
      id: "60",
      name: "60",
    },
    {
      product_code: "ELEPAY90",
      id: "90",
      name: "90",
    },
    {
      product_code: "ELEPAY120",
      id: "120",
      name: "120",
    },
    {
      product_code: "ELEPAY60-ELEPAY90",
      id: "60-90",
      name: "60/90",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY90-ELEPAY60",
      id: "60-90",
      name: "60/90",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY60-ELEPAY120",
      id: "60-120",
      name: "60/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY120-ELEPAY60",
      id: "60-120",
      name: "60/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY90-ELEPAY120",
      id: "90-120",
      name: "90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY120-ELEPAY90",
      id: "90-120",
      name: "90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY60-ELEPAY90-ELEPAY120",
      id: "60-90-120",
      name: "60/90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY90-ELEPAY120-ELEPAY60",
      id: "60-90-120",
      name: "60/90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY120-ELEPAY60-ELEPAY90",
      id: "60-90-120",
      name: "60/90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY120-ELEPAY90-ELEPAY60",
      id: "60-90-120",
      name: "60/90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY60-ELEPAY120-ELEPAY90",
      id: "60-90-120",
      name: "60/90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY90-ELEPAY60-ELEPAY120",
      id: "60-90-120",
      name: "60/90/120",
      // noPayFor: 45,
    },
    {
      product_code: "ELEPAY12monthsnointerest",
      id: "elepay12ni_rlc",
      name: "12 months no interest",
    },
    {
      product_code: "ELEPAY180",
      id: "elepay180",
      name: "180",
    },
  ];

  const handleProduct = (formCode) => {
    history.push({
      pathname: `/${partnerCode}/lead/${formCode}`,
      // pathname: (formId === "107" && '/coronis/lead/cor107nl') || (formId === "60-90" && '/coronis/lead/cor6090nlr'),
      state: {
        formCode,
        authenticated,
        partner_code: partnerCode,
        partner_name,
        firstname: partnerFirstName,
        lastname: partnerLastName,
        user_id: userId,
        forms,
        token: path[3] ? path[3] : null,
      },
    });
    // window.location.href = (formId === "107" && '/ui/coronis/lead/107_now') || (formId === "60-90" && '/ui/coronis/lead');
  };

  useEffect(() => {
    const singleProduct = [];
    const multipleProducts = [];
    let singleProdObj = {};
    let multipleProdObj = {};

    const allForms =
      (forms.length > 0 && forms) ||
      (convertedForms.length > 0 && convertedForms);

    allForms.map((form) => {
      const { code, paymentMethods, products } = form;

      if (products.length === 1) {
        singleProduct.push(products[0].name.replace(/\s/g, ""));

        if (singleProduct.length > 1) {
          for (let i = 0; singleProduct.length > i; i++) {
            multipleProdObj = {
              formCode: code,
              products: singleProduct[i],
            };
          }
        } else {
          singleProdObj = {
            formCode: code,
            products: singleProduct[0],
          };
        }
      }

      if (products.length > 1) {
        products.map((prod) =>
          multipleProducts.push(prod.name.replace(/\s/g, ""))
        );
        multipleProdObj = {
          formCode: code,
          products: _.join(_.uniq(multipleProducts), "-"),
        };
      }

      const allProducts = [{ ...singleProdObj }, { ...multipleProdObj }];

      setSingleProductDesc(singleProdObj);
      setMultipleProductDesc(multipleProdObj);
      setShowAllProducts(allProducts);
    });
  }, []);

  return (
    <div>
      <div className="flex justify-between flex-wrap items-center bg-purple-dark">
        {/* <div className="p-10 flex flex-wrap lg:flex-start items-baseline bg-purple-dark">
                    <img className="flex md:pl-0 lg:pl-40 w-52 lg:w-72" src={getPartnerLogoByCode(partner_code)} alt={getPartnerNameByCode[partner_code]} />
                </div> */}
        <img className="p-10 pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
      </div>
      <div className="flex flex-col justify-center">
        {jwt && !isJwtExpired(jwt) && (
          <button
            type="button"
            className="border border-purple-light w-max m-auto mt-5 p-2 mt-2 py-3 px-4 text-purple-light rounded-3xl font-gilroy-bold"
            onClick={() =>
              (window.location = process.env.REACT_APP_PORTAL_PREFIX)
            }
          >
            <motion.button whileHover={{ scale: 1.1 }}>
              Back to Portal
            </motion.button>
          </button>
        )}
        <h3 className="mt-20 p-4 items-center font-gilroy-bold leading-14 text-5xl text-center text-purple-light">
          Choose a product
        </h3>
        <div className="m-8">
          {showAllProducts.map((product) => {
            const productDesc = productInfo.find(
              (prod) => prod.product_code === product.products
            );

            return (
              <button
                className="w-max p-8 m-4 mb-2 border border-purple-extra-light hover:bg-purple-extra-light focus:bg-purple-light text-purple-light focus:text-white rounded-2xl"
                onClick={() => handleProduct(product.formCode)}
                key={product.formCode}
              >
                <small className="text-md font-gilroy-regular">
                  {productDesc.name.includes("months") ? "Elepay" : "days"}
                </small>
                <h3 className="text-4xl font-gilroy-semibold">
                  {productDesc && productDesc.name}
                </h3>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RayWhiteChooseProduct2;
