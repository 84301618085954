import React from 'react';
import _ from 'lodash';
import days60 from '../../assets/images/60_days.svg';
import days90 from '../../assets/images/90_days.svg';
import days120 from '../../assets/images/120_days.svg';

const CalculatorPlanOptions = ({onConfirmBox, details, options, requestedAmount, disableSelectButton }) => {
    return (<div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-between items-end">
            <p className="font-gilroy-semibold w-full text-4xl text-purple-light text-center">Select from three different plan options</p>
            <div className="flex flex-col xl:flex-row pt-8 justify-center items-center text-left">
                <div className="flex justify-center" style={{ margin: "0 7rem" }}>
                    <img className="flex pl-2 w-28 xl:w-40" src={days60} alt="60 Days" />
                    <img className="flex pl-2 w-28 xl:w-40" src={days90} alt="90 Days" />
                    <img className="flex pl-2 w-28 xl:w-40" src={days120} alt="120 Days" />
                </div>
                <div className="pt-8 xl:pt-0 pl-0 w-full xl:w-1/2">
                    <p className="text-left px-8 xl:px-4 text-2xl font-gilroy-regular text-purple-dark">Deferring property expenses with Elepay couldn't be any easier. We are Australia's Pay Later specialist for property owners.</p> <br />
                    <p className="text-left px-8 xl:px-4 text-xl font-gilroy-regular text-black-dark">Choose between a single repayment at the end of the plan or 4 repayments spread over the last 45 days. Funds will be in your account within days - it is that simple.</p> <br />
                </div>
            </div>
        </div>
    </div>)
};

export default CalculatorPlanOptions;