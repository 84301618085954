import React, { useState } from 'react';
import axios from 'axios';
import Joi from 'joi-browser';
import { useHistory, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import _ from 'lodash';
import ElepayLogo from '../../assets/images/ElepayLogo.png';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    // Validation Errors
    const [errors, setErrors] = useState({});

    const authErrorMessage = "Incorrect email or password. Please try again.";
    const [showAuthenticationError, setShowAuthenticationError] = useState(false);

    const history = useHistory();
    // Validation Schema
    const schema = {
        username: Joi.string().email().min(3).max(50).label("Email").required(),
        password: Joi.string().min(2).max(20).label("Password").required(),
    }

    const validate = () => {
        const validateDetails = {
            username, password
        }
        const options = { abortEarly: false };
        const { error } = Joi.validate(validateDetails, schema, options);
        if (!error) return null;

        const errors = {};
        error.details.map(error => errors[error.path[0]] = error.message);

        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validating upon submit
        const errors = validate();
        setErrors(errors || {});
        if (errors) return;

        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/re_login`, {
            email: username,
            password: password,
        })
            .then(function (response) {
                // “authenticated”: true/false, “firstname”: “firstname”, “lastname”: “lastname”
                const { authenticated, firstname, lastname, user_id, partner_code, partner_name, forms  } = response.data;
                !authenticated && setShowAuthenticationError(true); setTimeout(() => setShowAuthenticationError(false), 5000);

                if(authenticated) {
                    const form_codes = _.map(forms, 'code');
                    const products = _.map(forms, 'products');
                    
                    history.push({
                        pathname: form_codes.length > 1 ? `/${partner_code}/choose-product` : `/${partner_code}/lead/${form_codes[0]}`,
                        state: {
                            formCode: form_codes.length === 1 ? form_codes[0] : form_codes,
                            products,
                            authenticated,
                            partner_code,
                            partner_name,
                            firstname,
                            lastname,
                            user_id,
                            forms
                        },
                    });
                }
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    return (
        <>
            <div className="flex justify-between flex-wrap items-center bg-purple-dark">
                <div className="p-10 flex flex-wrap lg:flex-start items-end bg-purple-dark">
                    <img className="pr-10" src={ElepayLogo} alt="Elepay Logo" />
                    <p className="flex text-green-light text-2xl font-gilroy-bold md:pl-0 lg:pl-4">CRM</p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mt-20 flex flex-col items-center">
                    <div className="flex flex-col text-left w-80">
                        <h3 className="font-gilroy-semibold text-xl text-purple-light text-left">Please enter details</h3>
                        {showAuthenticationError && <p className="pt-2 text-red-dark font-gilroy-semibold text-sm">{authErrorMessage}</p>}
                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['username'] && 'text-red-dark'}`}>Email</p>
                        <input type="email" name="username" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['username'] && 'border-red-dark'}`} value={username} onChange={(e) => { setUsername(e.target.value); }} />
                        {errors['username'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['username']}</p>}

                        <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['password'] && 'text-red-dark'}`}>Password</p>
                        <input type="password" name="password" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['password'] && 'border-red-dark'}`} value={password} onChange={(e) => { setPassword(e.target.value); }} />
                        {errors['password'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['password']}</p>}

                        <Link to="/reset-password" className="pt-2 text-md text-purple-light font-gilroy-regular underline">Forgot password?</Link>
                    </div>
                    <div className="mt-4 flex self-center w-80">
                        <button type="submit" className="bg-purple-light p-2 mt-2 py-3 px-5 text-white rounded-3xl font-gilroy-bold">
                            <motion.button whileHover={{ scale: 1.1 }}>
                                Login
                            </motion.button>
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Login;