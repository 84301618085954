import React from "react";
import ElepayLogo from "../../assets/images/Elepay_enjoy_more_time.png";

const Header = () => {
  return (
    <div className="py-5 px-8 flex flex-col lg:flex-row items-center justify-between flex-wrap bg-purple-dark">
      <img src={ElepayLogo} className="w-64" alt="Elepay" />
    </div>
  );
};

export default Header;