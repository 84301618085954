import ray_white_aspley from '../assets/images/partners/ray_white_aspley.png';
import amazema from '../assets/images/partners/amazema_interiors.png';
import safetysquad from '../assets/images/partners/safetysquad.png';
import pbhomes from '../assets/images/partners/pbhomes.svg';
import elepay from '../assets/images/elepay-logo.png';

const partnerInfo = {
    ray_white_aspley: {
        partner_code: 'ray_white_aspley',
        name: 'Ray White Aspley',
        logo: ray_white_aspley
    },
    stylingproject: {
        partner_code: 'stylingproject',
        name: 'The Styling Project',
        logo: null,
    },
    amazema: {
        partner_code: 'amazema',
        name: 'Amazema Interiors',
        logo: amazema
    },
    safetysquad: {
        partner_code: 'safetysquad',
        name: 'Safety Squad',
        logo: safetysquad
    },
    pbhomes: {
        partner_code: 'pbhomes',
        name: 'Presenting Beautiful Homes',
        logo: pbhomes
    },
    elepay: {
        partner_code: 'elepay',
        name: 'Elepay House',
        logo: elepay
    },
}

const checkImage = (url) => {
  const image = new Image();
  image.onload = () => {
      if (image.width > 0) {
          console.log('Image Exists');
      }
    image.onerror = () => {
        console.log("Image doesn't exist");
    }
  }
  image.src = url;
};


export const getPartnerNameByCode = (partner_code) => {
    return partnerInfo[partner_code] != null ? partnerInfo[partner_code].name : '';
};

export const getPartnerLogoByCode = (partner_code) => {
    return partnerInfo[partner_code].logo;
    // return `https://go.elepay.com.au/static/images/partners/${partner_code}.png` ? `https://go.elepay.com.au/static/images/partners/${partner_code}.png` : `https://go.elepay.com.au/static/images/partners/${partner_code}.svg`;
    // checkImage(`https://go.elepay.com.au/static/images/partners/${partner_code}.${"png" || "svg"}`);
}

export default partnerInfo;