import React from "react";
import OptionCard from "../common/OptionCard";

const SingleOption = () => {
  const options = [
    {
      dayPlan: 60,
      noPayFor: 60,
      easyPayments: null,
      price: Number(1200).toFixed(2),
      dateOptions: ["30 November, 2021"],
    },
    {
      dayPlan: 90,
      noPayFor: 90,
      easyPayments: null,
      price: Number(1200).toFixed(2),
      dateOptions: ["30 November, 2021"],
    },
    {
      dayPlan: 120,
      noPayFor: 120,
      easyPayments: null,
      price: Number(1200).toFixed(2),
      dateOptions: ["30 November, 2021"],
    },
  ];

  return (
    <div className="flex flex-wrap justify-center">
      {options.map((option) => (
        <div className="pl-4 mt-4" key={option.dayPlan}>
          <OptionCard
            dayPlan={option.dayPlan}
            noPayFor={option.noPayFor}
            easyPayments={option.easyPayments}
            price={option.price}
            dateOptions={option.dateOptions}
          />
        </div>
      ))}
    </div>
  );
};

export default SingleOption;
