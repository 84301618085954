import BackgroundImage from "../../assets/images/Group_1137.jpg";

export default ({
  closePopup
}) => {

  return (
    <div className="bank-transfer-popup continue">
      <div className="popup-container">
        <div className="m-a">
          <img
            className="flex pb-2 banner-image"
            src={BackgroundImage}
            alt="Background Image"
          />

          <h1 className="font-gilroy-bold text-center text-white bank-transfer-thank-you">Thank You</h1>

          <h2 className="font-gilroy-bold text-center pb-0 bank-transfer-once-cleared-text">
            Once the funds have cleared in our account,<br />you will receive a payment receipt.
          </h2>

          <div className="flex flex-col items-center mt-1">
            <div className="font-gilroy-bold bank-transfer-more-funding">
              Need Access to More Funding?<br />Up to $50,000 within hours. Up to $500,000 within days.
            </div>

            <div className="bank-transfer-button-container">
              <table>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="top-button p-2 mt-2 py-3 px-4 rounded-3xl font-gilroy-bold"
                      onClick={(_) => closePopup("back")}
                    >
                      BACK
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="top-button p-2 mt-2 py-3 px-4 rounded-3xl font-gilroy-bold"
                      onClick={(_) => closePopup("more_funding")}
                    >
                      MORE FUNDING
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
