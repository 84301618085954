import React from 'react';
import ElepayLogo from '../../assets/images/Elepay_enjoy_more_time.png';
import CoronisLogo from '../../assets/images/CoronisLogo.png';

const PayFromRentThankYou = ({ location }) => {
    const { firstname, email } = location.state || {};

    return (<><div className="flex justify-between flex-wrap items-center bg-purple-dark">
        <div className="p-10 flex flex-wrap lg:flex-start items-baseline bg-purple-dark">
            <img className="flex md:pl-0 lg:pl-40" src={CoronisLogo} alt="Coronis" />
            <p className="flex text-green-light text-2xl font-gilroy-bold md:pl-0 lg:pl-16">Payment options</p>
        </div>
        <img className="pr-10 w-48" src={ElepayLogo} alt="Elepay Logo" />
    </div>
        <div className="flex flex-col justify-center items-center w-4/5 lg:w-1/5 xl:w-3/5 m-auto">
            <h3 className="mt-20 self-center font-gilroy-bold leading-14 text-6xl text-center text-purple-light">Thank you {firstname}.</h3>
            <h3 className="font-gilroy-semibold text-purple-light text-2xl">Your request for pay from rent has been sent to <span className="font-gilroy-bold text-purple-dark text-2xl">{email}</span></h3>
        </div>
    </>)
}

export default PayFromRentThankYou;