import React from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { motion } from 'framer-motion';

const PayFromRent = ({ details, leadId, showBlur, requestedAmount }) => {
    const { requestedamount, firstname } = details;
    const history = useHistory();

    const handleAuthorise = (params) => {
        axios.post(`${process.env.REACT_APP_ENV_PREFIX}/lead/re_pay/${leadId}?option=${requestedAmount.option}`)
            .then(function (response) {
                // “authenticated”: true/false, “firstname”: “firstname”, “lastname”: “lastname”
                const { success, email } = response.data;
                success && (
                    history.push({
                        pathname: "/authorise-thank-you",
                        state: {
                            firstname,
                            email
                        },
                    })
                )
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    // Formatting the currency 
    const formatCurrency = (elm) => {
        if (!elm) return '$0.00';

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        return formatter.format(elm);
    }

    return (<div className="text-left pb-10 pr-4 md:justify-center lg:flex-start lg:flex-start rounded-2xl rounded-t-none border border-purple-light border-t-0" style={{ filter: `${showBlur && 'blur(10px)'}` }}>
        <div className="flex flex-col">
            <div>
                <p className="font-gilroy-bold text-xl text-white pl-8 pt-6"><span className="text-purple-light">Option 3</span><span className="pl-4 text-black-dark">Pay {formatCurrency(requestedAmount.amount)} from Rent</span></p>
            </div>
            <div className="pl-20">
                <div className="pt-4 pb-6 flex items-baseline flex-col">
                    {/* <h3 className="pt-6 pl-10 font-gilroy-bold text-3xl text-purple-light">Pay ${requestedamount} from Rent</h3> */}
                    <p className="pl-12 font-gilroy-regular text-md text-black-dark">I Authorise Coronis Property Management to deduct this payment of {formatCurrency(requestedAmount.amount)} from rental income.
                    </p>
                    <button className="mt-6 ml-10 border border-purple-light p-2 mt-2 py-3 px-4 text-purple-light rounded-3xl font-gilroy-bold" onClick={handleAuthorise}>
                        <motion.button whileHover={{ scale: 1.1 }}>
                            Authorise now
                        </motion.button>
                    </button>
                </div>
            </div>
        </div>

    </div>)
}

export default PayFromRent;