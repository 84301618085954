import React, { useState, useEffect } from "react";
import axios from "axios";

import Tabs from "../calculator_components/Tabs";
import { motion } from "framer-motion";

import DayPickerInput from "react-day-picker/DayPickerInput";

import { formatDate, parseDate } from "react-day-picker/moment";
import { useHistory } from "react-router-dom";

import Joi from "joi-browser";

import partnerInfo from "../../helpers/getPartnerInfo";
import { initTrack } from "../../helpers/tracking";

import StateSelect from "../../components/common/StateSelect";

import ElepayLogo from "../../assets/images/Elepay_enjoy_more_time.png";
import days60 from "../../assets/images/60_days.svg";
import days90 from "../../assets/images/90_days.svg";
import days120 from "../../assets/images/120_days.svg";
import masterCard from "../../assets/images/mastercard.svg";
import visaCard from "../../assets/images/visa.svg";
import applePayCard from "../../assets/images/apple-pay.svg";
import gPayCard from "../../assets/images/g-pay.svg";
import checkmark from "../../assets/images/Checkmark.JPG";
import ElepayPrimaryLogo from "../../assets/images/Elepay_Primary.svg";
import GirlCouch from "../../assets/images/CalcMobImage.png";

import "../style.css";
import { Carousel } from "react-responsive-carousel";

const CustomerLanding = ({ location, match }) => {
  const [step, setStep] = useState(1);
  const path = match.url.split('/');
  const payLaterTabOptions = [
    { index: 0, option: "1 x payment", optionSelected: "a" },
    { index: 1, option: "4 x payments", optionSelected: "b" },
  ];
  const [payLaterOpenTab, setPayLaterOpenTab] = useState({
    index: 0,
    option: payLaterTabOptions[0].option,
    optionSelected: "a",
  });

  const payNowTabOptions = [
    { index: 0, option: "Credit Card", optionSelected: "a" },
    { index: 1, option: "Direct Debit", optionSelected: "b" },
  ];
  const [payNowOpenTab, setNowOpenTab] = useState({
    index: 0,
    option: payNowTabOptions[0].option,
    optionSelected: "a",
  });

  const history = useHistory();

  const { partner_code, form_code } = match.params;
  // (partner !== "coronis" || partner !== "coronis_b") && history.push('/not-found');
  // Property Owner Details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  // Property Address
  // const [propertyAddress, setPropertyAddress] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [state, setState] = useState("");
  const [postCode, setPostCode] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [partnerCode, setPartnerCode] = useState("");

  // Application Details
  const [requestedAmount, setRequestedAmount] = useState("");
  const [showRequestedAmount2, setShowRequestedAmount2] = useState(false);
  const [requestedAmount2, setRequestedAmount2] = useState("");
  const [showRequestedAmount3, setShowRequestedAmount3] = useState(false);
  const [requestedAmount3, setRequestedAmount3] = useState("");
  // const [partner, setPartner] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  // Validation Errors
  const [errors, setErrors] = useState({});

  const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

  useEffect(() => {
    console.log(initTrack("CustomerLanding", partner_code, form_code, path[3]));
    axios
      .get(
        `${process.env.REACT_APP_ENV_PREFIX}/lead/re_qry/${partner_code}?type=partner`
      )
      .then(function (response) {
        if (response.status === 400 || response.status === 401)
          history.push("/404");
        const { name, id, type } = response.data;
        setPartnerName(name);
        setPartnerCode(id);
      })
      .catch(function (error) {
        console.log("error", error);
        //   history.push('/404');
      });
  }, []);

  // Validation Schema
  const schema = {
    firstName: Joi.string().min(3).max(20).label("First Name").required(),
    lastName: Joi.string().min(2).max(20).label("Last Name").required(),
    email: Joi.string().email().min(3).max(50).label("Email").required(),
    mobile: Joi.number().label("Mobile").required(),
    // propertyAddress: Joi.string().min(3).max(100).label("Property Address").required(),
    unitNumber: Joi.string().allow("").label("Unit Number"),
    streetNumber: Joi.string().label("Street Number").required(),
    streetName: Joi.string().label("Street Name").required(),
    suburb: Joi.string().min(3).max(20).label("Suburb").required(),
    state: Joi.string().min(1).max(10).label("State").required(),
    postCode: Joi.number().label("Postcode").required(),
    requestedAmount: Joi.number()
      .min(1)      
      .label("Requested Amount")
      .required(),
    requestedAmount2: Joi.number()
      .min(1)      
      .allow("")
      .label("Requested Amount b"),
    requestedAmount3: Joi.number()
      .min(1)      
      .allow("")
      .label("Requested Amount c"),
    startDate: Joi.date().required(),
  };

  const validate = () => {
    const validateDetails = {
      firstName,
      lastName,
      email,
      mobile,
      unitNumber,
      streetNumber,
      streetName,
      suburb,
      state,
      postCode,
      requestedAmount,
      requestedAmount2,
      requestedAmount3,
      startDate,
    };
    const options = { abortEarly: false };
    const { error } = Joi.validate(validateDetails, schema, options);
    if (!error) return null;

    const errors = {};
    error.details.map((error) => (errors[error.path[0]] = error.message));

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaForProperty = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaForProperty);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const errors = {};
    const errorMessage = validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    setErrors(errors);
  };

  const referrer = location.search !== "" && location.search.split("?ref=");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validating upon submit
    if (form_code !== "paylater_cal") {
      const errors = validate();
      setErrors(errors || {});
      if (errors) return;
    }

    axios
      .post(
        `${process.env.REACT_APP_ENV_PREFIX}/lead/re_create?partner_code=${partner_code}`,
        {
          firstname: firstName,
          lastname: lastName,
          email: email,
          phone: mobile,
          address: {
            unitNumber: unitNumber,
            streetNumber: streetNumber,
            streetName: streetName,
            suburb: suburb,
            postcode: postCode,
            state: state,
          },
          referrer: referrer.length > 1 ? referrer[1] : undefined,
          form: {
            code: form_code,
          },
          requestedamount: form_code !== "paylater_cal" ? requestedAmount : 0.0,
          requestedamount_2:
            form_code !== "paylater_cal"
              ? requestedAmount2 === ""
                ? null
                : requestedAmount2
              : null,
          requestedamount_3:
            form_code !== "paylater_cal"
              ? requestedAmount3 === ""
                ? null
                : requestedAmount3
              : null,
          // startdate: `${startDate.toISOString().split('T')[0]}`
          startdate: `${startDate.getFullYear()}-${
            startDate.getMonth() + 1
          }-${startDate.getDate()}`.replace(/(^|\D)(\d)(?!\d)/g, "$10$2"),
        }
      )
      .then(function (response) {
        console.log("hello");
        const { qrcode, url } = response.data;
        // console.log('url', url);
        // console.log('response', response.data);
        window.location.href = url;
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  return (
    <div className="pb-10 full-banner customer-landing">
      <div className="flex flex-col pb-6 xl:pb-0 xl:flex-row items-center justify-between flex-wrap items-center">
        <div className="p-8 pl-24 flex flex-col xl:flex-row flex-wrap xl:flex-start items-center">
          <a href="https://elepay.com.au"><img className="w-60" src={ElepayLogo} alt="Elepay Logo" /></a>        
        </div>
      </div>

      {(form_code === "paylater" || form_code === "paylater_cal") && (
        <div className="m-auto mt-8 max-w-full" style={{ padding: "2% 5%" }}>
          <div className="p-10 pt-1 flex-row flex-center items-center constant-flex">
            <h3 className="flex text-green-light text-3xl font-gilroy-semibold md:pl-0 xl:pl-16 pt-8 xl:pt-0">
              {partnerName}
            </h3>

            <h3 className="flex text-green-light text-3xl font-gilroy-semibold md:pl-0 xl:pl-16 pt-8 xl:pt-0">
              |
            </h3>

            <h3 className="flex text-green-light text-3xl font-gilroy-semibold md:pl-0 xl:pl-16 pt-8 xl:pt-0">
              {partnerInfo[partner_code] &&
              form_code.includes("paylater_paynow")
                ? "Payment Hub"
                : "Pay Later"}
            </h3>
          </div>

          <h1 className="text-white pb-8 customer-landing-header-text m-auto font-gilroy-bold text-6xl">
            Select from three different plan options
          </h1>

          <div className="flex-col xl:flex-row pt-8">
            <div className="flex justify-center" style={{ margin: "0 7rem" }}>
              <img className="flex pl-2 plan-icon" src={days60} alt="60 Days" />
              <img className="flex pl-2 plan-icon" src={days90} alt="90 Days" />
              <img className="flex pl-2 plan-icon" src={days120} alt="120D" />
            </div>

            <div className="mt-10 pt-6 pl-0">
              <p className="text-2xl text-left font-gilroy-bold text-purple-dark customer-landing-text-center">
                To discover just how simple and convenient it is to defer any
                upfront payment with Elepay all you need to do is:
              </p>
              <br />

              <div className="flex customer-landing-text-center pb-3">
                <img src={checkmark} alt="checkmark" />

                <p className=" text-xl text-left font-gilroy-regular">
                  Fill in your details below.
                </p>
              </div>

              <div className="flex customer-landing-text-center">
                <img src={checkmark} alt="checkmark" />

                <p className=" text-xl text-left font-gilroy-regular">
                  Then select Access Pay Later Calculator to determine which
                  plan style best suits your needs
                </p>
              </div>

              <br />
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="pay-now-pay-later-form">
        <div className="flex flex-col justify-center w-4/5 xl:w-1/5 xl:w-3/5 m-auto">
          {form_code === "paylater_paynow" && (
            <div className="flex flex-col justify-center w-4/5 xl:w-1/5 xl:w-3/5 m-auto mt-8">
              <h3 className="mb-4 font-gilroy-bold leading-14 text-3xl text-left text-purple-light">
                Pay Now or Pay Later
              </h3>
              <p className="text-black-dark font-gilroy-semibold text-left text-lg mb-8">
                To make your life as easy and as hassle free as possible, we
                offer a selection of payment options with your order.
              </p>

              <div className="flex flex-col xl:flex-row justify-around">
                <div className="bg-black-light p-8 rounded-2xl">
                  <h3 className="text-black-dark font-gilroy-bold text-2xl mb-4 text-center">
                    Pay Now
                  </h3>
                  <div className="flex flex-col items-center">
                    <span className="flex">
                      <img
                        className="flex pl-2 pb-2 w-20"
                        src={masterCard}
                        alt="60 Days"
                      />
                      <img
                        className="flex pl-2 pb-2 w-20"
                        src={visaCard}
                        alt="60 Days"
                      />
                    </span>
                    <span className="flex">
                      <img
                        className="flex pl-2 w-20"
                        src={applePayCard}
                        alt="60 Days"
                      />
                      <img
                        className="flex pl-2 w-20"
                        src={gPayCard}
                        alt="60 Days"
                      />
                    </span>
                  </div>
                </div>

                <div className="flex flex-col items-center p-8 bg-purple-extra-light rounded-2xl mt-4 xl:mt-0">
                  <h3 className="text-black-dark font-gilroy-bold text-2xl mb-4 text-center">
                    Elepay Pay Later
                  </h3>
                  <div className="flex justify-center">
                    <img
                      className="flex pl-2 w-20"
                      src={days60}
                      alt="60 Days"
                    />
                    <img
                      className="flex pl-2 w-20"
                      src={days90}
                      alt="90 Days"
                    />
                    <img
                      className="flex pl-2 w-20"
                      src={days120}
                      alt="120 Days"
                    />
                  </div>
                  <p className="pt-4 text-black-dark font-gilroy-regular">
                    No upfront repayments
                  </p>
                </div>
              </div>
            </div>
          )}
          <h3 className="mt-10 mb-5  self-center font-gilroy-bold leading-14 text-6xl text-left text-purple-light">
            Let's start with some details
          </h3>

          <div className="flex justify-center details">
            {/* Personal details */}
            <div className="mt-12 flex flex-col items-center line-right">
              <div className="flex flex-col text-left w-80">
                <h3 className="font-gilroy-bold text-3xl text-purple-light text-left">
                  Personal details
                </h3>
                <p
                  className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                    errors["firstName"] && "text-red-dark"
                  }`}
                >
                  First name
                </p>
                <input
                  type="text"
                  name="firstName"
                  className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                    errors["firstName"] && "border-red-dark"
                  }`}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    handleChange(e);
                  }}
                />
                {errors["firstName"] && (
                  <p className="text-red-dark font-gilroy-semibold text-sm">
                    {errors["firstName"]}
                  </p>
                )}

                <p
                  className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                    errors["lastName"] && "text-red-dark"
                  }`}
                >
                  Last name
                </p>
                <input
                  type="text"
                  name="lastName"
                  className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                    errors["lastName"] && "border-red-dark"
                  }`}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    handleChange(e);
                  }}
                />
                {errors["lastName"] && (
                  <p className="text-red-dark font-gilroy-semibold text-sm">
                    {errors["lastName"]}
                  </p>
                )}

                <p
                  className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                    errors["email"] && "text-red-dark"
                  }`}
                >
                  Email
                </p>
                <input
                  type="text"
                  name="email"
                  className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                    errors["email"] && "border-red-dark"
                  }`}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleChange(e);
                  }}
                />
                {errors["email"] && (
                  <p className="text-red-dark font-gilroy-semibold text-sm">
                    {errors["email"]}
                  </p>
                )}

                <p
                  className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                    errors["mobile"] && "text-red-dark"
                  }`}
                >
                  Mobile
                </p>
                <input
                  type="text"
                  name="mobile"
                  className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                    errors["mobile"] && "border-red-dark"
                  }`}
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                    handleChange(e);
                  }}
                />
                {errors["mobile"] && (
                  <p className="text-red-dark font-gilroy-semibold text-sm">
                    {errors["mobile"]}
                  </p>
                )}
              </div>
            </div>

            {/* Property address */}
            <div className="mt-12 flex flex-col items-center line-left">
              <div className="flex flex-col text-left w-80">
                {form_code === "paylater_paynow" && (
                  <div className="bg-purple-light text-white p-4 rounded-2xl mb-8">
                    <p className="text-white font-gilroy-semibold text-left text-sm">
                      <span className="font-gilroy-bold">{partnerName} </span>
                      have partnered with elepay, Australia's Pay Later
                      specialist for Property Owners. Whether you Pay Now or Pay
                      Later - by providing your property details below, you
                      immediately gain exclusive access to future funding
                      opportunities via elepay.
                    </p>
                  </div>
                )}
                <h3 className="font-gilroy-bold text-3xl text-purple-light text-left">
                  Property Details{" "}
                  {form_code === "paylater_paynow" && (
                    <p
                      className="text-black-dark font-gilroy-semibold text-left text-sm mb-4"
                      style={{ fontStyle: "italic" }}
                    >
                      Please enter the property address on the order received
                      from {" " + partnerName}.
                    </p>
                  )}
                </h3>
                {/* <p className={`mt-4 font-gilroy-regular text-md text-black-dark ${errors['propertyAddress'] && 'text-red-dark'}`}>Property address where the funds will be invested</p>
                        <input type="text" name="propertyAddress" className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${errors['propertyAddress'] && 'border-red-dark'}`} value={propertyAddress} onChange={(e) => { setPropertyAddress(e.target.value); handleChange(e) }} />
                        {errors['propertyAddress'] && <p className="text-red-dark font-gilroy-semibold text-sm">{errors['propertyAddress']}</p>} */}

                <div className="flex">
                  <div className="w-full">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                        errors["unitNumber"] && "text-red-dark"
                      }`}
                    >
                      Unit Number
                    </p>
                    <input
                      type="text"
                      name="unitNumber"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["unitNumber"] && "border-red-dark"
                      }`}
                      value={unitNumber}
                      onChange={(e) => {
                        setUnitNumber(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["unitNumber"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm">
                        {errors["unitNumber"]}
                      </p>
                    )}
                  </div>
                  <div className="ml-2 w-full">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                        errors["streetNumber"] && "text-red-dark"
                      }`}
                    >
                      Street Number
                    </p>
                    <input
                      type="text"
                      name="streetNumber"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["streetNumber"] && "border-red-dark"
                      }`}
                      value={streetNumber}
                      onChange={(e) => {
                        setStreetNumber(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["streetNumber"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm">
                        {errors["streetNumber"]}
                      </p>
                    )}
                  </div>
                </div>

                <p
                  className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                    errors["streetName"] && "text-red-dark"
                  }`}
                >
                  Street Name
                </p>
                <input
                  type="text"
                  name="streetName"
                  className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                    errors["streetName"] && "border-red-dark"
                  }`}
                  value={streetName}
                  onChange={(e) => {
                    setStreetName(e.target.value);
                    handleChange(e);
                  }}
                />
                {errors["streetName"] && (
                  <p className="text-red-dark font-gilroy-semibold text-sm">
                    {errors["streetName"]}
                  </p>
                )}

                <p
                  className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                    errors["suburb"] && "text-red-dark"
                  }`}
                >
                  Suburb
                </p>
                <input
                  type="text"
                  name="suburb"
                  className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                    errors["suburb"] && "border-red-dark"
                  }`}
                  value={suburb}
                  onChange={(e) => {
                    setSuburb(e.target.value);
                    handleChange(e);
                  }}
                />
                {errors["suburb"] && (
                  <p className="text-red-dark font-gilroy-semibold text-sm">
                    {errors["suburb"]}
                  </p>
                )}

                <div className="flex">
                  <div className="w-full">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                        errors["postCode"] && "text-red-dark"
                      }`}
                    >
                      Postcode
                    </p>
                    <input
                      type="text"
                      name="postCode"
                      className={`p-4 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                        errors["postCode"] && "border-red-dark"
                      }`}
                      value={postCode}
                      onChange={(e) => {
                        setPostCode(e.target.value);
                        handleChange(e);
                      }}
                    />
                    {errors["postCode"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm">
                        {errors["postCode"]}
                      </p>
                    )}
                  </div>
                  <div className="ml-2 w-full">
                    <p
                      className={`mt-4 font-gilroy-bold text-md text-black-dark ${
                        errors["state"] && "text-red-dark"
                      }`}
                    >
                      State
                    </p>
                    <StateSelect
                      className="border-2 rounded-xl font-gilroy-semibold p-4"
                      options={states}
                      onOptionChange={(e) => setState(e.target.value)}
                      selectedOption={state}
                    />
                  </div>
                </div>

                {form_code !== "paylater_cal" && (
                  <>
                    <p
                      className={`mt-4 font-gilroy-regular text-md text-black-dark ${
                        errors["requestedAmount"] ? "text-red-dark" : ""
                      }`}
                    >
                      Requested amount
                    </p>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="text"
                        name="requestedAmount"
                        className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                          errors["requestedAmount"] ? "border-red-dark" : ""
                        }`}
                        placeholder="0.00"
                        value={`${requestedAmount}`}
                        onChange={(e) => {
                          setRequestedAmount(e.target.value);
                          handleChange(e);
                        }}
                      />
                    </div>
                    {errors["requestedAmount"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm">
                        {errors["requestedAmount"]}
                      </p>
                    )}

                    {showRequestedAmount2 && (
                      <>
                        <p
                          className={`mt-4 font-gilroy-regular text-md text-black-dark ${
                            errors["requestedAmount2"] ? "text-red-dark" : ""
                          }`}
                        >
                          Requested amount b
                        </p>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="text"
                            name="requestedAmount2"
                            className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                              errors["requestedAmount2"]
                                ? "border-red-dark"
                                : ""
                            }`}
                            placeholder="0.00"
                            value={`${requestedAmount2}`}
                            onChange={(e) => {
                              setRequestedAmount2(e.target.value);
                              handleChange(e);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {showRequestedAmount2 && errors["requestedAmount2"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm">
                        {errors["requestedAmount2"]}
                      </p>
                    )}

                    {showRequestedAmount3 && (
                      <>
                        <p
                          className={`mt-4 font-gilroy-regular text-md text-black-dark ${
                            errors["requestedAmount3"] ? "text-red-dark" : ""
                          }`}
                        >
                          Requested amount c
                        </p>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="text"
                            name="requestedAmount3"
                            className={`p-4 pl-7 w-full h-12 mt-1 border-2 rounded-xl font-gilroy-semibold ${
                              errors["requestedAmount3"]
                                ? "border-red-dark"
                                : ""
                            }`}
                            placeholder="0.00"
                            value={`${requestedAmount3}`}
                            onChange={(e) => {
                              setRequestedAmount3(e.target.value);
                              handleChange(e);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {showRequestedAmount3 && errors["requestedAmount3"] && (
                      <p className="text-red-dark font-gilroy-semibold text-sm">
                        {errors["requestedAmount3"]}
                      </p>
                    )}

                    {!showRequestedAmount3 && (
                      <div className="mt-5 flex self-center w-80">
                        <motion.button
                          type="button"
                          className="p-2 mt-2 py-3 px-4 text-purple-light rounded-3xl font-gilroy-bold border border-purple-light"
                          onClick={() => {
                            (!showRequestedAmount2 &&
                              setShowRequestedAmount2(true)) ||
                              (showRequestedAmount2 &&
                                !showRequestedAmount3 &&
                                setShowRequestedAmount3(true));
                          }}
                          whileHover={{ scale: 1.1 }}
                        >
                          Add amount
                        </motion.button>
                      </div>
                    )}

                    <p
                      className={`mt-4 font-gilroy-regular text-md text-black-dark ${
                        errors["startDate"] && "text-red-dark"
                      }`}
                    >
                      Start date
                    </p>
                    <div className="w-full flex justify-start items-center">
                      <span
                        className="w-7 pl-3 absolute"
                        style={{ color: "#4139ff" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          style={{ marginLeft: "-0.2rem" }}
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                      </span>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        minDate={new Date()}
                        onDayChange={(date) => setStartDate(date)}
                        value={startDate}
                        dayPickerProps={{
                          modifiers: {
                            disabled: [
                              {
                                before: new Date(),
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="mt-10 flex self-center w-80 pb-10">
            <motion.button
              type="submit"
              className="bg-purple-light p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold" whileHover={{ scale: 1.1 }}
            >
              {form_code === "paylater_cal"
                ? "Access Pay Later Calculator"
                : "View options"}
            </motion.button>
          </div>
        </div>
      </form>

      <div className="p-16 pb-16 flex flex-col text-center xl:text-right xl:flex-row flex-wrap xl:justify-between items-center">
        <img className="flex w-40" src={ElepayPrimaryLogo} alt="Elepay" />
        <p className="text-sm pt-4 xl:pt-0 font-gilroy-regular">
          ABN: 47 634 728 591. Copyright 2022 Elepay. <br />
          All rights reserved.
        </p>
      </div>

      <div className="p-16 pt-12 pb-12 flex flex-col text-left xl:flex-row flex-wrap " style={{backgroundColor: "#12026D" }}>   
          <div class="font-gilroy-bold" style={{color: "#00ffc4", fontSize: "14px"}}>IMPORTANT INFORMATION</div>           
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
            ^ By submitting this enquiry, you acknowledge that you have read <a href="https://go.elepay.com.au/static/pdf/PrivacyPolicy-ElevarePayEasyPtyLtd.pdf" target="_blank" style={{color: "#00ffc4"}} >Elepay's Privacy Policy</a> and understand that Elepay will use your personal information to contact you in relation to this enquiry and for other general marketing purposes. You can change your marketing preferences by contacting our support team on 1300 019 417 (or support@elepay.com.au) or by clicking the unsubscribe button on our marketing communications.
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^ Our online calculator should be used as an indication only and does not indicate approval for the loan or that the product is suitable for you. The calculator should be used as a guide to see the loan repayments based on the loan amount, loan term and number of repayments. The calculator does not take into account default fees or late interest and any applicable discount on early repayment. It's important that you are comfortable with, and have the financial capacity to make, the required repayments. 
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^All information including interest rate is subject to change without notice. Full details available on application.
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^Approval is subject to Elepay's lending and credit criteria and suitability assessment. Terms and conditions apply and are available upon submission of your application or upon request. Any information on this website is of a general nature only and does not take into account your objectives, financial situation or needs.   
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"white", fontSize: "14px" }}>
          ^Before applying, you should consider whether this product is appropriate for you by reviewing your individual circumstances and seeking independent professional financial advice. Please review our <a href="https://go.elepay.com.au/static/pdf/ElepayTMD.pdf" target="_blank" style={{color: "#00ffc4"}} >Target Market Determination</a> when considering whether our product is right for you. For further information or an individual assessment of your eligibility, please contact our Support Team.  
          </p>
          <p class="font-gilroy-bold pt-3" style={{color:"#00ffc4", fontSize: "14px" }}>
          ^Member of the Australian Financial Complaints Authority Limited A.B.N. 38 620 494 340 (Member number 96789). 
          </p>
      </div>

    </div>
  );
};

export default CustomerLanding;
