import React from 'react';
import _ from 'lodash';
import days107 from '../../assets/images/107_days_paylater.png';
import benefits from '../../assets/images/Benefits.png';
import benefitsWhite from '../../assets/images/benefits_mobile/whitebg.png';
import benefitsRectangle from '../../assets/images/benefits_mobile/Rectangle.png';
import benefitsHead from '../../assets/images/benefits_mobile/points/head.png';
import benefits1 from '../../assets/images/benefits_mobile/points/1.png';
import benefits2 from '../../assets/images/benefits_mobile/points/2.png';
import benefits3 from '../../assets/images/benefits_mobile/points/3.png';
import benefits4 from '../../assets/images/benefits_mobile/points/4.png';
import benefits5 from '../../assets/images/benefits_mobile/points/5.png';
import benefits6 from '../../assets/images/benefits_mobile/points/6.png';

const CalculatorPlanOptions = ({onConfirmBox, details, options, requestedAmount, disableSelectButton }) => {
    return (<div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-between items-end">
            <img className="flex pl-2" style={{ width: "22rem" }} src={days107} alt="107 Days" />
            <div className="flex flex-col xl:flex-row pt-8 justify-center items-center text-left">
                <div className="flex flex-col justify-center" style={{ margin: "0 2rem" }}>
                    <p className="text-2xl text-purple-dark font-gilroy-regular">Elepay funding is perfect ford:</p>
                    <br />
                    <ul style={{ listStyle: "disc" }}>
                        <li className="text-purple-dark text-xl font-gilroy-regular">Property staging</li>
                        <p className="text-black-dark text-xl font-gilroy-regular">Increase the value and shorten the days on the market</p>
                        <li className="mt-2 text-purple-dark text-xl font-gilroy-regular">Repairs & maintenance</li>
                        <p className="text-black-dark text-xl font-gilroy-regular">Painting, electrical, plumbing, flooring</p>
                        <li className="mt-2 text-purple-dark text-xl font-gilroy-regular">Renovations</li>
                        <p className="text-black-dark text-xl font-gilroy-regular">Add that special touch</p>
                        <li className="mt-2 text-purple-dark text-xl font-gilroy-regular">Property marketing</li>
                        <p className="text-black-dark text-xl font-gilroy-regular">The more eyeballs, the more interest you'll attract</p>
                        <li className="mt-2 text-purple-dark text-xl font-gilroy-regular">Property related fees</li>
                        <p className="text-black-dark text-xl font-gilroy-regular">Insurance, Body Corp, Property Management</p>
                    </ul>
                </div>
                <div className="hidden xl:block pt-8 pb-16 xl:pt-0 pl-0 w-full xl:w-1/2">
                    <div>
                        <img className="flex pl-2" src={benefits} alt="Benefits" />
                        {/* <button type="submit" className="w-full xl:w-1/3 bg-purple-light p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold" style={{ marginTop: "-6rem", position: "absolute", width: "13rem", marginLeft: "3rem" }}>
                            <motion.button whileHover={{ scale: 1.1 }}>
                                APPLY NOW
                            </motion.button>
                        </button> */}
                    </div>
                </div>
                <div className="xl:hidden pt-8 pb-16 xl:pt-0 pl-0 w-full xl:w-1/2" style={{ width: "auto" }}>
                    <div>
                        <div>
                            <div style={{ zIndex: 999, position: "absolute", padding: "2rem" }}>
                                <img className="flex pl-2" src={benefitsHead} alt="Benefits" />
                                <img className="flex pl-2" style={{ paddingTop: "2rem" }} src={benefits1} alt="Benefits" />
                                <img className="flex pl-2" style={{ paddingTop: "1rem" }} src={benefits2} alt="Benefits" />
                                <img className="flex pl-2" style={{ paddingTop: "1rem" }} src={benefits3} alt="Benefits" />
                                <img className="flex pl-2" style={{ paddingTop: "1rem" }} src={benefits4} alt="Benefits" />
                                <img className="flex pl-2" style={{ paddingTop: "1rem" }} src={benefits5} alt="Benefits" />
                                <img className="flex pl-2" style={{ paddingTop: "1rem" }} src={benefits6} alt="Benefits" />
                            </div>
                            <img style={{ zIndex: 99, position: "absolute" }} className="flex pl-2" src={benefitsWhite} alt="Benefits" />
                            <img style={{zIndex: 9}} className="flex pl-2" style={{ paddingLeft: "1rem", paddingTop: "0.5rem" }} src={benefitsRectangle} alt="Benefits" />
                            {/* <button type="submit" className="w-full xl:w-1/3 bg-purple-light p-2 mt-2 py-3 px-4 text-white rounded-3xl font-gilroy-bold" style={{ marginTop: "-6rem", position: "absolute", width: "13rem", marginLeft: "3rem", zIndex: "111" }}>
                                <motion.button whileHover={{ scale: 1.1 }}>
                                    APPLY NOW
                                </motion.button>
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};

export default CalculatorPlanOptions;