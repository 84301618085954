import React, { useState } from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import Tabs from './Tabs';
import OptionCard from './OptionCard';

const CalculatorPlanOptions = ({onConfirmBox, details, options, requestedAmount, disableSelectButton }) => {
    const payLaterTabOptions = [{ index: 0, option: "1 x payment", optionSelected: 'a' }, { index: 1, option: "4 x payments", optionSelected: 'b'}];
    const [payLaterOpenTab, setPayLaterOpenTab] = useState({ index: 0, option: payLaterTabOptions[0].option, optionSelected: 'a' });
    const [showMultiplePaymentOptions, setShowMultiplePaymentOptions] = useState(false);

    const singlePayment = _.orderBy(options[0].products.filter(product => product.payments.length === 1), item => item.product.numberOfDays, ['asc']);
    const multiplePayments = _.orderBy(options[0].products.filter(product => product.payments.length === 4), item => item.product.numberOfDays, ['asc']);

    const products = payLaterOpenTab.index === 0 ? singlePayment : multiplePayments;

    return (<div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-between items-end">
                <p className="font-gilroy-bold w-full text-2xl text-purple-light text-center">Your Plan options</p>
                {payLaterTabOptions.length > 1 && 
                    (<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                        {/* <p className="text-4xl text-purple-dark m-auto mt-20 mb-10 font-gilroy-bold">
                            Requested Amount
                        </p> */}
                        <Tabs
                            textColor={'text-purple-light'}
                            bgColor={'bg-purple-light'}
                            selectedTextColor={'text-purple-extra-light'}
                            borderColor={'border-purple-light'}
                            tabBackgroundColor={'bg-white'}
                            normalTabTextColor={'text-purple-light'}
                            activeWidth={36}
                            // showBlur={showBlur}
                            activeTabWidth="9rem"
                            tabOptions={payLaterTabOptions}
                            onTabChange={(option) => setPayLaterOpenTab(option)}
                            selectedTab={payLaterOpenTab}
                        />
                        {/* {openTab === "Single payment" ? <SingleOption /> : <MultipleOptions />} */}
                        </motion.div>)
                }
        </div>
        <div className="lg:hidden lg:flex justify-center">
                <Carousel swipeable={true} autoPlay={false} interval={300000} infiniteLoop={true} showIndicators={false} showStatus={false} width={500}>
                    {products.map((product) => {
                        const payments = _.sortBy(product.payments, function(dateObj) { return new Date(dateObj.date)});
                        {/* return <div className={`pl-4 mt-4 flex justify-center flex-wrap ${product['product'].numberOfDays === 60 && 'order-1'} ${product['product'].numberOfDays === 90 && 'order-2'} ${product['product'].numberOfDays === 120 && 'order-3'}`} key={product['product'].numberOfDays}> */}
                        return <div className={`pl-4 mt-4 flex justify-center flex-wrap`} key={product['product'].numberOfDays}>
                            <OptionCard
                                requestedAmount={requestedAmount}
                                applicationUrl={product.application_url}
                                dayPlan={product['product'].numberOfDays}
                                noPayFor={product['product'].firstPayment}
                                code={product['product'].code}
                                name={product['product'].name}
                                easyPayments={product['product'].numberOfPayments}
                                selectedTab={payLaterOpenTab}
                                price={product.payments[0].amount.toFixed(2)}
                                dateOptions={payments}
                                disableSelectButton={disableSelectButton}
                                onConfirmBox={onConfirmBox}
                                onShowMultiplePaymentOptions={() => setShowMultiplePaymentOptions(true)}
                                showMultiplePaymentOptions={showMultiplePaymentOptions}
                            />
                        </div>
                    })}
                </Carousel>
            </div>
            <div className="hidden lg:block lg:flex justify-center px-20">
                    {products.map((product) => {
                        const payments = _.sortBy(product.payments, function(dateObj) { return new Date(dateObj.date)});
                        {/* return <div key={product['product'].numberOfDays} className={`px-1 flex justify-center flex-wrap ${product['product'].numberOfDays === 60 && 'order-1'} ${product['product'].numberOfDays === 90 && 'order-2'} ${product['product'].numberOfDays === 120 && 'order-3'}`}> */}
                        return <div key={product['product'].numberOfDays} className={`px-2 flex justify-center flex-wrap`}>
                            <OptionCard
                                requestedAmount={requestedAmount}
                                applicationUrl={product.application_url}
                                dayPlan={product['product'].numberOfDays}
                                noPayFor={product['product'].firstPayment}
                                code={product['product'].code}
                                name={product['product'].name}
                                easyPayments={product['product'].numberOfPayments}
                                selectedTab={payLaterOpenTab}
                                price={product.payments[0].amount.toFixed(2)}
                                dateOptions={payments}
                                disableSelectButton={disableSelectButton}
                                onConfirmBox={onConfirmBox}
                                onShowMultiplePaymentOptions={() => setShowMultiplePaymentOptions(true)}
                                showMultiplePaymentOptions={showMultiplePaymentOptions}
                            />
                        </div>
                    })}
            </div>
    </div>)
};

export default CalculatorPlanOptions;