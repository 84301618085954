import React from "react";
import { fullMonthNames } from "../../constants/dateConstants";
import days60 from '../../assets/images/60_days.svg';
import days90 from '../../assets/images/90_days.svg';
import days120 from '../../assets/images/120_days.svg';

const OptionCard = ({
  // options
    requestedAmount,
    selectedTab,
    applicationUrl,
    dayPlan,
    noPayFor,
    code,
    name,
    dateOptions,
    onConfirmBox,
    easyPayments,
    price,
    disableSelectButton,
    onShowMultiplePaymentOptions,
    showMultiplePaymentOptions
}) => {
  const formatCurrency = (elm) => {
    if (!elm) return '$0.00';

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    return formatter.format(elm);
  }

  const formatDate = (date) => {
    return `${date.getDate()} ${fullMonthNames[date.getMonth()]} '${date.getFullYear() % 100}`;
  }

  return (
    <div className="w-60 bg-white rounded-2xl border border-purple-light" style={{ height: "fit-content" }}>
      <div className="p-4 flex justify-center">
        <img className="w-16" src={dayPlan === 60 ? days60 : dayPlan === 90 ? days90 : days120 } alt="Day Plan" />
      </div>
      {/* <div className="bg-purple-extra-light p-4">
        <p className="font-gilroy-bold text-purple-dark">Nothing to pay for</p>
        <h3 className="font-gilroy-bold text-3xl text-purple-light">
          {noPayFor} days
        </h3>
      </div> */}
      <div className="flex flex-col items-center">
        <p className="text-sm font-gilroy-regular py-2">No payment for <span className="font-gilroy-semibold">{noPayFor} days</span></p>
        <p className="text-3xl text-purple-light font-gilroy-regular py-2">{formatCurrency(price)}</p>
        <p className="text-sm mb-2 font-gilroy-regular">{`${easyPayments} x payment${easyPayments === 4 ? 's' : ""}`}</p>
      </div>
      <div className="p-4 pt-0">
        {/* <p className="font-gilroy-bold text-md text-purple-dark">
          {easyPayments ? `${easyPayments} easy payments` : "Single Payment"}
        </p>
        <p className="text-purple-light text-lg mb-4">${price}</p> */}
        {/* {selectedTab.optionSelected === "a" && !showSinglePaymentOptions && <button onClick={() => setShowSinglePaymentOptions(true)}><p className="font-gilroy-regular text-gray-500">{`View date${easyPayments === 4 ? 's' : ''}`} <span className="text-purple-light">▾</span></p></button>} */}
        {selectedTab.optionSelected === "a" &&
          <div className="text-right m-auto table">
            {dateOptions.map((dateOption) => (
              <p className="font-gilroy-semibold text-sm">
                {formatDate(new Date(dateOption.date))}
              </p>
          ))}
          </div>
        }
        {selectedTab.optionSelected === "b" && !showMultiplePaymentOptions && <button onClick={onShowMultiplePaymentOptions}><p className="font-gilroy-regular text-purple-dark">{`View date${easyPayments === 4 ? 's' : ''}`} <span className="text-purple-light">▾</span></p></button>}
        {selectedTab.optionSelected === "b" && showMultiplePaymentOptions && 
          <div className="text-right m-auto table">
            {dateOptions.map((dateOption) => (
                <p className="font-gilroy-semibold text-sm">
                  {formatDate(new Date(dateOption.date))}
                </p>
            ))}
          </div>}
        {/* <button onClick={() => onConfirmBox(dayPlan, easyPayments)} className="bg-purple-light p-2 mt-2 w-full text-white rounded-3xl font-gilroy-bold"> */}
      </div>
    </div>
  );
};

export default OptionCard;
