import React from 'react';
import StateSelect from '../../components/common/StateSelect';
import _ from 'lodash';
import '../style.css';

const CalculatorDetails = ({ details, showBlur, requestedAmount, purposeOfFunds, purposeOfFund, options, onPurposeChange }) => {
    const { firstname, lastname, email, address, requestedamount, startdate, disbursement_date } = details;
    const singlePayment = _.orderBy(options[0] && options[0].products.filter(product => product.payments.length === 1), item => item.product.numberOfDays, ['asc']);

    return (<>
        <div className="text-left xl:absolute items-center m-4 xl:m-0 xl:w-56" style={{ filter: `${showBlur && 'blur(10px)'}` }}>
            {/* <div className="hidden py-10 flex xl:flex flex-row justify-center flex-wrap bg-purple-dark border-2 border-purple-dark">
                <img className="w-40" src={ElepayLogo} alt="Elepay Logo" />
            </div> */}
            <div className="xl:flex xl:w-56 detailsContainer -z-1 xl:flex-col xl:absolute h-100" >
                <div className="text-left px-12 pb-3 w-full h-full details-in-payment-hub">
                    <h3 className="pt-6 font-gilroy-bold text-sm text-green-light">Personal details</h3>
                    <p className="text-white pt-2 font-gilroy-bold">{`${firstname} ${lastname}`}</p>
                    <p className="text-white text-sm leading-1 font-gilroy-regular">{email}</p>
                    <p className="text-white text-sm leading-1 font-gilroy-regular">{address?.unitNumber} {address?.streetNumber} {address?.streetName}</p>
                    <p className="text-white text-sm leading-1 font-gilroy-regular">{address?.suburb}</p>
                    <p className="text-white text-sm font-gilroy-regular">{address?.state} {address?.postcode}</p>
                    {/* {disbursement_date && <div className="bg-purple-light px-2 pt-3 pb-3 w-full rounded-2xl" style={{ maxWidth: "12rem", margin: "auto" }}>
                        <h3 className="font-gilroy-semibold text-md text-green-light">Funds disbursed into your account on</h3>
                        <h3 className="font-gilroy-semibold text-md text-white">{formatDate(disbursement_date)}</h3>
                    </div>} */}
                </div>
                {singlePayment.length >1 && 
                    <div className="hidden xl:flex xl:w-max -z-1 xl:flex-col xl:absolute h-100 mt-40" >
                        <div className="text-left pt-3 px-12 pb-3 w-full h-full details-in-payment-hub">
                            <h3 className="pt-6 font-gilroy-bold text-sm text-green-light">Purpose of funds</h3>
                            <StateSelect className="border-2 rounded-xl font-gilroy-semibold p-4" options={purposeOfFunds} onOptionChange={onPurposeChange} selectedOption={purposeOfFund} />
                        </div>
                    </div>
                }
            </div>
        </div>
    </>)
}

export default CalculatorDetails;