import React from "react";
import ElepayLogo from '../assets/images/Elepay_enjoy_more_time.png';

const Pay_107_Calc_Header = () => {
    return (<>
        <div className="tablet:hidden flex flex-col p-10 tablet:flex-row items-center justify-between flex-wrap items-center bg-purple-dark">
            <img className="pl-5 pr-10 pt-4 w-48" src={ElepayLogo} alt="Elepay Logo" />
        </div>
        <div className="hidden px-10 py-10 flex tablet:flex flex-row flex-wrap items-center tablet:justify-between bg-purple-dark">
            <img className="w-40" src={ElepayLogo} alt="Elepay Logo" />
        </div>
        </>
    );
};

export default Pay_107_Calc_Header;