let partnerLogos = {};
partnerLogos['Realestate'] = require('../assets/images/partners/Realestate.svg').default;
partnerLogos['Coronis'] = require('../assets/images/partners/Coronis.png').default;
    
export default partnerLogos;

// export const partnerLogo = (partnerCode) => {
//     // const logo = require(`../assets/images/partners/${partnerCode}.${'svg' || 'png' || 'jpg'}`).default;

//     let logo;
    
//     logo = require(`../assets/images/partners/${partnerCode}.svg`);
//     return logo ? logo : partnerCode;
// }