import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import SingleOption from "./SingleOption";
import MultipleOptions from "./MultipleOptions";
import Tabs from "../common/Tabs";
import Header from "../Header";
import Footer from "../Footer";
import './options.css';

const Options = () => {
  const tabOptions = ["Single payment", "Four payments"];
  const [openTab, setOpenTab] = useState(tabOptions[0]);

  return (
    <>
    <Header />
      <div className="options-container relative flex flex-col items-center w-full  bg-purple-light pb-10">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          <p className="text-4xl text-white m-auto mt-20 mb-10 font-gilroy-bold">
            Your plan options
          </p>
          <Tabs
            color="cyan"
            tabOptions={tabOptions}
            onTabChange={(option) => setOpenTab(option)}
            selectedTab={openTab}
          />
          {openTab === "Single payment" ? <SingleOption /> : <MultipleOptions />}
        </motion.div>
        <motion.button whileHover={{ scale: 1.1 }} className="mt-20">
          <Link
            to="/test"
            className="text-white font-gilroy-bold py-4 px-6 bg-transparent border-2 rounded-3xl"
          >
            Start again
          </Link>
        </motion.button>
      </div>
      <Footer />
    </>
  );
};

export default Options;
