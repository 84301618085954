import React from "react";
import './New107MessageBoxStyles.css';

export default function New107MessageBox({ show107MessageModal, showMessage, onShowMessage, onCloseModal }) {
    return (
        <>
            {show107MessageModal ? (<><div
                className="justify-center items-center flex h-full overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                        <div className="messageBoxContainer relative">
                            <h3 className="messageBoxHeader font-gilroy-bold text-purple-light">Great news!</h3>
                            <div className="paragraphBox">
                                <p className="messageBoxParagraph font-gilroy-regular">Alongside our 107 Day Exclusive Product (which comes with a fresh new <a href="https://elepay.webflow.io/products/deferring-your-property-expenses-has-never-been-easier" target="_blank" className="text-purple-light font-gilroy-regular">look</a>) we now offer property owners the choice to <a href="https://elepay.webflow.io/products/preparing-a-property-for-sale" target="_blank" className="text-purple-light font-gilroy-regular">Pay Later over 60 Days, 90 Days or 120 Days</a>! If you'd like to enquire about our new range of products - please email <a href="mailto:newpartner@elepay.com.au" target="_blank" className="text-purple-light font-gilroy-regular">newpartner@elepay.com.au</a> and we'll arrange a quick introduction to all our new features and benefits. Elepay has also launched a new <a href="https://elepay.webflow.io" target="_blank" className="text-purple-light font-gilroy-regular">website</a>, which we'd love you to check out!</p>
                                <p className="messageBoxParagraph font-gilroy-regular" style={{ paddingTop: "40px" }}>Team Elepay</p>
                            </div>
                            <div className="footerBox flex items-center justify-between">
                                <span>
                                    <input type="checkbox" checked={showMessage} onChange={onShowMessage} /> <span className="messageBoxParagraph text-black-dark font-gilroy-regular" style={{ paddingLeft: "16px" }}>Do not show this message again</span>
                                </span>
                                <button
                                    className="closeButton text-purple-light border-purple-light font-gilroy-bold"
                                    type="button"
                                    onClick={onCloseModal}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>) : null}
        </>
    );
}