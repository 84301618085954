import React from "react";
import ElepayLogo from "../assets/images/elepay-logo.png";

const Header = () => {
  return (
    <div className="p-10 flex justify-center bg-purple-dark">
      <img src={ElepayLogo} alt="Elepay" />
    </div>
  );
};

export default Header;