import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaGooglePay, FaApplePay  } from 'react-icons/fa';
import { SiVisa, SiMastercard } from 'react-icons/si';

const PayNowOptionCard = ({
    requestedAmount,
    onHandlePayNow,
    selectedTab,
    applicationUrl,
    dayPlan,
    noPayFor,
    code,
    name,
    dateOptions,
    easyPayments,
    price
}) => {
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showPayNowLoadingButton, setShowPayNowLoadingButton] = useState(false);

  return (
    <div className="w-64 flex items-center bg-white rounded-2xl" style={{ height: "fit-content" }}>
      {/* <div className="p-4">
        <h4 className="font-gilroy-bold text-purple-dark">
          <span className="w-10 p-2 bg-purple-extra-light rounded-3xl text-purple-dark">
            PAY NOW
          </span>{" "}
        </h4>
      </div> */}
      {/* <div className="bg-purple-extra-light p-4">
        <p className="font-gilroy-bold text-purple-dark">Nothing to pay for</p>
        <h3 className="font-gilroy-bold text-3xl text-purple-light">
          {noPayFor} days
        </h3>
      </div> */}
      <div className={`${selectedTab.index === 0 && 'w-4/5' || selectedTab.index === 1 && 'w-full'}`}>
        <div className="flex flex-col items-center">
            <hr className="w-3/5 mt-4 mb-2 border-green-light" style={{ borderWidth: "1px" }} />
            <p className="text-3xl text-purple-light font-gilroy-regular">{requestedAmount.amount}</p>
            <hr className="w-3/5 mt-1 mb-2 border-green-light" style={{ borderWidth: "1px" }} />
            <p className="mb-2 font-gilroy-regular text-gray-500" style={{ fontSize: "6px", fontStyle: "italic" }}>{`${selectedTab.index === 0 && '1.25% fee applies' || selectedTab.index === 1 && '$2.95 per payment'}`}</p>
        </div>
        <div className="p-4 pt-0">
            {/* <p className="font-gilroy-bold text-md text-purple-dark">
            {easyPayments ? `${easyPayments} easy payments` : "Single Payment"}
            </p>
            <p className="text-purple-light text-lg mb-4">${price}</p> */}
            {/* {!showPaymentOptions && <button onClick={() => setShowPaymentOptions(true)}><p className="font-gilroy-regular text-gray-500">View dates <span className="text-purple-light">▾</span></p></button>} */}
            {!showPayNowLoadingButton && <button onClick={() => { onHandlePayNow((selectedTab.index === 0 && 2) || selectedTab.index === 1 && 0); setShowPayNowLoadingButton(true); }} className="bg-green-light p-2 mt-2 w-full text-purple-light rounded-3xl font-gilroy-bold">
                <motion.button whileHover={{ scale: 1.1 }}>
                    Pay Now
                </motion.button>
            </button>}
            {showPayNowLoadingButton && <button className="opacity-50 flex items-baseline justify-center bg-green-light p-2 mt-2 w-full text-purple-light rounded-3xl font-gilroy-bold cursor-default" disabled={showPayNowLoadingButton}>
                <div>Please wait</div><div className="dot-pulse-1 ml-5"></div>
            </button>}
            
        </div>
      </div>
      <div className={`${selectedTab.index === 0 ? 'w-1/5' : 'hidden'} flex flex-col`}>
       <span> <SiVisa size={30} color="#4139ff" /> </span>
       <span> <SiMastercard size={30} color="#4139ff" /> </span>
       <span> <FaGooglePay size={30} color="#4139ff" /> </span>
       <span> <FaApplePay size={30} color="#4139ff" /> </span>
      </div>
    </div>

  );
};

export default PayNowOptionCard;
